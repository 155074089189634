import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ManageUser.module.css";

import { useCallback } from "react";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import useOrganisation from "src/hooks/useOrganisation";
import useAlerts from "src/hooks/useAlerts";
import { customFetch } from "src/utilities/http";
import useToast from "src/hooks/useToast";

const ManageUser = ({ name, email, isAdmin = false, setShowModal }) => {
  const { confirm } = useAlerts();
  const { homeOrganisation } = useOrganisation();

  const toast = useToast();

  const handleDelete = useCallback(async () => {
    try {
      setShowModal(false);
      await confirm({
        label: `Are you sure you want to delete ${name}?`,
        text: "This action is permanent and cannot be undone.",
        confirmText: "Delete",
        inverse: true,
      });

      await customFetch({
        entity: "Public.User",
        method: "DELETE_TEAM_MEMBER",
        data: { email, homeOrganisation: homeOrganisation?._id },
      });

      toast.success(`${name} has been deleted from your team.`);
    } catch (error) {
      setShowModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, email, homeOrganisation, name]);
  return (
    <div className={styles["user-container"]}>
      <div className={styles["details"]}>
        <h3 className={styles["name"]}>
          {name}
          {isAdmin && <span>(admin)</span>}
        </h3>
        <p className={styles["email"]}>{email}</p>
      </div>
      <div className={styles["controls"]}>
        {!isAdmin && (
          <div className={styles["delete"]} onClick={handleDelete}>
            <p>delete team member</p>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageUser;
