import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const toastError = (error) => {
  Toastify({
    text: error,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    style: {
      background: "var(--error-light)",
      color: "var(--error-dark)",
      borderRadius: "15px",
      fontSize: "20px",
      padding: "18px 40px 20px 40px",
      boxShadow: "var(--box-shadow)",
      textAlign: "center",
    },
  }).showToast();
};

export const customFetch = async ({ entity, method, criteria, data: reqData, node = "live-node" }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");

      const headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["token"] = token;
      }

      const res = await fetch(`${process.env.REACT_APP_API_URL}/${node}`, {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify({
          entity,
          method,
          criteria,
          data: reqData,
        }),
      });
      const data = await res.json();
      if (data?.error && data.error !== "Access Denied.") {
        toastError(data.error);
      }

      if (data.token) {
        localStorage.setItem("token", data.token);
      }

      resolve({ status: data.status, data: data.data, error: data.error });
    } catch (error) {
      toastError(error.message);
      resolve({ status: 500, data: null, error: error.message });
    }
  });
};
