import styles from "./TeamItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TeamItem = ({ icon, onClick, text }) => {
  
  return (
    <div className={styles["container"]}>
      <div className={styles["icon"]}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={styles["link"]} onClick={onClick}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default TeamItem;
