import clsx from "clsx";
import styles from "../EmployerApplication.module.css";
import globalStyles from "src/Global.module.css";
import Form from "src/components/Interface/layout/Form";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "src/components/Interface/buttons/Button";
import { faSave, faTimes, faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { isNotBlank, validateEmail } from "src/utilities/validation";
import Input from "src/components/Interface/inputs/Input";
import ToggleButton from "src/components/Interface/inputs/ToggleButton";
import Select from "src/components/Interface/inputs/Select";
import useToast from "src/hooks/useToast";
import Date from "src/components/Interface/inputs/Date";
import StatusIcon from "src/components/misc/StatusIcon";
import { useParams } from "react-router-dom";
import { faPaperPlane, faHeart } from "@fortawesome/free-regular-svg-icons";
import useAlerts from "src/hooks/useAlerts";
import { customFetch } from "src/utilities/http";
import { buildApplicationCollaboratorLink } from "src/utilities/generate";
import useNav from "src/hooks/useNav";
import { checkUserHasPaymentSetup } from "src/utilities/payment";
import useWindowSize from "src/hooks/useWindowSize";
import { applicationInvitation } from "src/utilities/Email/index";
import useOrganisation from "src/hooks/useOrganisation";

const validateEmploymentBasis = (mode, value) => {
  if (mode === "CONTRACTOR") {
    return value === "LABOURHIRE" || value === "NONEMPLOYEE";
  }

  if (mode === "EMPLOYEE") {
    return value === "FULLTIME" || value === "PARTTIME" || value === "CASUAL";
  }
};

const EmpAppForm = ({ application = {} }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [incomeTypeOptions, setIncomeTypeOptions] = useState([]);
  const [initial, setInitial] = useState(application);
  const [loading, setLoading] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [userHasPaymentMethod, setUserHasPaymentMethod] = useState(true);
  const { screen } = useWindowSize();
  const [formData, setFormData] = useState({});

  const { confirm, prompt } = useAlerts();
  const { key } = useParams();
  const toast = useToast();
  const navigate = useNav();
  const { organisation } = useOrganisation();

  const { data = {} } = formData;

  const urlParams = new URLSearchParams(window.location.search);
  const organisationID = urlParams.get("organisationID");
  const organisationName = urlParams.get("organisation");
  const homeOrganisationID = urlParams.get("homeOrganisation");
  const fullPath = window.location.href.split("/")?.[4];

  useEffect(() => {
    (async () => {
      const { hasPaymentMethod } = await checkUserHasPaymentSetup();
      setUserHasPaymentMethod(hasPaymentMethod);
    })();
  }, []);

  const resubmitApplication = useCallback(async () => {
    setLoading(true);
    if (!userHasPaymentMethod) {
      setLoading(false);
      toast.error("Please add a payment method to resubmit this application.");
      return;
    }

    const { status, data } = await customFetch({
      entity: "Public.Application",
      method: "SUBMIT",
      data: {
        application: {
          ...formData.data,
          taxDeclaration: { ...formData.data?.taxDeclaration, employmentBasis: formData.data.employmentBasis },
        },
        organisationID,
        homeOrganisation: homeOrganisationID,
        key,
        draft: false,
        send: false,
        organisationName,
      },
    });

    if (status === 300) {
      setLoading(false);
      toast.error(data?.error);
      return;
    }

    if (status === 400) {
      setLoading(false);
      return;
    }

    toast.success("Application submitted successfully");
    navigate("/completed/" + key);
    setLoading(false);

    // eslint-disable-next-line
  }, [userHasPaymentMethod, formData, key]);

  const saveApplication = useCallback(
    async (draft = true) => {
      setLoading(true);
      setSaveDraftLoading(draft);

      if (!formData.isValid) {
        setLoading(false);
        setSaveDraftLoading(false);
        toast.error("Please fill in all mandatory fields.");
        return false;
      }

      if (!validateEmploymentBasis(data?.employmentType, data?.employmentBasis)) {
        toast.error("Please select an employment basis.");
        setLoading(false);
        setSaveDraftLoading(false);
        return false;
      }

      const { status } = await customFetch({
        entity: "Public.Application",
        method: hasBeenSubmitted ? "UPDATE" : "CREATE",
        data: {
          application: {
            ...data,
            incomeType: data?.employmentType === "CONTRACTOR" ? data?.employmentBasis : data?.incomeType,
            status: draft ? "draft" : "pending",
          },
          organisationID,
          homeOrganisation: homeOrganisationID,
          key,
          draft,
          send: !draft,
          organisationName,
        },
      });

      if (status === 200) {
        draft ? toast.success("Application saved as draft.") : toast.success("Application saved successfully.");
      }

      setLoading(false);
      setSaveDraftLoading(false);
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, hasBeenSubmitted, key, navigate]
  );

  const updateApplication = useCallback(() => {
    saveApplication(false);
  }, [saveApplication]);

  const deleteApplication = useCallback(async () => {
    try {
      await confirm({
        label: "Confirm Deletion",
        text: "Are you sure you want to delete this application? This cannot be undone.",
        confirmText: "Delete",
        cancelText: "Cancel",
      });

      await customFetch({
        entity: "Application.Application",
        method: "DELETE",
        criteria: { key },
      });

      toast.success("Application deleted successfully.");
      navigate("/");
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, key, navigate]);

  const onSaveAsDraft = useCallback(async () => {
    const res = await saveApplication(true);
    res && navigate("/");
  }, [saveApplication, navigate]);

  const onSubmit = useCallback(async () => {
    if (!formData.isValid) {
      toast.error("Please fill in all mandatory fields.");
      return;
    }

    try {
      await confirm({
        label: "Confirm Submission",
        text: (
          <>
            <span>Are you sure you want to send this application for the employee to complete? </span>
            {!userHasPaymentMethod && (
              <>
                <br />
                <br />
                ** Please note that all applications will require an active payment method to succeed.
              </>
            )}
          </>
        ),
        confirmText: "Send",
        cancelText: "Cancel",
      });

      const res = await saveApplication(false);
      res && navigate("/");
    } catch (error) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, saveApplication, navigate, userHasPaymentMethod, formData]);

  const inviteCollaborator = useCallback(async () => {
    try {
      const email = await prompt({
        label: "Application Invitation",
        text: "Enter the email address of the person you would like to invite to this application:",
        placeholder: "Email address",
      });

      const link = buildApplicationCollaboratorLink({
        organisation: organisationName,
        organisationID,
        homeOrganisation: homeOrganisationID,
      });

      applicationInvitation({
        link,
        data: {
          to: email,
          subject: "Application Invitation",
        },
      });

      toast.success("Invitation to collaborate sent!");
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTaxDeclaration = useCallback(
    (val, name) => {
      setFormData((prev) => ({
        ...prev,
        data: { taxDeclaration: { ...prev.taxDeclaration, [name]: val } },
      }));
    },
    [setFormData]
  );

  useEffect(() => {
    setInitial(application);
  }, [application]);

  useEffect(() => {
    application?.status && application?.status !== "draft" ? setHasBeenSubmitted(true) : setHasBeenSubmitted(false);
  }, [application]);

  useEffect(() => {
    if (data?.employmentType === "EMPLOYEE") {
      setIncomeTypeOptions([
        { label: "Salary & Wages", value: "SALARYANDWAGES" },
        { label: "Closely Held Employees", value: "CLOSELYHELDPAYEES" },
        { label: "Working holiday maker", value: "WORKINGHOLIDAYMAKER" },
      ]);
    } else if (data?.employmentType === "CONTRACTOR") {
      setIncomeTypeOptions([
        { label: "Labour Hire", value: "LABOURHIRE" },
        { label: "Non-employee", value: "NONEMPLOYEE" },
      ]);
    }
  }, [data?.employmentType, setFormData]);

  return (
    <Form onSubmit={onSubmit} initial={initial} setFormData={setFormData}>
      <div className={clsx(styles["form-container"], hasBeenSubmitted && styles["submitted"])}>
        <div className={styles["form-heading"]}>
          {hasBeenSubmitted ? (
            <>
              <div className={styles["form-details"]}>
                <h2>
                  {application?.firstName} {application?.lastName}
                </h2>
                {screen === "desktop" && <StatusIcon status={application.status || "pending"} />}
              </div>

              <div className={styles["form-actions"]}>
                {screen !== "mobile" && (
                  <Button onClick={deleteApplication} icon={faTrash} theme={"red"} mr={"20px"}>
                    Delete Application
                  </Button>
                )}
                <Button
                  onClick={updateApplication}
                  icon={faSave}
                  theme={"white"}
                  className={styles["update-details-button"]}
                >
                  {loading ? "Saving..." : "Update Details"}
                </Button>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </>
          ) : (
            <>
              <div className={styles["form-details"]}>
                <h2>{`New Application for ${new URLSearchParams(window.location.search).get("organisation")}`}</h2>
                {screen === "desktop" && <StatusIcon status={"draft"} />}
              </div>
              <div className={styles["form-actions"]}>
                <Button onClick={inviteCollaborator} icon={faUserPlus} theme={"white"} mr={"40px"}>
                  Invite Collaborator
                </Button>
                <FontAwesomeIcon icon={faTimes} onClick={() => navigate("/")} />
              </div>
            </>
          )}
        </div>
        <div className={styles["form-content"]}>
          <div className={styles["form-content-half"]}>
            <div className={globalStyles["form-flex-container"]}>
              <div className={globalStyles["form-input-container"]}>
                <Input
                  type="text"
                  name={"firstName"}
                  label={"First Name"}
                  validation={isNotBlank}
                  placeholder={"John"}
                  message={"Enter a first name."}
                  maxLength={35}
                  mandatory
                />
              </div>
              <div className={globalStyles["form-input-container"]}>
                <Input
                  type="text"
                  name={"lastName"}
                  label={"Last Name"}
                  validation={isNotBlank}
                  placeholder={"Doe"}
                  message={"Enter a last name."}
                  maxLength={35}
                  mandatory
                />
              </div>
            </div>

            <div className={globalStyles["form-input-container"]}>
              <Input
                type="text"
                name={"email"}
                label={"Email"}
                validation={validateEmail}
                placeholder={"johndoe@example.com"}
                message={"Enter a valid email address."}
                mandatory
              />
            </div>
            <div className={globalStyles["form-input-container"]}>
              <ToggleButton
                name={"employmentType"}
                buttons={[
                  { label: "Employee", value: "EMPLOYEE" },
                  { label: "Contractor", value: "CONTRACTOR" },
                ]}
                mandatory
                onChange={() => {
                  setFormData((prev) => ({ ...prev, data: { ...prev.data, incomeType: null, employmentBasis: null } }));
                }}
                label={"Employment Type"}
              />
            </div>
            <div className={globalStyles["form-input-container"]}>
              <Select
                options={
                  data?.employmentType !== "CONTRACTOR"
                    ? [
                        { label: "Full Time", value: "FULLTIME" },
                        { label: "Part Time", value: "PARTTIME" },
                        { label: "Casual", value: "CASUAL" },
                      ]
                    : [
                        { label: "Labour Hire", value: "LABOURHIRE" },
                        { label: "Non-employee", value: "NONEMPLOYEE" },
                      ]
                }
                label={"Employment Basis"}
                placeholder={"select"}
                name={"employmentBasis"}
                mandatory
                onChange={(val) => {
                  updateTaxDeclaration(val, "employmentBasis");
                }}
              />
            </div>
          </div>
          <div className={styles["form-content-half"]}>
            <div className={globalStyles["form-input-container"]}>
              {data.employmentType !== "CONTRACTOR" && (
                <Select
                  mandatory
                  options={incomeTypeOptions}
                  label={"Income Type"}
                  placeholder={"select"}
                  name={"incomeType"}
                  disabled={!data?.employmentType}
                />
              )}
            </div>
            <div className={globalStyles["form-input-container"]}>
              <Input type="text" name={"jobTitle"} label={"Job Title"} placeholder={"Headmaster"} maxLength={100} />
            </div>
            <div className={globalStyles["form-flex-container"]}>
              <div className={globalStyles["form-input-container"]}>
                <Date label={"Start Date"} name={"startDate"} format={"YYYY-MM-DD"} />
              </div>
              <div className={clsx(globalStyles["form-input-container"], styles["hourly-rate"])}>
                <p className={styles["currency"]}>$</p>
                <Input type="numeric" name={"hourlyRate"} label={"Hourly Rate"} placeholder={"0.00"} />
                <p className={styles["unit"]}>/ hour</p>
              </div>
            </div>
            <div className={clsx(globalStyles["form-input-container"], styles["award-input"])}>
              <Input
                type="text"
                name={"classification"}
                label={"Award Classification"}
                placeholder={"MA000014"}
                maxLength={100}
                link={{
                  destination: "https://www.fairwork.gov.au/awards-and-agreements/awards/find-my-award",
                  label: "Find Award",
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles["form-buttons"]}>
          <p className={styles["error-notes"]}>{formData?.data?.notes}</p>
          {!hasBeenSubmitted && (
            <Button onClick={onSaveAsDraft} theme={"white"} icon={faHeart} className={styles["draft-button"]}>
              {saveDraftLoading ? "Saving..." : "Save as Draft"}
            </Button>
          )}
          {!hasBeenSubmitted && (
            <Button onClick={onSubmit} theme={"blue-dark"} icon={faPaperPlane}>
              {loading ? "Sending..." : "Send to employee"}
            </Button>
          )}

          <div className={styles["setup-paymentmethod"]}>
            {organisation && (
              <Button
                onClick={() => {
                  navigate("/new-payment-method?redirect=" + fullPath);
                }}
                icon={faUserPlus}
                theme={"blue"}
                ml={"20px"}
              >
                {!userHasPaymentMethod ? "Add Payment Method" : "Manage Payment Methods"}
              </Button>
            )}
          </div>

          {formData?.data?.status === "failed" && (
            <div className={styles["setup-paymentmethod"]}>
              <Button
                onClick={() => resubmitApplication()}
                icon={faUserPlus}
                theme={userHasPaymentMethod ? "blue " : "white"}
                ml={"20px"}
                disabled={loading}
              >
                Resubmit Application
              </Button>
            </div>
          )}
        </div>
      </div>
    </Form>
  );
};
export default EmpAppForm;
