import { useState } from "react";
import styles from "./Select.module.css";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import P from "../text/P";
import useValidation from "src/hooks/useValidation";
import useInlineStyles from "src/hooks/useInlineStyles";
import useToast from "src/hooks/useToast";
const Select = ({
  options = [],
  name,
  label,
  placeholder,
  width = "100%",
  validation = () => true,
  mandatory = false,
  message,
  disabled,
  onChange: onChangeProp,
  value: overideValue,
  setValue: setOverideValue = () => {},
  inActive = false,
  boxShadow,
  className,
  noHover,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const { error, setTouched, value, onChange } = useValidation({ name, validation, mandatory, onChangeProp });
  const style = useInlineStyles({ ...props });
  const toast = useToast();
  const selectedOption = options.find((op) => {
    return (
      (op.value && op.value === overideValue) ||
      op === overideValue ||
      (!overideValue && op.value === value) ||
      (!overideValue && op === value)
    );
  });

  return (
    <div
      className={clsx(
        styles["container"],
        disabled && styles["disabled"],
        boxShadow && styles["box-shadow-grey"],
        !noHover && styles["hover"],
        className
      )}
      style={style}
      onBlur={() => !disabled && setTouched(true)}
      onClick={() => disabled && toast.error("This field has been locked with read-only rights.")}
    >
      <div
        className={clsx(styles["wrapper"], error && styles["error"])}
        onClick={() => !disabled && !inActive && setOpen((prev) => !prev)}
      >
        <label className={styles["label"]}>{label}</label>
        {!overideValue && (!value || !selectedOption) ? (
          <div className={styles["placeholder"]}>
            <p>{placeholder}</p>
          </div>
        ) : (
          <div className={styles["value"]}>
            <p>{selectedOption?.label || selectedOption}</p>
          </div>
        )}
        {!disabled && <FontAwesomeIcon icon={faCaretDown} className={clsx(styles["icon"])} />}
      </div>
      {error && <P className={styles["message"]}>{message}</P>}
      {open && <div className={styles["overlay"]} onClick={() => setOpen(false)}></div>}
      <div className={clsx(styles["content"], open && styles["open"])}>
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className={styles["option"]}
              onClick={() => {
                onChange(option.value || option);
                setOpen(false);
                setOverideValue(option.value || option);
              }}
            >
              {option?.label || option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Select;
