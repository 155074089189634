import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/authSlice";
import { organisationActions } from "src/store/organisationSlice";
import { customFetch } from "src/utilities/http";
import { useCallback } from "react";
import useUI from "./useUI";
import useNav from "./useNav";

const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNav();

  const { loading, setLoading } = useUI();

  //Creates jwt token and stores access info in local storage
  const login = async ({ email, password }) => {
    const { data: user } = await customFetch({
      entity: "Public.Authentication",
      method: "LOGIN",
      data: { email, password },
    });

    user && dispatch(authActions.login(user));

    return user;
  };

  //Removes jwt token
  const logout = async () => {
    setLoading(true);
    dispatch(authActions.logout());
    dispatch(organisationActions.setOrganisation());
    localStorage.removeItem("token");
    setTimeout(() => {
      navigate("/portal");
      setLoading(false);
    }, 1000);
  };

  //Creates user account and logs in with new jwt token
  const signup = async ({ firstName, lastName, email, password }) => {
    setLoading(true);
    const { data: user } = await customFetch({
      entity: "Public.Authentication",
      method: "SIGNUP",
      data: { firstName, lastName, email, password },
    });
    user && dispatch(authActions.signup(user));
    setLoading(false);
    return user;
  };

  //Checks whether jwt token is present, if not assign public access jwt token
  const initAuth = useCallback(async () => {
    const { data: [user] = [] } = await customFetch({ entity: "User.User", method: "INIT_AUTH" });

    user && dispatch(authActions.login(user));

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    return user;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const setUser = useCallback(
    async (user) => {
      dispatch(authActions.setUser(user));
    },
    [dispatch]
  );

  return { auth, login, logout, signup, initAuth, setUser, loading, setLoading, ...auth };
};

export default useAuth;
