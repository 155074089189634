const superFunds = [
  {
    "Fund ABN": 30099320583,
    "Fund Name": "AMG Super",
    USI: "PTC0133AU",
    SPIN: "PTC0133AU",
    "Product Name": "AMG Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76514770399,
    "Fund Name": "AMP SUPERANNUATION SAVINGS TRUST",
    USI: "AMP0195AU",
    SPIN: "AMP0195AU",
    "Product Name": "AMP Flexible Lifetime Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76514770399,
    "Fund Name": "AMP SUPERANNUATION SAVINGS TRUST",
    USI: "AMP0276AU",
    SPIN: "AMP0276AU",
    "Product Name": "AMP Retirement Savings Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76514770399,
    "Fund Name": "AMP SUPERANNUATION SAVINGS TRUST",
    USI: "AMP0402AU",
    SPIN: "AMP0402AU",
    "Product Name": "AMP Personal Superannuation (AMPAK) - Investment Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 68964712340,
    "Fund Name": "Aon Master Trust",
    USI: "68964712340004",
    SPIN: "AON0200AU",
    "Product Name": "Aon Master Trust - Corporate Super - Aon STAFF",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 68964712340,
    "Fund Name": "Aon Master Trust",
    USI: "68964712340002",
    SPIN: "AON0202AU",
    "Product Name": "Aon Master Trust - Personal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 68964712340,
    "Fund Name": "Aon Master Trust",
    USI: "68964712340001",
    SPIN: "AON0201AU; AON0200AU",
    "Product Name": "Aon Master Trust - Corporate Super",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 90194410365,
    "Fund Name": "ASGARD Independence Plan Division Two",
    USI: "90194410365001",
    SPIN: "ASG0002AU",
    "Product Name": "Asgard Elements Super Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 90194410365,
    "Fund Name": "ASGARD Independence Plan Division Two",
    USI: "90194410365003",
    SPIN: "ASG0002AU",
    "Product Name": "Asgard Elements Pension Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 90194410365,
    "Fund Name": "ASGARD Independence Plan Division Two",
    USI: "90194410365005",
    SPIN: "ASG0020AU",
    "Product Name": "Asgard Infinity eWRAP Super Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 90194410365,
    "Fund Name": "ASGARD Independence Plan Division Two",
    USI: "90194410365004",
    SPIN: "ASG0020AU",
    "Product Name": "Asgard eWRAP Super Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 16457520308,
    "Fund Name": "Auscoal Superannuation Fund",
    USI: "16457520308001",
    SPIN: "COS0001AU",
    "Product Name": "AUSCOAL Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 49633667743,
    "Fund Name": "Australian Ethical Retail Superannuation Fund",
    USI: "AET0100AU",
    SPIN: "AET0100AU",
    "Product Name": "Australian Ethical Retail Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 65714394898,
    "Fund Name": "AustralianSuper",
    USI: "STA0100AU",
    SPIN: "STA0100AU",
    "Product Name": "AustralianSuper",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681002",
    SPIN: "AIL0001AU",
    "Product Name": "Investment Exchange Retirement Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681010",
    SPIN: "AIL0002AU",
    "Product Name": "FirstWrap Super and Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681001",
    SPIN: "AIL0003AU",
    "Product Name": "FirstWrap Plus Super and Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681003",
    SPIN: "AIL0006AU",
    "Product Name": "Beacon Superannuation and Pension Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681007",
    SPIN: "AIL0007AU",
    "Product Name": "Omniport Superannuation and Pension Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681008",
    SPIN: "AIL0008AU",
    "Product Name": "Northhaven Financial Management Super Wrap",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681004",
    SPIN: "AIL0012AU",
    "Product Name": "Commonwealth Private Super and Pension Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38876896681,
    "Fund Name": "Avanteos Superannuation Trust",
    USI: "38876896681005",
    SPIN: "AIL0015AU",
    "Product Name": "encircle Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 57526653420,
    "Fund Name": "Bendigo Superannuation Plan",
    USI: "STL0050AU",
    SPIN: "STL0050AU",
    "Product Name": "Bendigo SmartStart Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 85571332201,
    "Fund Name": "BUSSQ",
    USI: "BUS0002AU",
    SPIN: "BUS0002AU",
    "Product Name": "BUSSQ",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98172275725,
    "Fund Name": "CARE Super",
    USI: "CAR0100AU",
    SPIN: "CAR0100AU",
    "Product Name": "CARE Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 87883998803,
    "Fund Name": "Challenger Retirement Fund",
    USI: "CIT0102AU",
    SPIN: "CIT0102AU",
    "Product Name": "Challenger Guaranteed Personal Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 66628776348,
    "Fund Name": "Christian Super",
    USI: "CHR0001AU",
    SPIN: "CHR0001AU",
    "Product Name": "Christian Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 45828721007,
    "Fund Name": "ClearView Retirement Plan",
    USI: "CVW0001AU",
    SPIN: "CVW0001AU",
    "Product Name": "ClearView WealthFoundations Super and Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 26458298557,
    "Fund Name": "Colonial First State Firstchoice Superannuation Trust",
    USI: "FSF0361AU",
    SPIN: "FSF0361AU",
    "Product Name": "Colonial First State FirstChoice Employer Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 26458298557,
    "Fund Name": "Colonial First State Firstchoice Superannuation Trust",
    USI: "FSF0511AU",
    SPIN: "FSF0511AU",
    "Product Name": "Colonial First State FirstChoice Wholesale Personal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 88854638840,
    "Fund Name": "Colonial First State Rollover and Superannuation Fund",
    USI: "FSF0360AU",
    SPIN: "FSF0360AU",
    "Product Name": "Colonial First State Personal Pension Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469101",
    SPIN: "COM0002AU",
    "Product Name": "Commonwealth Personal Superannuation & Rollover Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469102",
    SPIN: "COM0101AU",
    "Product Name": "Commonwealth SuperSelect",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469221",
    SPIN: "COM0101AU",
    "Product Name": "Ilinked ManagedSP",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469222",
    SPIN: "COM0101AU",
    "Product Name": "Ilinked Retirement",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469223",
    SPIN: "COM0101AU",
    "Product Name": "Ilinked RetirePlus",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469224",
    SPIN: "COM0101AU",
    "Product Name": "Ilinked SuperClu",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469225",
    SPIN: "COM0101AU",
    "Product Name": "Ilinked Superwise4",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469226",
    SPIN: "COM0101AU",
    "Product Name": "Ilinked Superwise5",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469103",
    SPIN: "COM0117AU",
    "Product Name": "Commonwealth PensionSelect",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469231",
    SPIN: "PRU0163AU",
    "Product Name": "Colonial Select Personal Superannuation ADFPS",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469232",
    SPIN: "PRU0163AU",
    "Product Name": "Colonial Select Personal Superannuation CMPS",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469233",
    SPIN: "PRU0163AU",
    "Product Name": "Colonial Select Personal Superannuation MPPS",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469234",
    SPIN: "PRU0163AU",
    "Product Name": "Colonial Select Personal Superannuation PRUPS",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469235",
    SPIN: "PRU0163AU",
    "Product Name": "Colonial Select Personal Superannuation SCPS",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40328908469,
    "Fund Name": "Colonial Super Retirement Fund",
    USI: "40328908469247",
    SPIN: "PRU0188AU",
    "Product Name": "CPSL Master Fund Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24248426878,
    "Fund Name": "Commonwealth Bank Group Super",
    USI: "24248426878001",
    SPIN: "OSF0001AU",
    "Product Name": "Retirement Access",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24248426878,
    "Fund Name": "Commonwealth Bank Group Super",
    USI: "OSF0001AU",
    SPIN: "OSF0001AU",
    "Product Name": "Accumulate Plus",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24248426878,
    "Fund Name": "Commonwealth Bank Group Super",
    USI: "24248426878201",
    SPIN: "OSF0001AU ",
    "Product Name": "Commonwealth Bank Super Defined Benefits",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 48123123124,
    "Fund Name": "Commonwealth Bank of Australia",
    USI: "48123123124130",
    SPIN: "COM0164AU",
    "Product Name": "Superannuation Savings Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 56601925435,
    "Fund Name": "Commonwealth Essential Super",
    USI: "FSF1332AU",
    SPIN: "FSF1332AU",
    "Product Name": "Commonwealth Essential Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19415776361,
    "Fund Name": "Commonwealth Superannuation Scheme",
    USI: "19415776361001",
    SPIN: "CMS0100AU",
    "Product Name": "Commonwealth Superannuation Scheme",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 75493363262,
    "Fund Name": "Construction and Building Unions Superannuation Fund",
    USI: "CBU0100AU",
    SPIN: "CBU0100AU",
    "Product Name": "Cbus",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 47412936330,
    "Fund Name": "Definitive Superannuation Plan",
    USI: "47412936330161",
    SPIN: "MAQ0628AU",
    "Product Name": "Definitive Wrap Super Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 58632310300,
    "Fund Name": "Deseret Benefit Plan for Australia",
    USI: "58632310300001",
    SPIN: "DBP0100AU",
    "Product Name": "Deseret Benefit Plan for Australia",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 46074281314,
    "Fund Name": "DIY Master Plan",
    USI: "46074281314001",
    SPIN: "TCS0008AU",
    "Product Name": "DIY Master Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 39771753561,
    "Fund Name": "Dow Australia Superannuation Fund",
    USI: "39771753561001",
    SPIN: "DOW0100AU",
    "Product Name": "Dow Australia Superannuation Fund",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 40725722496,
    "Fund Name": "DPM Retirement Service",
    USI: "40725722496001",
    SPIN: "NUL0368AU",
    "Product Name": "DPM Retirement Service - Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40725722496,
    "Fund Name": "DPM Retirement Service",
    USI: "40725722496002",
    SPIN: "NUL0367AU",
    "Product Name": "DPM Retirement Service - Account-based Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 77408077215,
    "Fund Name": "Elphinstone Group Superannuation Fund",
    USI: "77408077215001",
    SPIN: "WAE0100AU",
    "Product Name": "Elphinstone Group Superannuation Fund",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 18838658991,
    "Fund Name": "EmPlus Superannuation Fund",
    USI: "ETL0196AU",
    SPIN: "ETL0196AU",
    "Product Name": "EmPlus Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 22277243559,
    "Fund Name": "Energy Industries Superannuation Scheme - Pool A",
    USI: "EIS0001AU",
    SPIN: "EIS0001AU",
    "Product Name": "EISS Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 22277243559,
    "Fund Name": "Energy Industries Superannuation Scheme - Pool A",
    USI: "EIS0101AU",
    SPIN: "EIS0101AU",
    "Product Name": "EISS Pool A Division A E and N",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 22277243559,
    "Fund Name": "Energy Industries Superannuation Scheme - Pool A",
    USI: "EIS0102AU",
    SPIN: "EIS0102AU",
    "Product Name": "EISS Pool A Division F Rollover Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 22277243559,
    "Fund Name": "Energy Industries Superannuation Scheme - Pool A",
    USI: "EIS0103AU",
    SPIN: "EIS0103AU",
    "Product Name": "EISS Pool A Division F Allocated Pension Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 64322090181,
    "Fund Name": "Energy Industries Superannuation Scheme - Pool B",
    USI: "EIS0105AU",
    SPIN: "EIS0105AU",
    "Product Name": "EISS Pool B Division B",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 64322090181,
    "Fund Name": "Energy Industries Superannuation Scheme - Pool B",
    USI: "EIS0106AU",
    SPIN: "EIS0106AU",
    "Product Name": "EISS Pool B Division D",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 33813823017,
    "Fund Name": "equipsuper",
    USI: "33813823017000",
    SPIN: "EPL0100AU",
    "Product Name": "EquipSuper",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "AUS0001AU",
    SPIN: "AUS0001AU",
    "Product Name": "Freedom of Choice Corporate Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "AUS0025AU",
    SPIN: "AUS0025AU",
    "Product Name": "Freedom of Choice Personal Allocated Pension Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "ETS0001AU",
    SPIN: "ETS0001AU",
    "Product Name": "Freedom of Choice Business Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "ETS0002AU",
    SPIN: "ETS0002AU",
    "Product Name": "Freedom of Choice Personal Allocated Pension Service",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "ETS0003AU",
    SPIN: "ETS0003AU",
    "Product Name": "Freedom of Choice Personal Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "ETS0009AU",
    SPIN: "ETS0009AU",
    "Product Name": "simpleWRAP Personal Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "ETS0010AU",
    SPIN: "ETS0010AU",
    "Product Name": "simpleWRAP Retirement Service",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "FOC0001AU",
    SPIN: "FOC0001AU",
    "Product Name": "Freedom of Choice Employer Sponsored Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 38531644711,
    "Fund Name": "EquitySuper",
    USI: "FOC0004AU",
    SPIN: "FOC0004AU",
    "Product Name": "Freedom of Choice Personal Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 85894637037,
    "Fund Name": "ESSSuper",
    USI: "ESS0001AU",
    SPIN: "ESS0001AU",
    "Product Name": "ESSSuper Beneficiary Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 85894637037,
    "Fund Name": "ESSSuper",
    USI: "ESS0003AU",
    SPIN: "ESS0003AU",
    "Product Name": "ESSSuper Accumulation plan ",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 57929339093,
    "Fund Name": "Fiducian Superannuation Fund",
    USI: "57929339093001",
    SPIN: "FPS0101AU",
    "Product Name": "Fiducian Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 57929339093,
    "Fund Name": "Fiducian Superannuation Fund",
    USI: "57929339093002",
    SPIN: "FPS0102AU",
    "Product Name": "Fiducian Corporate Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 96873087231,
    "Fund Name": "FIRST QUEST RETIREMENT SERVICE",
    USI: "96873087231002",
    SPIN: "SGY0027AU",
    "Product Name": "First Quest Retirement Service - Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53226460365,
    "Fund Name": "First State Superannuation Scheme",
    USI: "53226460365001",
    SPIN: "FSS0100AU; HES001AU ",
    "Product Name": "First State Superannuation - Accumulation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53226460365,
    "Fund Name": "First State Superannuation Scheme",
    USI: "53226460365002",
    SPIN: "FSS0100AU",
    "Product Name": "First State Superannuation - Income Stream",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 53226460365,
    "Fund Name": "First State Superannuation Scheme",
    USI: "53226460365003",
    SPIN: "HES0001AU ",
    "Product Name": "First State Superannuation - Defined Benefits",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 56286625181,
    "Fund Name": "First Super",
    USI: "FIS0001AU",
    SPIN: "FIS0001AU",
    "Product Name": "First Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 55697537183,
    "Fund Name": "GOLDMAN SACHS & JBWERE SUPERANNUATION FUND",
    USI: "55697537183000",
    SPIN: "BES0001AU",
    "Product Name": "Goldman Sachs & JBWere Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 32367272075,
    "Fund Name": "Grosvenor Pirie Master Superannuation Fund - Series 2",
    USI: "32367272075151",
    SPIN: "TCS0004AU",
    "Product Name": "Grosvenor Pirie Super - GPML Balanced Growth",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 22599554834,
    "Fund Name": "Guild Retirement Fund",
    USI: "22599554834401",
    SPIN: "GLD0001AU",
    "Product Name": "GUILD SUPER",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 22599554834,
    "Fund Name": "Guild Retirement Fund",
    USI: "22599554834402",
    SPIN: "INN0002AU",
    "Product Name": "CHILD CARE SUPER",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 64971749321,
    "Fund Name": "Health Employees Superannuation Trust Australia",
    USI: "HST0100AU",
    SPIN: "HST0100AU",
    "Product Name": "HESTA",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 64971749321,
    "Fund Name": "Health Employees Superannuation Trust Australia",
    USI: "HST0001AU",
    SPIN: "HST0001AU",
    "Product Name": "HESTA Income Stream",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 68657495890,
    "Fund Name": "HOSTPLUS Superannuation Fund",
    USI: "HOS0100AU",
    SPIN: "HOS0100AU",
    "Product Name": "HOSTPLUS Superannuation Fund - Industry",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 60910190523,
    "Fund Name": "HUB24 Super Fund",
    USI: "60910190523001",
    SPIN: "TCS0011AU",
    "Product Name": "HUB24 Super Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 58244115920,
    "Fund Name": "IAG & NRMA SUPERANNUATION PLAN",
    USI: "58244115920001",
    SPIN: "INS0001AU ",
    "Product Name": "NRMA Members",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 58244115920,
    "Fund Name": "IAG & NRMA SUPERANNUATION PLAN",
    USI: "58244115920002",
    SPIN: "INS0001AU ",
    "Product Name": "IAG Members",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 13355603448,
    "Fund Name": "ING Direct Superannuation Fund",
    USI: "13355603448001",
    SPIN: "TCS0012AU",
    "Product Name": "ING Direct Living Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 65704511371,
    "Fund Name": "Intrust Superannuation Fund",
    USI: "HPP0100AU",
    SPIN: "HPP0100AU",
    "Product Name": "Intrust Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "AMG0063AU",
    SPIN: "AMG0063AU",
    "Product Name": "Lifetrack Personal Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "70815369818002",
    SPIN: "AMG0063AU",
    "Product Name": "Lifetrack Employer Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "AMG0064AU",
    SPIN: "AMG0064AU",
    "Product Name": "LifeTrack Cashback Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "AMG0065AU",
    SPIN: "AMG0065AU",
    "Product Name": "LifeTrack Corporate Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "70815369818004",
    SPIN: "AMG0065AU",
    "Product Name": "LifeTrack Staff Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "IOF0056AU",
    SPIN: "IOF0056AU",
    "Product Name": "IOOF Portfolio Service Employer Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "IOF0057AU",
    SPIN: "IOF0057AU",
    "Product Name": "IOOF Portfolio Service Personal Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "70815369818001",
    SPIN: "IOF0057AU",
    "Product Name": "IOOF Portfolio Service Wholesale Personal Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70815369818,
    "Fund Name": "IOOF Portfolio Service Superannuation Fund",
    USI: "SMF0126AU",
    SPIN: "SMF0126AU",
    "Product Name": "IOOF Employer Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 60346078879,
    "Fund Name": "LegalSuper",
    USI: "LIS0100AU",
    SPIN: "LIS0100AU",
    "Product Name": "Legal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94579217553,
    "Fund Name": "Lifefocus Superannuation Fund",
    USI: "94579217553001",
    SPIN: "TQM0005AU",
    "Product Name": "LifeFocus Private eWrap Super Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94579217553,
    "Fund Name": "Lifefocus Superannuation Fund",
    USI: "94579217553002",
    SPIN: "TQM0005AU",
    "Product Name": "LifeFocus Wholesale eWrap Super Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94579217553,
    "Fund Name": "Lifefocus Superannuation Fund",
    USI: "94579217553003",
    SPIN: "TQM0005AU",
    "Product Name": "LifeFocus eWrap Super Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 74925979278,
    "Fund Name": "Local Government Superannuation Scheme - Pool A",
    USI: "LGS0101AU",
    SPIN: "LGS0101AU",
    "Product Name": "LGSS Pool A Accumulation Scheme",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 23053121564,
    "Fund Name": "Local Government Superannuation Scheme Qld",
    USI: "QLG0001AU",
    SPIN: "QLG0001AU",
    "Product Name": "LGsuper",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 65508799106,
    "Fund Name": "Macquarie Superannuation Plan",
    USI: "65508799106031",
    SPIN: "MAQ0157AU",
    "Product Name": "Macquarie Super Manager (including client branded products)",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 65508799106,
    "Fund Name": "Macquarie Superannuation Plan",
    USI: "65508799106041",
    SPIN: "MAQ0311AU",
    "Product Name": "Macquarie Super Accumulator",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 65508799106,
    "Fund Name": "Macquarie Superannuation Plan",
    USI: "65508799106172",
    SPIN: "MAQ0779AU",
    "Product Name": "Macquarie Super Consolidator (including client branded products)",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 34422545198,
    "Fund Name": "MANAGED AUSTRALIAN RETIREMENT FUND",
    USI: "34422545198001",
    SPIN: "MAR0001AU",
    "Product Name": "MANAGED AUSTRALIAN RETIREMENT FUND",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 92181844838,
    "Fund Name": "Mercer Portfolio Service Superannuation Plan",
    USI: "92181844838001",
    SPIN: "MIN0004AU",
    "Product Name": "Mercer Portfolio Service Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981999",
    SPIN: "MIN0001AU",
    "Product Name": "Mercer Super Trust - SmartSuper",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981224",
    SPIN: "MIN0001AU",
    "Product Name": "Mercer Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981046",
    SPIN: "MIN0001AU",
    "Product Name": "IGT (Australia) Pty Ltd Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981049",
    SPIN: "MIN0001AU",
    "Product Name": "Rider Levett Bucknall Staff Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981055",
    SPIN: "MIN0001AU",
    "Product Name": "Medtel Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981056",
    SPIN: "MIN0001AU",
    "Product Name": "Scientific Games Products (Australia) Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981074",
    SPIN: "MIN0001AU",
    "Product Name": "Becton Dickinson (Australia) Staff Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981083",
    SPIN: "MIN0001AU",
    "Product Name": "WTH Pty Ltd Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981087",
    SPIN: "MIN0001AU",
    "Product Name": "Ferro Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981089",
    SPIN: "MIN0001AU",
    "Product Name": "Cameron Australasia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981091",
    SPIN: "MIN0001AU",
    "Product Name": "Coca Cola Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981094",
    SPIN: "MIN0001AU",
    "Product Name": "Australian Railroad Group Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981098",
    SPIN: "MIN0001AU",
    "Product Name": "Koppers Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981105",
    SPIN: "MIN0001AU",
    "Product Name": "Innovia Films and Innovia Security Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981109",
    SPIN: "MIN0001AU",
    "Product Name": "CPS Staff Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981112",
    SPIN: "MIN0001AU",
    "Product Name": "Grace Australia Retirement Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981116",
    SPIN: "MIN0001AU",
    "Product Name": "CCI Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981118",
    SPIN: "MIN0001AU",
    "Product Name": "HLRA Retirement Accumulation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981119",
    SPIN: "MIN0001AU",
    "Product Name": "Automotive Holdings Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981123",
    SPIN: "MIN0001AU",
    "Product Name": "Agrium Australia Staff Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981129",
    SPIN: "MIN0001AU",
    "Product Name": "CNH Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981132",
    SPIN: "MIN0001AU",
    "Product Name": "ASX Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981137",
    SPIN: "MIN0001AU",
    "Product Name": "PPG Australia Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981148",
    SPIN: "MIN0001AU",
    "Product Name": "Allergan Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981149",
    SPIN: "MIN0001AU",
    "Product Name": "GrainCorp Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981153",
    SPIN: "MIN0001AU",
    "Product Name": "Motorola Solutions Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981154",
    SPIN: "MIN0001AU",
    "Product Name": "First Data Corporation Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981159",
    SPIN: "MIN0001AU",
    "Product Name": "Norske Skog Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981161",
    SPIN: "MIN0001AU",
    "Product Name": "The Minerals Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981163",
    SPIN: "MIN0001AU",
    "Product Name": "Kennametal Australia Pty Ltd",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981164",
    SPIN: "MIN0001AU",
    "Product Name": "Mount Scopus Memorial College Retirement Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981165",
    SPIN: "MIN0001AU",
    "Product Name": "The Stanley Black & Decker Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981168",
    SPIN: "MIN0001AU",
    "Product Name": "DDB Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981169",
    SPIN: "MIN0001AU",
    "Product Name": "NWS 9 Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981174",
    SPIN: "MIN0001AU",
    "Product Name": "Halliburton Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981175",
    SPIN: "MIN0001AU",
    "Product Name": "Ernst & Young Partners Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981176",
    SPIN: "MIN0001AU",
    "Product Name": "EMI Group Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981180",
    SPIN: "MIN0001AU",
    "Product Name": "Brisbane Airport Corporation Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981182",
    SPIN: "MIN0001AU",
    "Product Name": "Hatch Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981184",
    SPIN: "MIN0001AU",
    "Product Name": "Baulderstone Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981185",
    SPIN: "MIN0001AU",
    "Product Name": "Heat And Control Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981188",
    SPIN: "MIN0001AU",
    "Product Name": "RT Edwards Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981189",
    SPIN: "MIN0001AU",
    "Product Name": "NYK Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981190",
    SPIN: "MIN0001AU",
    "Product Name": "IMS Health Australia Pty Ltd Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981192",
    SPIN: "MIN0001AU",
    "Product Name": "Dalrymple Bay Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981195",
    SPIN: "MIN0001AU",
    "Product Name": "Penleigh & Essendon Grammar School Super Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981199",
    SPIN: "MIN0001AU",
    "Product Name": "Hydro Aluminium Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981203",
    SPIN: "MIN0001AU",
    "Product Name": "Avon Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981204",
    SPIN: "MIN0001AU",
    "Product Name": "John Deere Employees Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981205",
    SPIN: "MIN0001AU",
    "Product Name": "Novartis Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981206",
    SPIN: "MIN0001AU",
    "Product Name": "Syngenta Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981207",
    SPIN: "MIN0001AU",
    "Product Name": "Metal Manufactures Group Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981209",
    SPIN: "MIN0001AU",
    "Product Name": "Auscott Limited Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981210",
    SPIN: "MIN0001AU",
    "Product Name": "Rockwell Automation Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981211",
    SPIN: "MIN0001AU",
    "Product Name": "Coventry Group Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981213",
    SPIN: "MIN0001AU",
    "Product Name": "Fairfax Media Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981215",
    SPIN: "MIN0001AU",
    "Product Name": "Lion Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981216",
    SPIN: "MIN0001AU",
    "Product Name": "Adelaide Brighton Group Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981217",
    SPIN: "MIN0001AU",
    "Product Name": "Unisys Australian Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981218",
    SPIN: "MIN0001AU",
    "Product Name": "Ericsson Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981219",
    SPIN: "MIN0001AU",
    "Product Name": "Rivalea Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981220",
    SPIN: "MIN0001AU",
    "Product Name": "Reckitt Benckiser (Australia) Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981221",
    SPIN: "MIN0001AU",
    "Product Name": "South East Fibre Exports Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981222",
    SPIN: "MIN0001AU",
    "Product Name": "Swiss Re Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981225",
    SPIN: "MIN0001AU",
    "Product Name": "Marsh Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981226",
    SPIN: "MIN0001AU",
    "Product Name": "Cargill Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981227",
    SPIN: "MIN0001AU",
    "Product Name": "Auspine Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981232",
    SPIN: "MIN0001AU",
    "Product Name": "Santos Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981234",
    SPIN: "MIN0001AU",
    "Product Name": "Fonterra Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981236",
    SPIN: "MIN0001AU",
    "Product Name": "CSC Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981237",
    SPIN: "MIN0001AU",
    "Product Name": "CHC Helicopters (Australia) Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981239",
    SPIN: "MIN0001AU",
    "Product Name": "Angus & Coote Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981240",
    SPIN: "MIN0001AU",
    "Product Name": "Cement Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981242",
    SPIN: "MIN0001AU",
    "Product Name": "Caltex Petroleum Services Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981243",
    SPIN: "MIN0001AU",
    "Product Name": "Phillips Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981244",
    SPIN: "MIN0001AU",
    "Product Name": "Pernod Ricard Winemakers Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981245",
    SPIN: "MIN0001AU",
    "Product Name": "Ridley Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981246",
    SPIN: "MIN0001AU",
    "Product Name": "Malaysia Airlines Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981247",
    SPIN: "MIN0001AU",
    "Product Name": "Thorn Lighting Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981249",
    SPIN: "MIN0001AU",
    "Product Name": "KBR Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981251",
    SPIN: "MIN0001AU",
    "Product Name": "Catalent Australia Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981252",
    SPIN: "MIN0001AU",
    "Product Name": "Luxottica Retail Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981254",
    SPIN: "MIN0001AU",
    "Product Name": "The Wrigley Company Superannuation Scheme",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981255",
    SPIN: "MIN0001AU",
    "Product Name": "Thai Airways International Staff Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981256",
    SPIN: "MIN0001AU",
    "Product Name": "Yalumba Staff Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981259",
    SPIN: "MIN0001AU",
    "Product Name": "Mercedes - Benz Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981260",
    SPIN: "MIN0001AU",
    "Product Name": "Devro Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981261",
    SPIN: "MIN0001AU",
    "Product Name": "Wesfarmers Group Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981262",
    SPIN: "MIN0001AU",
    "Product Name": "Sydney Airport Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981263",
    SPIN: "MIN0001AU",
    "Product Name": "Deutsche Staff Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981264",
    SPIN: "MIN0001AU",
    "Product Name": "Trend-Breezway Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981267",
    SPIN: "MIN0001AU",
    "Product Name": "Brambles Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981268",
    SPIN: "MIN0001AU",
    "Product Name": "James Hardie Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981269",
    SPIN: "MIN0001AU",
    "Product Name": "Baxter Healthcare Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981270",
    SPIN: "MIN0001AU",
    "Product Name": "Australian Radio Network Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981272",
    SPIN: "MIN0001AU",
    "Product Name": "DIC Australia Pty Limited Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981273",
    SPIN: "MIN0001AU",
    "Product Name": "Parsons Brinckerhoff Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981274",
    SPIN: "MIN0001AU",
    "Product Name": "TNT Group Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981275",
    SPIN: "MIN0001AU",
    "Product Name": "Vodafone Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981276",
    SPIN: "MIN0001AU",
    "Product Name": "Australian Associated Press Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981277",
    SPIN: "MIN0001AU",
    "Product Name": "Woodside Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981278",
    SPIN: "MIN0001AU",
    "Product Name": "American Express Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981279",
    SPIN: "MIN0001AU",
    "Product Name": "Scotch College Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981280",
    SPIN: "MIN0001AU",
    "Product Name": "Trinity Grammar School Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981281",
    SPIN: "MIN0001AU",
    "Product Name": "Ivanhoe Girls Grammar School Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981284",
    SPIN: "MIN0001AU",
    "Product Name": "Sony BMG Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981285",
    SPIN: "MIN0001AU",
    "Product Name": "BAE Systems Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981286",
    SPIN: "MIN0001AU",
    "Product Name": "Accolade Wines Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981288",
    SPIN: "MIN0001AU",
    "Product Name": "MGC Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981290",
    SPIN: "MIN0001AU",
    "Product Name": "McCain Foods Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981293",
    SPIN: "MIN0001AU",
    "Product Name": "Du Pont Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981294",
    SPIN: "MIN0001AU",
    "Product Name": "ALSTOM Grid Australia Limited Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981296",
    SPIN: "MIN0001AU",
    "Product Name": "V A Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981297",
    SPIN: "MIN0001AU",
    "Product Name": "Crane Group Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981298",
    SPIN: "MIN0001AU",
    "Product Name": "Fuji Xerox Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981299",
    SPIN: "MIN0001AU",
    "Product Name": "Network Ten  Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981300",
    SPIN: "MIN0001AU",
    "Product Name": "Lexmark Australia Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981301",
    SPIN: "MIN0001AU",
    "Product Name": "Eli Lilly Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981302",
    SPIN: "MIN0001AU",
    "Product Name": "Wesley College Melbourne Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981303",
    SPIN: "MIN0001AU",
    "Product Name": "Stratco Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981304",
    SPIN: "MIN0001AU",
    "Product Name": "Cummins Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981305",
    SPIN: "MIN0001AU",
    "Product Name": "Nuplex Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981307",
    SPIN: "MIN0001AU",
    "Product Name": "Seven Network Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981308",
    SPIN: "MIN0001AU",
    "Product Name": "Melbourne Grammar School Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981310",
    SPIN: "MIN0001AU",
    "Product Name": "Raytheon Super Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981317",
    SPIN: "MIN0001AU",
    "Product Name": "DX Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981320",
    SPIN: "MIN0001AU",
    "Product Name": "Myer Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981323",
    SPIN: "MIN0001AU",
    "Product Name": "Pfizer Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981324",
    SPIN: "MIN0001AU",
    "Product Name": "DP World Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981328",
    SPIN: "MIN0001AU",
    "Product Name": "Qube Superannuation Plan - Qube Ports & Bulk Employees",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981329",
    SPIN: "MIN0001AU",
    "Product Name": "Qube superannuation Plan - Qube Logistics Employees",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981331",
    SPIN: "MIN0001AU",
    "Product Name": "Americold Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981333",
    SPIN: "MIN0001AU",
    "Product Name": "Estee Lauder Companies Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981334",
    SPIN: "MIN0001AU",
    "Product Name": "UTC Building and Industrial Systems Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981336",
    SPIN: "MIN0001AU",
    "Product Name": "GlaxoSmithKline Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981337",
    SPIN: "MIN0001AU",
    "Product Name": "Ford Employees Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981339",
    SPIN: "MIN0001AU",
    "Product Name": "Schweppes Australia Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981340",
    SPIN: "MIN0001AU",
    "Product Name": "Nyrstar Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981345",
    SPIN: "MIN0001AU",
    "Product Name": "Oasis Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 19905422981,
    "Fund Name": "MERCER SUPER TRUST",
    USI: "19905422981888",
    SPIN: "MIN0001AU",
    "Product Name": "Mercer SmartSuper Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40022701955,
    "Fund Name": "MLC Superannuation Fund",
    USI: "40022701955012",
    SPIN: "NUL0086AU",
    "Product Name": "blueprint Retirement Plan - Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40022701955,
    "Fund Name": "MLC Superannuation Fund",
    USI: "40022701955009",
    SPIN: "NUL0089AU",
    "Product Name": "FC ONE Retirement Builder - Superannuation Service (including Series 2)",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40022701955,
    "Fund Name": "MLC Superannuation Fund",
    USI: "40022701955015",
    SPIN: "NUL0095AU",
    "Product Name": "Portfoliofocus - Premium Retirement Service - Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40022701955,
    "Fund Name": "MLC Superannuation Fund",
    USI: "40022701955018",
    SPIN: "NUL0098AU",
    "Product Name": "St Andrew's Retirement Plan - Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40022701955,
    "Fund Name": "MLC Superannuation Fund",
    USI: "40022701955001",
    SPIN: "NUL0360AU",
    "Product Name": "MLC Navigator Retirement Plan - Superannuation Service (including Series 2)",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40022701955,
    "Fund Name": "MLC Superannuation Fund",
    USI: "40022701955023",
    SPIN: "NUL0390AU",
    "Product Name": "Enevita Platinum Retirement Mgmt Solution - Superannuation Service (including Series 2)",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 40022701955,
    "Fund Name": "MLC Superannuation Fund",
    USI: "40022701955006",
    SPIN: "NUL0392AU",
    "Product Name": "MLC Wrap Super - Super Service (including Series 2)",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 74559365913,
    "Fund Name": "MTAA Superannuation Fund",
    USI: "MTA0100AU",
    SPIN: "MTA0100AU",
    "Product Name": "MTAA Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "NML0429AU",
    SPIN: "NML0429AU",
    "Product Name": "Flexipol",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "76746741299003",
    SPIN: "NML0430AU",
    "Product Name": "Accelerator",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "76746741299004",
    SPIN: "NML0430AU",
    "Product Name": "GoldLine",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "NML0432AU",
    SPIN: "NML0432AU",
    "Product Name": "Personal Super Bond",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "NML0433AU",
    SPIN: "NML0433AU",
    "Product Name": "Provider",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "NML0434AU",
    SPIN: "NML0434AU",
    "Product Name": "Provider Top Up Retirement Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "NML0435AU",
    SPIN: "NML0435AU",
    "Product Name": "Retirement Bond",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 76746741299,
    "Fund Name": "NATIONAL MUTUAL RETIREMENT FUND",
    USI: "NML0436AU",
    SPIN: "NML0436AU",
    "Product Name": "Retirement Security Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 15201768813,
    "Fund Name": "Nationwide Superannuation Fund",
    USI: "NSF0100AU",
    SPIN: "NSF0100AU",
    "Product Name": "Nationwide Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 33472013860,
    "Fund Name": "Nestle Australia Group Superannuation Fund",
    USI: "33472013860001",
    SPIN: "NAP0100AU",
    "Product Name": "Nestle Australia Group Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0017AU",
    SPIN: "NET0017AU",
    "Product Name": "netwealth Super Accelerator - Personal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0018AU",
    SPIN: "NET0018AU",
    "Product Name": "netwealth Super Accelerator - Employer Sponsored Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0102AU",
    SPIN: "NET0102AU",
    "Product Name": "netwealth Super Wrap - Personal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0103AU",
    SPIN: "NET0103AU",
    "Product Name": "netwealth Super Wrap - Employer Sponsored Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0012AU",
    SPIN: "NET0012AU",
    "Product Name": "netwealth Super Wrap - Transition to Retirement Income Stream",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0019AU",
    SPIN: "NET0019AU",
    "Product Name": "netwealth Super Accelerator - Transition to Retirement Income Stream",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0020AU",
    SPIN: "NET0020AU",
    "Product Name": "netwealth Super Accelerator - Term Allocated Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0021AU",
    SPIN: "NET0021AU",
    "Product Name": "netwealth Super Accelerator - Standard Income Stream",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0101AU",
    SPIN: "NET0101AU",
    "Product Name": "netwealth Super Wrap - Standard Income Stream",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 94573747704,
    "Fund Name": "Netwealth Superannuation Master Fund",
    USI: "NET0104AU",
    SPIN: "NET0104AU",
    "Product Name": "netwealth Super Wrap - Term Allocated Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 72229227691,
    "Fund Name": "New South Wales Electrical Superannuation Scheme",
    USI: "NES0100AU",
    SPIN: "NES0100AU",
    "Product Name": "New South Wales Electrical Scheme",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 57170878804,
    "Fund Name": "NEWCASTLE PERMANENT SUPERANNUATION PLAN",
    USI: "57170878804000",
    SPIN: "NPB0102AU",
    "Product Name": "NEWCASTLE PERMANENT SUPERANNUATION PLAN",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 57170878804,
    "Fund Name": "Newcastle Permanent Superannuation Plan",
    USI: "57170878804799",
    SPIN: "NPB0101AU",
    "Product Name": "Newcastle Permanent Super Plan (Pension Division)",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 73549180515,
    "Fund Name": "NGS SUPER",
    USI: "73549180515701",
    SPIN: "NGS0001AU",
    "Product Name": "NGS SUPER",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 73549180515,
    "Fund Name": "NGS SUPER",
    USI: "73549180515702",
    SPIN: "NGS0001AU",
    "Product Name": "SA Catholic Plan - DB Members",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 73549180515,
    "Fund Name": "NGS SUPER",
    USI: "73549180515703",
    SPIN: "NGS0001AU",
    "Product Name": "Eltham College - DB Members",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 73549180515,
    "Fund Name": "NGS SUPER",
    USI: "73549180515705",
    SPIN: "NGS0001AU",
    "Product Name": "Penleigh & Essendon Grammar - DB Members",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 73549180515,
    "Fund Name": "NGS SUPER",
    USI: "73549180515706",
    SPIN: "NGS0001AU",
    "Product Name": "Cuesuper - DB Members",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 73549180515,
    "Fund Name": "NGS SUPER",
    USI: "73549180515799",
    SPIN: "NGS0001AU",
    "Product Name": "NGS Super - Allocated Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 24774305410,
    "Fund Name": "Nissan Superannuation Plan",
    USI: "24774305410001",
    SPIN: "NSP0100AU",
    "Product Name": "Nissan Superannuation Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 81154851339,
    "Fund Name": "Oasis Superannuation Master Trust",
    USI: "OAM0001AU",
    SPIN: "OAM0001AU",
    "Product Name": "Oasis Superannuation Master Trust",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "ANZ0074AU",
    SPIN: "ANZ0074AU",
    "Product Name": "ANZ Personal Superannuation Bond",
    "Contribution Restrictions": "no",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "ANZ0265AU",
    SPIN: "ANZ0265AU",
    "Product Name": "ANZ Super Advantage",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "ANZ0323AU",
    SPIN: "ANZ0323AU",
    "Product Name": "ANZ OneAnswer Allocated Pension - Entry Fee",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "ANZ0324AU",
    SPIN: "ANZ0324AU",
    "Product Name": "ANZ OneAnswer Allocated Pension - Deferred Entry Fee",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "ANZ0325AU",
    SPIN: "ANZ0325AU",
    "Product Name": "ANZ OneAnswer Personal Super - Entry Fee",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "ANZ0326AU",
    SPIN: "ANZ0326AU",
    "Product Name": "ANZ OneAnswer Personal Super - Nil Entry Fee",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "ANZ0415AU",
    SPIN: "ANZ0415AU",
    "Product Name": "ANZ Superannuation Savings Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "LEF0160AU",
    SPIN: "LEF0160AU",
    "Product Name": "OptiMix Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "MMF0146AU",
    SPIN: "MMF0146AU",
    "Product Name": "Integra Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "MMF0333AU",
    SPIN: "MMF0333AU",
    "Product Name": "OneAnswer Frontier Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "MMF0334AU",
    SPIN: "MMF0334AU",
    "Product Name": "OneAnswer Frontier Personal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "MMF0393AU",
    SPIN: "MMF0393AU",
    "Product Name": "Corporate Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "MMF2076AU",
    SPIN: "MMF2076AU",
    "Product Name": "ANZ Smart Choice Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 53789980697,
    "Fund Name": "OnePath Masterfund",
    USI: "MMF2077AU",
    SPIN: "MMF2077AU",
    "Product Name": "ANZ Smart Choice Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 17608890083,
    "Fund Name": "Oracle Superannuation Plan",
    USI: "17608890083001",
    SPIN: "OPA0100AU",
    "Product Name": "Oracle Superannuation Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 22897174641,
    "Fund Name": "Perpetual Super Wrap",
    USI: "22897174641174",
    SPIN: "PER0667AU",
    "Product Name": "Perpetual Select Allocated Pension Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 41772007500,
    "Fund Name": "Perpetual WealthFocus Superannuation Fund",
    USI: "PER0068AU",
    SPIN: "PER0068AU",
    "Product Name": "Perpetual Private Pension Wrap",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 41772007500,
    "Fund Name": "Perpetual WealthFocus Superannuation Fund",
    USI: "PER0403AU",
    SPIN: "PER0403AU",
    "Product Name": "Perpetual WealthFocus Super Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 51068260563,
    "Fund Name": "Perpetual WealthFocus Superannuation Fund",
    USI: "PER0405AU",
    SPIN: "PER0405AU",
    "Product Name": "Perpetual WealthFocus Allocated Pension Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 51068260563,
    "Fund Name": "Perpetual's Select Superannuation Fund",
    USI: "PER0138AU",
    SPIN: "PER0138AU",
    "Product Name": "Perpetual Select Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 20339905340,
    "Fund Name": "Plum Superannuation Fund",
    USI: "20339905340001",
    SPIN: "PSN0100AU",
    "Product Name": "Plum Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70479285132,
    "Fund Name": "PremiumChoice Retirement Service",
    USI: "70479285132001",
    SPIN: "NUL0357AU",
    "Product Name": "PremiumChoice Retirement Service - Superannuation Service (including Series 2)",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 70479285132,
    "Fund Name": "PremiumChoice Retirement Service",
    USI: "70479285132002",
    SPIN: "NUL0358AU",
    "Product Name": "PremiumChoice Retirement Service - Account-based Pension (including Series 2)",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 65127917725,
    "Fund Name": "Public Sector Superannuation Accumulation Plan",
    USI: "65127917725001",
    SPIN: "PSS0001AU",
    "Product Name": "Public Sector Superannuation Accumulation Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 74172177893,
    "Fund Name": "Public Sector Superannuation Scheme",
    USI: "74172177893001",
    SPIN: "CMS0101AU",
    "Product Name": "Public Sector Superannuation Scheme",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 76641658449,
    "Fund Name": "REI SUPER",
    USI: "76641658449601",
    SPIN: "REI0001AU",
    "Product Name": "REI Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 25303030489,
    "Fund Name": "Reserve Bank of Australia Officers' Superannuation Fund",
    USI: "RBA0100AU",
    SPIN: "RBA0100AU",
    "Product Name": "Reserve Bank of Australia Officers' Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 62653671394,
    "Fund Name": "Retail Employees Superannuation Trust",
    USI: "RES0101AU",
    SPIN: "RES0101AU",
    "Product Name": "REST Select",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 62653671394,
    "Fund Name": "Retail Employees Superannuation Trust",
    USI: "RES0103AU",
    SPIN: "RES0103AU",
    "Product Name": "REST Industry Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 62653671394,
    "Fund Name": "Retail Employees Superannuation Trust",
    USI: "RES0104AU",
    SPIN: "RES0104AU",
    "Product Name": "Acumen",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 61808189263,
    "Fund Name": "Retirement Portfolio Service",
    USI: "61808189263052",
    SPIN: "LEF0052AU",
    "Product Name": "PortfolioOne Pension Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 61808189263,
    "Fund Name": "Retirement Portfolio Service",
    USI: "61808189263051",
    SPIN: "LEF0053AU",
    "Product Name": "PortfolioOne Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 61808189263,
    "Fund Name": "Retirement Portfolio Service",
    USI: "61808189263089",
    SPIN: "LEF0077AU",
    "Product Name": "PortfolioOne Term Allocated Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 39827542991,
    "Fund Name": "Retirement Wrap",
    USI: "BTA0134AU",
    SPIN: "BTA0134AU",
    "Product Name": "BT Retirement Selection - Personal Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 39827542991,
    "Fund Name": "Retirement Wrap",
    USI: "BTA0136AU",
    SPIN: "BTA0136AU",
    "Product Name": "BT Lifetime Super - Employer Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 39827542991,
    "Fund Name": "Retirement Wrap",
    USI: "BTA0280AU",
    SPIN: "BTA0280AU",
    "Product Name": "BT Super for Life - Retirement Account",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 39827542991,
    "Fund Name": "Retirement Wrap",
    USI: "BTA0287AU",
    SPIN: "BTA0287AU",
    "Product Name": "BT Super for Life - Savings Account",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 39827542991,
    "Fund Name": "Retirement Wrap",
    USI: "TCS0105AU",
    SPIN: "TCS0105AU",
    "Product Name": "SuperWrap Pension Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 39827542991,
    "Fund Name": "Retirement Wrap",
    USI: "TCS0106AU",
    SPIN: "TCS0106AU",
    "Product Name": "SuperWrap Personal Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 39827542991,
    "Fund Name": "Retirement Wrap",
    USI: "WFS0401AU",
    SPIN: "WFS0401AU",
    "Product Name": "BT Superannuation Investment Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 89384753567,
    "Fund Name": "Russell SuperSolution Master Trust",
    USI: "TRM0001AU",
    SPIN: "TRM0001AU",
    "Product Name": "Russell SuperSolution Master Trust",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 89384753567,
    "Fund Name": "Russell SuperSolution Master Trust",
    USI: "TRM0016AU",
    SPIN: "TRM0016AU",
    "Product Name": "Russell Private Active Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 29239066746,
    "Fund Name": "SAS Trustee Corporation Pooled Fund",
    USI: "29239066746003",
    SPIN: "SAS0100AU ",
    "Product Name": "State Super Scheme",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 29239066746,
    "Fund Name": "SAS Trustee Corporation Pooled Fund",
    USI: "29239066746006",
    SPIN: "SAS0101AU",
    "Product Name": "STC Pooled Fund - Pensions",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 29239066746,
    "Fund Name": "SAS Trustee Corporation Pooled Fund",
    USI: "29239066746002",
    SPIN: "SAS0101AU ",
    "Product Name": "Police Superannuation Scheme",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 29239066746,
    "Fund Name": "SAS Trustee Corporation Pooled Fund",
    USI: "29239066746004",
    SPIN: "SAS0101AU ",
    "Product Name": "STC Pooled Fund - Deferred Benefits",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 29239066746,
    "Fund Name": "SAS Trustee Corporation Pooled Fund",
    USI: "29239066746001",
    SPIN: "SAS0102AU ",
    "Product Name": "State Authorities Superannuation Scheme",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 43905581638,
    "Fund Name": "Smartsave 'Member's Choice' Superannuation Master Plan",
    USI: "TCS0102AU",
    SPIN: "TCS0102AU",
    "Product Name": "Smartsave 'Member's Choice' Superannuation Master Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 82810851250,
    "Fund Name": "SMF Eligible Rollover Fund",
    USI: "RSA0114AU",
    SPIN: "RSA0114AU",
    "Product Name": "SMF Eligible Rollover Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24276733959,
    "Fund Name": "Star Portfolio Superannuation Fund",
    USI: "24276733959001",
    SPIN: "AIL0010AU",
    "Product Name": "Star Portfolio Super and Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 86664654341,
    "Fund Name": "State Super Retirement Fund",
    USI: "SSI0009AU",
    SPIN: "SSI0009AU",
    "Product Name": "State Super Allocated Pension Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 86664654341,
    "Fund Name": "State Super Retirement Fund",
    USI: "SSI0017AU",
    SPIN: "SSI0017AU",
    "Product Name": "State Super Personal Retirement Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 54145196298,
    "Fund Name": "Statewide Superannuation Trust",
    USI: "SSP0001AU",
    SPIN: "SSP0001AU",
    "Product Name": "Statewide Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "CTE0078AU",
    SPIN: "CTE0078AU",
    "Product Name": "Partnership Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "CTE0080AU",
    SPIN: "CTE0080AU",
    "Product Name": "INVESCO Accumulations Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "CTE0084AU",
    SPIN: "CTE0084AU",
    "Product Name": "Connelly Temple Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0001AU",
    SPIN: "RSA0001AU",
    "Product Name": "Workforce Superannuation Corporate",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0003AU",
    SPIN: "RSA0003AU",
    "Product Name": "Suncorp Wealthsmart Business Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0004AU",
    SPIN: "RSA0004AU",
    "Product Name": "Suncorp WealthSmart Personal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0100AU",
    SPIN: "RSA0100AU",
    "Product Name": "Connelly Temple Super Savings Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0102AU",
    SPIN: "RSA0102AU",
    "Product Name": "Connelly Temple Employer Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0105AU",
    SPIN: "RSA0105AU",
    "Product Name": "Connelly Temple Superannuation Master Trust Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0108AU",
    SPIN: "RSA0108AU",
    "Product Name": "Optimum Professional Superannuation Savings",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0111AU",
    SPIN: "RSA0111AU",
    "Product Name": "Optimum Corporate Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0112AU",
    SPIN: "RSA0112AU",
    "Product Name": "Optimum Personal Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0682AU",
    SPIN: "RSA0682AU",
    "Product Name": "Suncorp Everyday Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "98350952022042",
    SPIN: "SIS0100AU",
    "Product Name": "Suncorp Employee Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0002AU",
    SPIN: "RSA0002AU",
    "Product Name": "Suncorp Wealthsmart Allocated Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 98350952022,
    "Fund Name": "Suncorp Master Trust",
    USI: "RSA0681AU",
    SPIN: "RSA0681AU",
    "Product Name": "Suncorp Everyday Super Pension",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 98503137921,
    "Fund Name": "Sunsuper Superannuation Fund",
    USI: "98503137921001",
    SPIN: "SSR0100AU",
    "Product Name": "Sunsuper Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 78421957449,
    "Fund Name": "SUPER DIRECTIONS FUND",
    USI: "NML0258AU",
    SPIN: "NML0258AU",
    "Product Name": "Super Directions - Personal Super Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 78421957449,
    "Fund Name": "SUPER DIRECTIONS FUND",
    USI: "NML0287AU",
    SPIN: "NML0287AU",
    "Product Name": "Retirement Directions - Allocated Pension Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 78421957449,
    "Fund Name": "SUPER DIRECTIONS FUND",
    USI: "NML0437AU",
    SPIN: "NML0437AU",
    "Product Name": "Tailored Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 78421957449,
    "Fund Name": "SUPER DIRECTIONS FUND",
    USI: "NML0438AU",
    SPIN: "NML0438AU",
    "Product Name": "Simple Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 73703878235,
    "Fund Name": "SuperTrace Eligible Rollover Fund",
    USI: "73703878235251",
    SPIN: "LGL0515AU",
    "Product Name": "Super Trace",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 24685968122,
    "Fund Name": "Symetry Personal Retirement Fund",
    USI: "24685968122001",
    SPIN: "AIL0011AU",
    "Product Name": "FinHQ Wrap I Super and Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24685968122,
    "Fund Name": "Symetry Personal Retirement Fund",
    USI: "24685968122002",
    SPIN: "AIL0013AU",
    "Product Name": "Symetry Foundation Super and Pension",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24685968122,
    "Fund Name": "Symetry Personal Retirement Fund",
    USI: "24685968122003",
    SPIN: "SYM0008AU",
    "Product Name": "Symetry Lifetime Super and Pension Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 85502108833,
    "Fund Name": "Telstra Superannuation Scheme",
    USI: "TLS0100AU",
    SPIN: "TLS0100AU",
    "Product Name": "Telstra Superannuation Scheme",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 77343563307,
    "Fund Name": "TWU Superannuation Fund",
    USI: "TWU0101AU",
    SPIN: "TWU0101AU",
    "Product Name": "TWU Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 97900294767,
    "Fund Name": "Ultimate Superannuation Fund",
    USI: "97900294767001",
    SPIN: "AIL0016AU",
    "Product Name": "Ultimate Super and Pension Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 91385943850,
    "Fund Name": "UniSuper",
    USI: "91385943850001",
    SPIN: "UNI0001AU",
    "Product Name": "UniSuper",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24496637884,
    "Fund Name": "Vision Super",
    USI: "24496637884020",
    SPIN: "VSN0003AU",
    "Product Name": "Vision Super Saver",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 24496637884,
    "Fund Name": "Vision Super",
    USI: "24496637884003",
    SPIN: "VSN0004AU",
    "Product Name": "Vision Personal Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 92381911598,
    "Fund Name": "WEALTH PERSONAL SUPERANNUATION AND PENSION FUND",
    USI: "NMM0006AU",
    SPIN: "NMM0006AU",
    "Product Name": "iAccess Personal Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 92381911598,
    "Fund Name": "WEALTH PERSONAL SUPERANNUATION AND PENSION FUND",
    USI: "NMM0008AU",
    SPIN: "NMM0008AU",
    "Product Name": "Generations Personal Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 92381911598,
    "Fund Name": "WEALTH PERSONAL SUPERANNUATION AND PENSION FUND",
    USI: "NMM0104AU",
    SPIN: "NMM0104AU",
    "Product Name": "Summit Master Trust -  Personal Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 92381911598,
    "Fund Name": "WEALTH PERSONAL SUPERANNUATION AND PENSION FUND",
    USI: "92381911598002",
    SPIN: "NMS0001AU",
    "Product Name": "North Personal Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 81236903448,
    "Fund Name": "Westpac Mastertrust Superannuation Division",
    USI: "WFS0112AU",
    SPIN: "WFS0112AU",
    "Product Name": "BT Business Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 81236903448,
    "Fund Name": "Westpac Mastertrust Superannuation Division",
    USI: "WFS0123AU",
    SPIN: "WFS0123AU",
    "Product Name": "Personal Portfolio Service - Personal Superannuation Plan",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 81236903448,
    "Fund Name": "Westpac Mastertrust Superannuation Division",
    USI: "81236903448001",
    SPIN: "WFS0341AU",
    "Product Name": "Term Life as Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 81236903448,
    "Fund Name": "Westpac Mastertrust Superannuation Division",
    USI: "81236903448004",
    SPIN: "WFS0341AU",
    "Product Name": "Income Protection as Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 81236903448,
    "Fund Name": "Westpac Mastertrust Superannuation Division",
    USI: "WFS0342AU",
    SPIN: "WFS0342AU",
    "Product Name": "Westpac Flexible Income Plan",
    "Contribution Restrictions": "Yes- contact fund",
  },
  {
    "Fund ABN": 81236903448,
    "Fund Name": "Westpac Mastertrust Superannuation Division",
    USI: "WFS0343AU",
    SPIN: "WFS0343AU",
    "Product Name": "Westpac Lifetime Superannuation Service",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 36369876939,
    "Fund Name": "Westpac Personal Superannuation Fund",
    USI: "WFS0338AU",
    SPIN: "WFS0338AU",
    "Product Name": "Westpac Personal Superannuation Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 36369876939,
    "Fund Name": "Westpac Personal Superannuation Fund",
    USI: "WFS0339AU",
    SPIN: "WFS0339AU",
    "Product Name": "Westpac Personal Superannuation Investment Fund",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 36369876939,
    "Fund Name": "Westpac Personal Superannuation Fund",
    USI: "36369876939008",
    SPIN: "WFS0340AU",
    "Product Name": "Term Life as Superannuation",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 36369876939,
    "Fund Name": "Westpac Personal Superannuation Fund",
    USI: "36369876939005",
    SPIN: "WFS0340AU",
    "Product Name": "Westpac Simple Super",
    "Contribution Restrictions": "No",
  },
  {
    "Fund ABN": 33632838393,
    "Fund Name": "Zurich Master Superannuation Fund",
    USI: "33632838393987",
    SPIN: "ZUR0473AU",
    "Product Name": "Zurich Master Superannuation Fund",
    "Contribution Restrictions": "Yes- contact fund",
  },
];

export default superFunds;
