import styles from "../Application.module.css";
import logo from "src/assets/global/eline-logo.svg";
const ApplicationHeader = ({ heading, subline }) => {
  return (
    <div className={styles["header"]}>
      <img src={logo} alt="header-logo" className={styles["header-logo"]} />
      <h1>{heading}</h1>
      <p>{subline}</p>
      <div className={styles["header-divider"]}></div>
    </div>
  );
};

export default ApplicationHeader;
