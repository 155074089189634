import styles from "../Application.module.css";
import Input from "src/components/Interface/inputs/Input";
import Button from "src/components/Interface/buttons/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { isNotBlank } from "src/utilities/validation";
import { newGuid } from "src/utilities/generate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useForm } from "src/contexts/Form";
import { useState, useEffect, useCallback, useRef } from "react";
import { customFetch } from "src/utilities/http";
import CreateSuperFund from "../subcomponents/CreateSuperFund";
import ControlButtons from "../subcomponents/ControlButtons";
import globalStyles from "src/Global.module.css";
import superFundsList from "src/data/superFundsList";
import SearchSelect from "src/components/Interface/inputs/SearchSelect";

const SuperDetails = ({ tab, setTab }) => {
  const { data, setData } = useForm();
  const [funds, setFunds] = useState(data?.superMemberships);
  const [availableFunds, setAvailableFunds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [mergedFunds, setMergedFunds] = useState(superFundsList);
  const [loadingSelect, setLoadingSelect] = useState(true);

  const controlButtonRef = useRef();

  useEffect(() => {
    const arr = superFundsList.map((fund) => ({ ...fund, superFundID: newGuid() }));

    availableFunds?.forEach((fund) => {
      const index = arr.findIndex((item) => item["USI"] === fund["uSI"]);
      if (index === -1) {
        arr.push({ ...fund, "Product Name": fund["name"], USI: fund["uSI"], superFundID: fund.superFundID });
      } else {
        arr[index] = { ...arr[index], ...fund };
      }
    });
    setMergedFunds(arr);
  }, [availableFunds]);

  const getSuperFunds = useCallback(async () => {
    setLoadingSelect(true);
    const urlParams = new URLSearchParams(window.location.search);
    const organisationID = urlParams.get("organisationID");
    const res = await customFetch({
      node: "xero-node",
      method: "GET_SUPERFUNDS",
      data: { organisationID: organisationID },
    });

    setAvailableFunds(res.data || []);
    setLoadingSelect(false);
  }, []);

  useEffect(() => {
    getSuperFunds();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData((prev) => ({ ...prev, superMemberships: funds }));
  }, [funds, setData]);

  const manageSelectedFund = async (fundID, idx) => {
    const dbSuperFund = mergedFunds.find((fund) => fund["superFundID"] === fundID);

    const availableSuperFund = availableFunds.find((fund) => fund["superFundID"] === fundID);

    if (availableSuperFund) {
      setFunds((prev) => {
        const newFunds = [...prev];
        newFunds[idx] = { ...availableSuperFund, id: availableSuperFund?.superFundID, employeeNumber: "" };
        return newFunds;
      });
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const organisationID = urlParams.get("organisationID");

    const { data } = await customFetch({
      node: "xero-node",
      method: "CREATE_SUPERFUND",
      data: {
        superFund: {
          aBN: dbSuperFund["Fund ABN"],
          uSI: dbSuperFund["USI"],
          name: dbSuperFund["Product Name"],
          type: "REGULATED",
        },
        organisationID: organisationID,
      },
    });

    if (!data) {
      return;
    }

    setFunds((prev) => {
      const newFunds = [...prev];
      newFunds[idx] = { ...data, id: data?.superFundID, employeeNumber: "" };
      return newFunds;
    });

    getSuperFunds();
  };

  const removeSuperMemberships = async () => {
    setFunds([]);
    controlButtonRef.current?.onSubmit(false);
  };
  return (
    <>
      <CreateSuperFund showModal={showModal} setShowModal={setShowModal} refresh={getSuperFunds} />
      <div className={styles["content-wrapper"]}>
        <div className={styles["form-container"]}>
          <div className={styles["no-super-button"]} onClick={removeSuperMemberships}>
            <p>I don't have a super membership</p>
          </div>
          <p className={styles["content-heading"]}>Super Details</p>
          {data.incomeType !== "LABOURHIRE" && (
            <div className={clsx()}>
              <div className={styles["super-container"]}>
                <div className={styles["super-add-btn"]}>
                  <Button
                    theme={"blue"}
                    size={"large"}
                    icon={faPlus}
                    mx={"auto"}
                    onClick={() => setFunds((prev) => [...prev, { id: newGuid() }])}
                  >
                    Add Super Details
                  </Button>
                </div>
                <div className={styles["funds"]}>
                  {funds.map((_, idx) => {
                    return (
                      <div className={styles["fund"]} key={_.id || _?.superFundID}>
                        <div className={globalStyles["form-flex-container"]}>
                          <div className={globalStyles["form-input-container"]}>
                            <SearchSelect
                              options={mergedFunds.map((fund) => {
                                return { label: fund["Product Name"] + " - " + fund.USI, value: fund?.superFundID };
                              })}
                              label={"Super Fund"}
                              placeholder={"select"}
                              value={_?.superFundID}
                              onChange={(val) => {
                                manageSelectedFund(val, idx);
                              }}
                              loading={loadingSelect}
                              mandatory
                            />
                          </div>
                          <div className={globalStyles["form-input-container"]}>
                            <Input
                              type="text"
                              value={_.employeeNumber}
                              label={"Member Number"}
                              validation={isNotBlank}
                              name={"employeeNumber" + idx + _?.superFundID}
                              placeholder={"456"}
                              onChange={(val) => {
                                setFunds((prev) => {
                                  const newFunds = [...prev];
                                  newFunds[idx] = { ...newFunds[idx], employeeNumber: val };
                                  return newFunds;
                                });
                              }}
                              mandatory
                            />
                          </div>
                          <div
                            className={styles["super-bin"]}
                            onClick={() => {
                              setFunds((prev) => {
                                const newFunds = [...prev];
                                newFunds.splice(idx, 1);
                                return newFunds;
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} className={styles["super-trash"]} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={styles["create-new-fund"]}
                  onClick={() => data["status"] !== "success" && setShowModal(true)}
                >
                  <p>
                    <span>+</span> Don't see your fund? Add it manually.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <ControlButtons setTab={setTab} tab={tab} ref={controlButtonRef} />
      </div>
    </>
  );
};
export default SuperDetails;
