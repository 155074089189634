import Main from "src/components/layout/Main";
import styles from "./AllApplications.module.css";
import PaginationBar from "../dashboard/components/subcomponents/PaginationBar";
import { useState, useEffect } from "react";
import { customFetch } from "src/utilities/http";
import ApplicationCard from "../dashboard/components/subcomponents/ApplicationCard";
import LoadingSpinner from "src/components/layout/LoadingSpinner";
import useDelayUnmount from "src/hooks/useDelayUnmount";
import useWindowSize from "src/hooks/useWindowSize";
import StatusIcon from "src/components/misc/StatusIcon";
import useOrganisation from "src/hooks/useOrganisation";

const AllApplications = () => {
  const [visibleApplications, setVisibleApplications] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const showLoader = useDelayUnmount(loading);
  const { screen } = useWindowSize();
  const { organisation } = useOrganisation();

  useEffect(() => {
    setLoading(true);
    const getApplications = async () => {
      const { data = [] } = await customFetch({
        entity: "Application.Application",
        method: "GET",
        criteria: { organisationID: organisation?.organisationID },
      });
      setApplications(data);

      localStorage.setItem("lastAccess", new Date());
      setLoading(false);
    };

    getApplications();
  }, [organisation]);

  
  let divisor;
  switch (screen) {
    case "desktop":
      divisor = 21;
      break;
    case "tablet":
      divisor = 9;
      break;
    case "mobile":
      divisor = 3;
      break;
    default:
      divisor = 21;
      break;
  }

  return (
    <>
      <Main>
        <div className={styles["container"]}>
          <div className={styles["header"]}>
            <div className={styles["header-legend"]}>
              <h1>All Applications</h1>
              <div className={styles["progress-legend"]}>
                <div className={styles["progress-status-container"]}>
                  <StatusIcon status={"draft"} />
                  <StatusIcon status={"pending"} />
                  <StatusIcon status={"failed"} />
                </div>
              </div>
            </div>
            <div className={styles["header-controls"]}></div>
          </div>
          {showLoader ? (
            <div className={styles["loading-container"]}>
              <LoadingSpinner colour={"blue"} loader={loading} />
            </div>
          ) : (
            <>
              {applications.length > 0 ? (
                <div className={styles["applications"]}>
                  {visibleApplications?.map((application) => (
                    <ApplicationCard key={application.key} application={application} />
                  ))}
                </div>
              ) : (
                <div className={styles["no-applications"]}>
                  <p>
                    You don't have any active applications! <br />
                    Create one to track its progress here.
                  </p>
                </div>
              )}
            </>
          )}

          <div className={styles["pagination"]}>
            <PaginationBar
              applications={applications}
              visibleApplications={visibleApplications}
              setVisibleApplications={setVisibleApplications}
              divisor={divisor}
            />
          </div>
        </div>
      </Main>
    </>
  );
};

export default AllApplications;
