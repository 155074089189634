import { useCallback, useEffect, useState } from "react";
import styles from "../Portal.module.css";
import illustration from "src/assets/svg/login-illustration.svg";
import logo from "src/assets/global/eline-logo.svg";
import Input from "src/components/Interface/inputs/Input";
import { validatePassword } from "src/utilities/validation";
import useToast from "src/hooks/useToast";
import { customFetch } from "src/utilities/http";
import Button from "src/components/Interface/buttons/Button";
import globalStyles from "src/Global.module.css";
const ResetEmail = ({ setTab }) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const { status } = await customFetch({
        entity: "Public.Authentication",
        method: "VALIDATE_RESET_TOKEN",
        data: { token },
      });

      if (status !== 200) {
        toast.error("Invalid reset token.");
        setTab("login");
      }
    })();
  });

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const { status } = await customFetch({
      entity: "Public.Authentication",
      data: {
        token,
        password,
      },
      method: "SAVE_NEW_PASSWORD",
    });

    setLoading(false);

    if (status === 200) {
      setTab("login");
      toast.success("You can now log in with your new password.");
    }

    //eslint-disable-next-line
  }, [password, setTab]);
  return (
    <div className={styles["container"]}>
      <div className={styles["login-left"]}>
        <img src={logo} alt="logo graphic" className={styles["login-logo"]} />
        <div className={styles["reset-details"]}>
          <div className={globalStyles["form-input-container"]}>
            <Input
              value={password}
              onChange={setPassword}
              background
              label={"New Password"}
              placeholder={"********"}
              validation={validatePassword}
              message={"Please enter a valid password"}
            />
          </div>
          <div className={globalStyles["form-input-container"]}>
            <Input
              background
              label={"Confirm Password"}
              placeholder={"********"}
              validation={(v) => v === password}
              message={"Passwords don't match."}
            />
          </div>

          <Button onClick={onSubmit} size={"medium"} mt={"40px"}>
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
      <div className={styles["login-right"]}>
        <img src={illustration} alt="login graphic" className={styles["login-illustration"]} />
      </div>
    </div>
  );
};
export default ResetEmail;
