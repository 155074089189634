import Button from "src/components/Interface/buttons/Button";
import styles from "../Application.module.css";
import clsx from "clsx";
import { useForm } from "src/contexts/Form";
import useToast from "src/hooks/useToast";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { customFetch } from "src/utilities/http";
import { useParams } from "react-router-dom";
import useAlerts from "src/hooks/useAlerts";
import useNav from "src/hooks/useNav";

const ControlButtons = forwardRef(function ControlBtns({ tab, setTab }, ref) {
  const toast = useToast();
  const { data, isValid } = useForm();
  const { key } = useParams();
  const navigate = useNav();
  const { confirm } = useAlerts();
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  const onSubmit = useCallback(
    async (draft = false) => {
      setLoading(true);
      if (data["status"] === "success") {
        navigate("/thankyou");
        return;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const organisationID = urlParams.get("organisationID");
      const organisation = urlParams.get("organisation");
      let homeOrganisation = urlParams.get("homeOrganisation");
      const userID = urlParams.get("userID");

      if (!homeOrganisation) {
        const { data = {} } = await customFetch({
          entity: "Public.User",
          method: "GET_HOME_ORGANISATION",
          data: { userID },
        });

        homeOrganisation = data;
      }

      delete data["_id"];

      try {
        !draft &&
          (await confirm({
            label: "Confirm Submission",
            text: "Are you sure you want to submit this application?",
            confirmText: "Submit",
            cancelText: "Cancel",
            withOverlay: false,
          }));
      } catch (error) {
        setLoading(false);
        return;
      }

      const { status } = await customFetch({
        entity: "Public.Application",
        method: draft ? "CREATE" : "SUBMIT", //Allow employees to save as draft
        data: {
          application: { ...data },
          organisationID,
          homeOrganisation,
          key,
          draft,
          send: false,
          organisationName: organisation,
        },
      });

      setLoading(false);
      if (status === 400) {
        return;
      }

      toast.success("Application saved successfully");
      !draft && navigate("/thankyou");
    },
    //eslint-disable-next-line
    [data, key, navigate]
  );

  return (
    <div className={styles["control-buttons-container"]}>
      <div className={styles["header-divider"]}></div>
      <div className={styles["control-buttons"]}>
        <div
          className={clsx(styles["back-control"], tab === 0 && styles["disabled"])}
          onClick={() => {
            if (tab === 0) {
              return;
            }
            setTab((prev) => prev - 1);
          }}
        >
          <div className={clsx(styles["back-indicator"])}></div>
          <p>Go Back</p>
        </div>
        <div className={styles["next-control"]}>
          <Button
            size={"large"}
            onClick={() => onSubmit(true)}
            theme={"blue-light"}
            className={styles["draft-button"]}
            disabled={loading}
          >
            Save as Draft
          </Button>

          <Button
            size={"large"}
            disabled={loading}
            onClick={() => {
              if (isValid) {
                tab < 4 ? setTab((prev) => prev + 1) : onSubmit();
                window.scrollTo(0, 400);
                return;
              }
              toast.error("Please complete all required fields");
            }}
          >
            {tab < 4 ? "Next" : loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ControlButtons;
