import Modal from "src/components/Interface/layout/Modal";
import styles from "./InviteTeam.module.css";
import Input from "src/components/Interface/inputs/Input";
import { useCallback, useState } from "react";
import Form from "src/components/Interface/layout/Form";
import { isNotBlank } from "src/utilities/validation";
import Button from "src/components/Interface/buttons/Button";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import globalStyles from "src/Global.module.css";
import useOrganisation from "src/hooks/useOrganisation";
import { buildHomeOrgCollaboratorLink } from "src/utilities/generate";
import { collaborationInvitation } from "src/utilities/Email";
import useToast from "src/hooks/useToast";
import useAuth from "src/hooks/useAuth";

const InviteTeam = ({ show, setShow }) => {
  const [formData, setFormData] = useState({});
  const { homeOrganisation } = useOrganisation();
  const {
    auth: { user },
  } = useAuth();

  const toast = useToast();
  const onSubmit = useCallback(async () => {
    const { data, isValid } = formData;
    if (!isValid) return;

    const link = buildHomeOrgCollaboratorLink({
      homeOrganisation: homeOrganisation._id,
    });

    collaborationInvitation({
      owner: user?.personal?.firstName,
      user: data.name,
      link,
      data: {
        to: data.email,
        subject: "Application Invitation",
      },
    });

    toast.success("Application invitation sent!");

    setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, homeOrganisation, setShow, user]);

  return (
    <Modal
      showModal={show}
      setShowModal={setShow}
      label={"Invite New Team Member"}
      subLabel={"Please fill in the form fields below."}
      className={styles["modal"]}
    >
      <Form onSubmit={onSubmit} setFormData={setFormData} className={styles["form"]}>
        <div className={globalStyles["form-input-container"]}>
          <Input
            name={"name"}
            label={"First Name"}
            placeholder={"John"}
            mandatory
            validation={isNotBlank}
            message={"Required"}
            background
          />
        </div>
        <div className={globalStyles["form-input-container"]}>
          <Input
            name={"email"}
            label={"Email Address"}
            placeholder={"johndoe@example.com"}
            mandatory
            validation={isNotBlank}
            message={"Required"}
            background
          />
        </div>
        <Button type={"submit"} icon={faPaperPlane} theme={"blue"} className={styles["button"]}>
          Send Invitiation
        </Button>
      </Form>
    </Modal>
  );
};

export default InviteTeam;
