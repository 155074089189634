import styles from "./Checkbox.module.css";
import P from "../text/P";
import useValidation from "src/hooks/useValidation";
import useInlineStyles from "src/hooks/useInlineStyles";
import useToast from "src/hooks/useToast";
import clsx from "clsx";

const Checkbox = ({
  name,
  label,
  validation = () => true,
  message,
  mandatory = false,
  disabled,
  onChange: onChangeProp = () => {},
  value: overideValue,
  setValue: setOverideValue = () => {},
  className,
  ...props
}) => {
  const { error, setTouched, data, onChange } = useValidation({ name, validation, mandatory, onChangeProp });
  const style = useInlineStyles({ ...props });
  const toast = useToast();
  return (
    <div
      className={clsx(styles["container"], disabled && styles["disabled"], className)}
      style={style}
      onClick={() => disabled && toast.error("This field has been locked with read-only rights.")}
    >
      <input
        disabled={disabled}
        id={name}
        name={name}
        type="checkbox"
        className={styles["checkbox"]}
        defaultChecked={overideValue || data[name]}
        onBlur={() => setTouched(true)}
        onChange={(e) => {
          onChange(e.target.checked);
          setOverideValue(e.target.checked);
        }}
      ></input>
      <label htmlFor={name} className={styles["label"]}>
        {label}
      </label>
      {error && <P className={styles["message"]}>{message}</P>}
    </div>
  );
};

export default Checkbox;
