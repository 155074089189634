import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customFetch } from "src/utilities/http";
import { organisationActions } from "../store/organisationSlice";

const useOrganisation = () => {
  const dispatch = useDispatch();
  const orgState = useSelector((state) => state.organisation);
  const authState = useSelector((state) => state.auth);
  const [availableOrganisations, setAvailableOrganisations] = useState([]);
  const [loading, setLoading] = useState();
  const [allOrganisations, setAllOrganisations] = useState([]);

  //Fetches all organisations the user has connected, regardless of provider
  const fetchOrganisations = useCallback(async () => {
    setLoading(true);
    if (!authState.user?.homeOrganisation || !orgState?.homeOrganisation?._id) {
      setLoading(false);
      return;
    }
    const { data: organisations = [] } = await customFetch({
      entity: "User.Organisation",
      method: "GET_AVAILABLE_ORGANISATIONS",
      data: {
        homeOrganisation: authState.user?.homeOrganisation || orgState?.homeOrganisation?._id,
      },
    });

    const { data: allOrgs = [] } = await customFetch({
      entity: "User.Organisation",
      method: "GET",
    });

    setAllOrganisations(allOrgs);
    setAvailableOrganisations(organisations);
    setLoading(false);
  }, [authState.user?.homeOrganisation, orgState?.homeOrganisation?._id]);

  const fetchHomeOrganisation = useCallback(async () => {
    setLoading(true);
    if (!authState.user?.homeOrganisation || orgState.homeOrganisation) return;
    const {
      data: [homeOrg],
    } = await customFetch({
      entity: "User.HomeOrganisation",
      criteria: {
        _id: authState.user?.homeOrganisation,
      },
    });
    dispatch(organisationActions.setHomeOrganisation(homeOrg));
    setLoading(false);
  }, [authState.user?.homeOrganisation, dispatch, orgState.homeOrganisation]);

  const setHomeOrganisation = useCallback(
    (homeOrg) => {
      dispatch(organisationActions.setHomeOrganisation(homeOrg));
    },
    [dispatch]
  );

  useEffect(() => {
    authState.user && fetchHomeOrganisation();
  }, [authState.user, fetchOrganisations, fetchHomeOrganisation]);

  useEffect(() => {
    authState?.user?.homeOrganisation && fetchOrganisations();
  }, [authState, fetchOrganisations]);

  //   Updates the redux state with an organisation
  const setOrganisation = useCallback(
    (org) => {
      dispatch(organisationActions.setOrganisation(org));
    },
    [dispatch]
  );

  // Updates the redux provider based on the active organsiation
  const setProvider = useCallback(
    (provider) => {
      dispatch(organisationActions.setProvider(provider));
    },
    [dispatch]
  );

  useEffect(() => {
    orgState?.organisation && setProvider(orgState.organisation.provider);
  }, [orgState?.organisation, setProvider]);

  return {
    fetchOrganisations,
    setOrganisation,
    setHomeOrganisation,
    loading,
    availableOrganisations,
    setProvider,
    allOrganisations,
    ...orgState,
  };
};

export default useOrganisation;
