import styles from "../Application.module.css";
import Input from "src/components/Interface/inputs/Input";
import ToggleButton from "src/components/Interface/inputs/ToggleButton";
import Button from "src/components/Interface/buttons/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { isNotBlank, validateUSI } from "src/utilities/validation";
import Modal from "src/components/Interface/layout/Modal";
import clsx from "clsx";
import Form from "src/components/Interface/layout/Form";
import { useCallback, useState } from "react";
import { customFetch } from "src/utilities/http";
import useToast from "src/hooks/useToast";
import globalStyles from "src/Global.module.css";

const CreateSuperFund = ({ showModal, setShowModal, refresh }) => {
  const [superData, setSuperData] = useState({});
  const toast = useToast();

  const createNewSuper = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const organisationID = urlParams.get("organisationID");

    if (!superData.isValid) {
      toast.error("Please fill out all required fields.");
      return;
    }

    await customFetch({
      node: "xero-node",
      method: "CREATE_SUPERFUND",
      data: { superFund: superData.data, organisationID: organisationID },
    });

    refresh();
    toast.success("Super fund created successfully.");
    setShowModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, superData.data, superData.isValid]);

  return (
    <Modal label={"Create new fund"} showModal={showModal} setShowModal={setShowModal}>
      <Form setFormData={setSuperData} formData={superData}>
        <ToggleButton
          mt={"20px"}
          mb="80px"
          name={"type"}
          buttons={[
            { label: "Regulated Fund", value: "REGULATED" },
            { label: "Self Manged Super Fund", value: "SMSF" },
          ]}
        />
        {superData.data?.type && (
          <div className={styles["form-flex-container"]}>
            <div className={clsx(globalStyles["form-input-container"])}>
              <Input
                type="text"
                name={"aBN"}
                label={"ABN Number"}
                validation={isNotBlank}
                placeholder={"68 657 495 890"}
                message={"Please enter a valid ABN number"}
                background
              />
            </div>
            {superData?.data?.type === "REGULATED" && (
              <div className={clsx(globalStyles["form-input-container"])}>
                <Input
                  type="text"
                  name={"uSI"}
                  label={"USI Number"}
                  validation={superData?.data?.type === "REGULATED" ? validateUSI : () => true}
                  placeholder={"RES0103AU"}
                  message={"Please enter a valid USI."}
                  background
                />
              </div>
            )}
            {superData?.data?.type === "SMSF" && (
              <div className={clsx(globalStyles["form-input-container"])}>
                <Input
                  type="text"
                  name={"name"}
                  label={"Fund Name"}
                  validation={superData?.data?.type === "SMSF" ? isNotBlank : () => true}
                  placeholder={"New Super Fund"}
                  message={"Please enter the super fund name."}
                  background
                />
              </div>
            )}
          </div>
        )}

        {superData?.data?.type === "SMSF" && (
          <div className={globalStyles["form-flex-container"]}>
            <div className={clsx(globalStyles["form-input-container"])}>
              <Input
                type="text"
                name={"accountName"}
                label={"Account Name"}
                validation={superData?.data?.type === "SMSF" ? isNotBlank : () => true}
                placeholder={"New Super Fund"}
                message={"Please enter the super fund account name."}
                background
              />
            </div>

            <div className={clsx(globalStyles["form-input-container"])}>
              <Input
                type="text"
                name={"electronicServiceAddress"}
                label={"Electronic Service Address"}
                validation={superData?.data?.type === "SMSF" ? isNotBlank : () => true}
                message={"Please enter your elctronic service address (Max 16 characters)"}
                background
              />
            </div>
          </div>
        )}

        {superData?.data?.type === "SMSF" && (
          <div className={globalStyles["form-flex-container"]}>
            <div className={clsx(globalStyles["form-input-container"])}>
              <Input
                type="text"
                name={"bSB"}
                label={"BSB"}
                validation={superData?.data?.type === "SMSF" ? isNotBlank : () => true}
                placeholder={"000000"}
                message={"Please enter the super fund account BSB."}
                background
              />
            </div>

            <div className={clsx(globalStyles["form-input-container"])}>
              <Input
                type="text"
                name={"accountNumber"}
                label={"Account Number"}
                validation={superData?.data?.type === "SMSF" ? isNotBlank : () => true}
                placeholder={"00000000"}
                message={"Please enter the super fund account number."}
                background
              />
            </div>
          </div>
        )}
        <div className={styles["super-btn-container-modal"]}>
          <Button theme={"blue-dark"} size={"large"} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button theme={"blue"} type={"button"} size={"large"} icon={faPlus} onClick={createNewSuper}>
            Create
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateSuperFund;
