import { useCallback, useEffect, useState } from "react";
import { useForm } from "src/contexts/Form";

const useValidation = ({ name = "", validation = () => true, mandatory = false, onChangeProp = () => {} }) => {
  const [error, setError] = useState(false);
  const { data, setData, setValidateFields = () => {} } = useForm();
  const [value, setValue] = useState(data?.[name]);
  const [touched, setTouched] = useState(Boolean(data?.[name]));

  //Effect that determines whether an input field requires validation
  useEffect(() => {
    if (value) {
      setValidateFields((prev) => ({ ...prev, [name]: validation(value) }));
    } else {
      setValidateFields((prev) => ({ ...prev, [name]: !mandatory }));
    }

    return () =>
      setValidateFields((prev) => {
        const { [name]: _, ...rest } = prev;
        return rest;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setValue(data?.[name]);
    setTouched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.[name], setValue, name]);

  //Validate the entered value of the input field against a provided function
  useEffect(() => {
    setError(false);
    if (touched) {
      setValidateFields((prev) => ({ ...prev, [name]: validation(data[name]) }));
      let timeout = setTimeout(() => {
        if (!validation(data[name])) {
          setError(true);
        }
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[name], touched]);

  //Updates relevants states on input change
  const onChange = useCallback(
    (val) => {
      setTouched(true);
      setError(false);
      setData((prev) => ({ ...prev, [name]: val }));
      onChangeProp(val);
      setValue(val);
    },
    [name, setData, setTouched, onChangeProp]
  );
  return { onChange, error, setError, touched, setTouched, data, setData, value, setValue };
};

export default useValidation;
