const { customFetch } = require("./http");

const checkUserHasPaymentSetup = async () => {
  const { data = [] } = await customFetch({ entity: "Payment.PaymentMethod" });

  return { hasPaymentMethod: data.length > 0, paymentMethods: data };
};

function getCreditCardType(cardNumber) {
  // Remove any non-digit characters
  const cleanedNumber = cardNumber.replace(/\D/g, "");

  // Define regular expressions for known card types
  const cardPatterns = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  };

  // Check the card type based on the card number pattern
  for (const cardType in cardPatterns) {
    if (cardPatterns[cardType].test(cleanedNumber)) {
      return cardType;
    }
  }

  // If no known type matches, return "Unknown"
  return "Unknown";
}

export { checkUserHasPaymentSetup, getCreditCardType };
