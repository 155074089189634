import H1 from "src/components/Interface/headings/H1";
import styles from "../Portal.module.css";
import logo from "src/assets/global/eline-logo.svg";
import H3 from "src/components/Interface/headings/H3";
import Form from "src/components/Interface/layout/Form";
import { useEffect, useState } from "react";
import Providers from "./Providers";
import Input from "src/components/Interface/inputs/Input";
import { validateEmail, isNotBlank } from "src/utilities/validation";
import Button from "src/components/Interface/buttons/Button";
import Select from "src/components/Interface/inputs/Select";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import useAuth from "src/hooks/useAuth";
import useToast from "src/hooks/useToast";
import useWindowSize from "src/hooks/useWindowSize";
import P from "src/components/Interface/text/P";
import { validatePassword } from "src/utilities/validation";
import globalStyles from "src/Global.module.css";
import useNav from "src/hooks/useNav";
import { customFetch } from "src/utilities/http";
const Signup = ({ setTab }) => {
  const [data, setData] = useState({});
  const { signup } = useAuth();
  const toast = useToast();
  const navigate = useNav();
  const { screen } = useWindowSize();
  const [homeOrg, setHomeOrg] = useState(null);

  useEffect(() => {
    (async () => {
      const homeOrganisationID = localStorage.getItem("homeOrganisation");

      if (homeOrganisationID) {
        const { data } = await customFetch({
          entity: "Public.Authentication",
          method: "GET_HOME_ORGANISATION",
          data: { homeOrganisation: homeOrganisationID },
        });

        setHomeOrg(data?.[0]);
      }
    })();
  }, []);

  const onSubmitHandler = async () => {
    if (data.isValid) {
      const user = await signup({
        firstName: data?.data?.firstName,
        lastName: data?.data?.lastName,
        email: data?.data?.email,
        password: data?.data?.password,
      });

      if (!user) {
        toast.error("Failed to sign up. Please try again.");
        return;
      }

      if (homeOrg) {
        await customFetch({
          entity: "Public.Authentication",
          method: "LINK_HOME_ORGANISATION",
          data: { homeOrganisation: homeOrg, user },
        });
      } else {
        await customFetch({
          entity: "Public.Authentication",
          method: "CREATE_HOME_ORGANISATION",
          data: { user },
        });
      }

      toast.success(`Welcome ${user?.personal?.firstName}!`);
      navigate("/hq");
    } else {
      toast.error("Please complete the signup form.");
    }
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["signup-form-container"]}>
        <img src={logo} alt="logo graphic" className={styles["logo"]} />
        <H1 mt={screen === "mobile" ? "30px" : " 40px"}>Welcome to eline!</H1>
        <H3 mt={"20px"} mb={"40px"}>
          Employee onboarding made easy
        </H3>
        <Providers />
        <Form onSubmit={onSubmitHandler} setFormData={setData} className={styles["signup-form"]}>
          <div className={globalStyles["form-flex-container"]}>
            <div className={globalStyles["form-input-container"]}>
              <Select options={["Mr", "Ms", "Mrs", "Dr"]} label={"Title"} placeholder={"select"} />
            </div>
            <div className={globalStyles["form-input-container"]}>
              <Input
                type="text"
                name={"firstName"}
                label={"First Name"}
                validation={isNotBlank}
                placeholder={"John"}
                message={"Please enter your first name."}
              />
            </div>
          </div>
          <div className={globalStyles["form-input-container"]}>
            <Input
              type="text"
              name={"lastName"}
              label={"Last Name"}
              validation={isNotBlank}
              placeholder={"Doe"}
              message={"Please enter your last name."}
            />
          </div>
          <div className={globalStyles["form-flex-container"]}>
            <div className={globalStyles["form-input-container"]}>
              <Input
                type="text"
                name={"email"}
                label={"Email"}
                validation={validateEmail}
                placeholder={"johndoe@example.com"}
              />
            </div>

            <div className={globalStyles["form-input-container"]}>
              <Input type="text" name={"phone"} label={"Phone"} placeholder={"0400000000"} />
            </div>
          </div>

          <div className={globalStyles["form-flex-container"]}>
            <div className={globalStyles["form-input-container"]}>
              <Input
                type="password"
                name={"password"}
                label={"Password"}
                validation={validatePassword}
                message={"Please enter a stronger password."}
                placeholder={"********"}
              />
            </div>
            <div className={globalStyles["form-input-container"]}>
              <Input
                type="password"
                name={"confirmPassword"}
                label={"Confirm Password"}
                placeholder={"********"}
                validation={(value) => value === data?.data?.password}
                message={"Passwords do not match."}
              />
            </div>
          </div>
          <div className={styles["signup-button-container"]}>
            <div className={styles["login-switch"]}>
              <P>
                Already have an account?{" "}
                <span
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setTab("login");
                  }}
                >
                  Log in here!
                </span>
              </P>
            </div>
            <Button type="submit" theme={"blue"} size={screen !== "mobile" ? "large" : "smal"} icon={faChevronRight}>
              Sign Up
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Signup;
