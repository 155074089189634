import clsx from "clsx";
import styles from "./LoadingOverlay.module.css";
import { useEffect, useState } from "react";
import LoadingSpinner from "./LoadingSpinner";

const LoadingOverlay = ({ loading }) => {
  const [openUp, setOpenUp] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);

  useEffect(() => {
    if (!loading) {
      setHideLoader(true);
      setTimeout(() => {
        setOpenUp(true);
      }, 1000);
    } else {
      setOpenUp(false);
    }
  }, [loading]);

  return (
    <div className={clsx(styles["container"], loading && styles["visible"])}>
      <div className={clsx(styles["loader-container"], hideLoader && styles["hideLoader"])}>
        <LoadingSpinner loader={loading} />
      </div>
      <div className={clsx(styles["left"], openUp && styles["openUp"])}></div>
      <div className={clsx(styles["right"], openUp && styles["openUp"])}></div>
    </div>
  );
};

export default LoadingOverlay;
