import { forwardRef } from "react";
import styles from "./StripeWrapper.module.css";
import useInlineStyles from "src/hooks/useInlineStyles";
import clsx from "clsx";
import useToast from "src/hooks/useToast";

const StripeWrapper = forwardRef(function StripeWrapper(
  {
    type,
    name,
    label,
    placeholder,
    background,
    mandatory = false,
    onChange: onChangeProp = () => {},
    validation = () => true,
    message,
    disabled,
    link = {},
    value: overideValue,
    setValue: setOverideValue = () => {},
    inActive = false,
    className,
    boxShadow,
    children,
    ...props
  },
  ref
) {
  const style = useInlineStyles({ ...props });
  const toast = useToast();

  return (
    <div
      className={clsx(
        styles["input-container"],
        disabled && styles["disabled"],
        boxShadow && styles["box-shadow-grey"],
        background && styles["background"],
        className
      )}
      style={style}
      onClick={() => disabled && toast.error("This field has been locked with read-only rights.")}
    >
      <label className={styles["label"]}>{label}</label>
      {children}
    </div>
  );
});

export default StripeWrapper;
