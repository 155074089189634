import clsx from "clsx";
import styles from "./H3.module.css";
const H1 = ({ className, children, textAlign, mx, my, px, py, mt, mb, ml, mr, pt, pb, pl, pr, ...props }) => {
  return (
    <h1
      style={{
        marginTop: mt ? mt : my,
        marginBottom: mb ? mb : my,
        marginLeft: ml ? ml : mx,
        marginRight: mr ? mr : mx,
        paddingTop: pt ? pt : py,
        paddingBottom: pb ? pb : py,
        paddingLeft: pl ? pl : px,
        paddingRight: pr ? pr : px,
        textAlign: textAlign,
      }}
      className={clsx(styles["tag"], className)}
      {...props}
    >
      {children}
    </h1>
  );
};

export default H1;
