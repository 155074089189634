import clsx from "clsx";
import styles from "./LoadingOverlay.module.css";

const LoadingSpinner = ({ colour, style, loader }) => {
  const mountedStyle = { animation: "inAnimation 500ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 500ms ease-out",
    animationFillMode: "forwards",
  };
  return (
    <span
      style={loader ? mountedStyle : unmountedStyle}
      className={clsx(styles["loader"], colour && styles[colour])}
    ></span>
  );
};
export default LoadingSpinner;
