import Button from "src/components/Interface/buttons/Button";
import styles from "../Dashboard.module.css";
import H1 from "src/components/Interface/headings/H1";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { customFetch } from "src/utilities/http";
import ApplicationCard from "./subcomponents/ApplicationCard";
import PaginationBar from "./subcomponents/PaginationBar";
import LoadingSpinner from "src/components/layout/LoadingSpinner";
import StatusIcon from "src/components/misc/StatusIcon";
import useNav from "src/hooks/useNav";
import useDelayUnmount from "src/hooks/useDelayUnmount";
import useWindowSize from "src/hooks/useWindowSize";
import useOrganisation from "src/hooks/useOrganisation";

const Progress = () => {
  const navigate = useNav();
  const [applications, setApplications] = useState([]);
  const [visibleApplications, setVisibleApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const showLoader = useDelayUnmount(loading);
  const { screen } = useWindowSize();
  const { organisation } = useOrganisation();

  let divisor;
  switch (screen) {
    case "desktop":
      divisor = 12;
      break;
    case "tablet":
      divisor = 9;
      break;
    case "mobile":
      divisor = 2;
      break;
    default:
      divisor = 12;
      break;
  }
  const getApplications = useCallback(async () => {
    setLoading(true);
    const { data = [] } = await customFetch({
      entity: "Application.Application",
      method: "GET",
      criteria: { organisationID: organisation?.organisationID },
    });
    setApplications(data.filter((application) => application.status !== "success"));
    setLoading(false);
  }, [organisation]);

  useEffect(() => {
    getApplications();
  }, [getApplications]);
  return (
    <div className={styles["progress-container"]}>
      <div className={styles["progress-heading"]}>
        <div className={styles["progress-legend"]}>
          <H1>In Progress</H1>
          <div className={styles["progress-status-container"]}>
            <StatusIcon status={"draft"} />
            <StatusIcon status={"pending"} />
            <StatusIcon status={"failed"} />
          </div>
        </div>

        <Button
          size={"large"}
          icon={faChevronRight}
          theme={"blue-light"}
          onClick={() => navigate("/all-applications")}
          className={styles["progress-view-applications"]}
        >
          View All Applications
        </Button>
      </div>
      {showLoader ? (
        <div className={styles["loading-container"]}>
          <LoadingSpinner colour={"blue"} loader={loading} />
        </div>
      ) : (
        <>
          {applications.length > 0 ? (
            <div className={styles["progress-grid"]}>
              {visibleApplications?.map((application) => (
                <ApplicationCard key={application.key} application={application} refresh={getApplications} />
              ))}
            </div>
          ) : (
            <div className={styles["no-applications"]}>
              <p>
                You don't have any active applications! <br />
                Create one to track its progress here.
              </p>
            </div>
          )}
          {applications.length > 0 && (
            <PaginationBar
              applications={applications}
              visibleApplications={visibleApplications}
              setVisibleApplications={setVisibleApplications}
              divisor={divisor}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Progress;
