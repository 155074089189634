import useWindowSize from "src/hooks/useWindowSize";
import ApplicationHeader from "../application/subcomponents/ApplicationHeader";
import ApplicationNav from "../application/subcomponents/ApplicationNav";
import styles from "./ThankYou.module.css";
import Dancing from "src/assets/svg/dancing.svg";


const ThankYou = () => {
  const { screen } = useWindowSize();
  return (
    <>
      <div className={styles["container"]}>
        {screen !== "mobile" && <ApplicationNav tab={4} setTab={() => {}} />}
        <ApplicationHeader heading={"Thanks for onboarding!"} subline={"We'll get back to you shortly."} />
        <img src={Dancing} alt="dancing pic" className={styles["dancing"]} />
      </div>
    </>
  );
};

export default ThankYou;
