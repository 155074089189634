import React, { useState, createContext, useRef } from "react";
import Modal from "src/components/Interface/layout/Modal";
import Button from "src/components/Interface/buttons/Button";
import styles from "./Alerts.module.css";
import Input from "src/components/Interface/inputs/Input";
import Overlay from "src/components/Interface/layout/Overlay";

export const AlertsContext = createContext();

export const AlertsContextProvider = ({ children }) => {
  const awaitingPromiseRef = useRef(null);
  const [options, setOptions] = useState(null);
  const promptInputRef = useRef(null);

  const openModal = (options) => {
    setOptions(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOptions(null);
  };

  const handleConfirm = (value) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(value);
    }
    setOptions(null);
  };

  const confirm = async ({
    label,
    text,
    cancelText = "Cancel",
    confirmText = "Submit",
    inverse = false,
    withOverlay = true,
  }) => {
    return await openModal({
      label,
      content: (
        <>
          {withOverlay && <Overlay open={Boolean(options)} onClick={() => {}} />}
          <p className={styles["confirm-text"]}>{text}</p>
          <div className={styles["modal-btns-container"]}>
            <Button theme={!inverse ? "blue" : "red"} onClick={handleConfirm}>
              {confirmText}
            </Button>
            <Button theme={inverse ? "blue" : "red"} onClick={handleClose}>
              {cancelText}
            </Button>
          </div>
        </>
      ),
    });
  };

  const alert = async ({ label, text, confirmText = "Okay" }) => {
    return await openModal({
      label,
      content: (
        <>
          <Overlay open={Boolean(options)} onClick={() => {}} />
          <p className={styles["confirm-text"]}>{text}</p>
          <div className={styles["modal-btns-container"]}>
            <Button theme={"red"} onClick={handleConfirm}>
              {confirmText}
            </Button>
          </div>
        </>
      ),
    });
  };

  const prompt = async ({ label, text, cancelText = "Cancel", confirmText = "Submit", placeholder, type = "text" }) => {
    return await openModal({
      label,
      content: (
        <>
          <Overlay open={Boolean(options)} onClick={() => {}} />
          <p className={styles["confirm-text"]}>{text}</p>
          <Input type={type} mb={"40px"} placeholder={placeholder} ref={promptInputRef} background />
          <div className={styles["modal-btns-container"]}>
            <Button
              theme={"blue"}
              onClick={() => {
                handleConfirm(promptInputRef.current.value);
              }}
            >
              {confirmText}
            </Button>
            <Button theme={"red"} onClick={handleClose}>
              {cancelText}
            </Button>
          </div>
        </>
      ),
    });
  };

  return (
    <>
      <AlertsContext.Provider
        value={{ openModal, handleConfirm, handleClose, confirm, prompt, alert }}
        children={children}
      />

      <Modal
        showModal={Boolean(options)}
        label={options?.label}
        setShowModal={handleClose}
        className={styles["modal-class"]}
      >
        {options?.content}
      </Modal>
    </>
  );
};
