import Main from "src/components/layout/Main";
import styles from "./Plans.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import useNav from "src/hooks/useNav";
import PricingPlan from "./components/PricingPlan";
import { useEffect, useState } from "react";
import useOrganisation from "src/hooks/useOrganisation";

const Plans = () => {
  const navigate = useNav();
  const [activePlan, setActivePlan] = useState(0);
  const { homeOrganisation } = useOrganisation();

  useEffect(() => {
    setActivePlan(homeOrganisation?.subscriptionTier || 0)
  }, [homeOrganisation]);

  return (
    <Main>
      <div className={styles["container"]}>
        <div className={styles["dash-header"]}>
          <h1>Pricing Plans</h1>
          <div className={styles["return"]} onClick={() => navigate("/hq")}>
            <div className={styles["return-icon"]}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <p>Back to HQ</p>
          </div>
        </div>
        <div className={styles["plans-container"]}>
          <PricingPlan
            name={'Essential'}
            price={"FREE"}
            numberOfOrganisations={1}
            description={"Perfect for the solo flight into the digital onboarding universe, our Essential Plan offers you a free launch pad. It's designed for 1 organisation with a mere $7 per application fee, making it ideal for startups or small teams."}
            pricePerApplication={7}
            activePlan={activePlan}
            index={1}
            setActivePlan={setActivePlan}
          />
          <PricingPlan
            name={'Premium Plan"'}
            price={"A$15"}
            numberOfOrganisations={5}
            description={"As your fleet expands, so do your needs. The Starter Plan is your co-pilot in onboarding efficiency, for just A$15. Manage up to 5 organisations in your expanding galaxy and enjoy a reduced rate of $6.50 per application. "}
            pricePerApplication={6.5}
            activePlan={activePlan}
            index={2}
            setActivePlan={setActivePlan}
          />
          <PricingPlan
            name={'Enterprise Plan'}
            price={"A$25"}
            numberOfOrganisations={10}
            description={"Orbiting at the sweet spot of our pricing plans, the Premium offers a balanced gravitational pull of features and value. For A$25, coordinate up to 10 organisations with a $6 fee per application, ensuring your onboarding process is as smooth as an interstellar glide."}
            pricePerApplication={6}
            activePlan={activePlan}
            index={3}
            setActivePlan={setActivePlan}
          />
          <PricingPlan
            name={"Enterprise"}
            price={"A$50"}
            numberOfOrganisations={20}
            description={"Command a fleet of up to 20 organisations with the prowess of a seasoned captain for A$50. At $5.5 per application, the Enterprise Plan is your flagship offering, equipped with all the necessary tools to conquer the administrative galaxies."}
            pricePerApplication={5.5}
            activePlan={activePlan}
            index={4}
            setActivePlan={setActivePlan}
          />
        </div>
      </div>
      Ï
    </Main>
  );
};

export default Plans;
