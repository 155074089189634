export const newGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const buildApplicationCollaboratorLink = ({ organisation, organisationID, homeOrganisation }) => {
  const link = `${process.env.REACT_APP_FRONTEND_URL}/application/new?organisation=${organisation}&organisationID=${organisationID}&user=${homeOrganisation}`;
  return link;
};

export const buildEmployeeOnboardingLink = ({ organisation, organisationID, homeOrganisation, key }) => {
  const link = `${process.env.REACT_APP_FRONTEND_URL}/onboarding/${key}?organisation=${organisation}&organisationID=${organisationID}&homeOrganisation=${homeOrganisation}`;
  return link;
};

export const buildHomeOrgCollaboratorLink = ({ homeOrganisation }) => {
  const link = `${process.env.REACT_APP_FRONTEND_URL}/portal?homeOrganisation=${homeOrganisation}&page=signup`;
  return link;
};
