import Main from "src/components/layout/Main";
import styles from "./EmployerApplication.module.css";
import EmpAppForm from "./subcomponents/EmpAppForm";
import EmployeeFields from "./subcomponents/EmployeeFields";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { customFetch } from "src/utilities/http";
import useDelayUnmount from "src/hooks/useDelayUnmount";
import LoadingSpinner from "src/components/layout/LoadingSpinner";

const EmployerApplication = () => {
  const { key } = useParams();
  const [application, setApplication] = useState({});
  const [loading, setLoading] = useState(true);

  const showLoader = useDelayUnmount(loading);

  const fetchApplication = useCallback(async () => {
    setLoading(true);
    const { data: [application] = [] } = await customFetch({
      entity: "Application.Application",
      method: "GET",
      criteria: { key },
    });
    setApplication(application);
    setLoading(false);
  }, [key]);

  useEffect(() => {
    if (key !== "new") {
      fetchApplication();
    } else {
      setLoading(false);
      setApplication({});
    }
  }, [key, fetchApplication]);

  return (
    <Main>
      <div className={styles["container"]}>
        {showLoader ? (
          <div className={styles["loading-container"]}>
            <LoadingSpinner loader={loading} colour={"blue"} />
          </div>
        ) : (
          <>
            <EmpAppForm application={application} />
            <EmployeeFields application={application} />
          </>
        )}
      </div>
    </Main>
  );
};
export default EmployerApplication;
