import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ResetEmail from "./components/ResetEmail";
import ResetPassword from "./components/ResetPassword";
const Portal = () => {
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get("page") || "login");

  useEffect(() => {
    const homeOrganisation = searchParams.get("homeOrganisation");
    if (homeOrganisation) {
      localStorage.setItem("homeOrganisation", homeOrganisation);
    }
  }, [searchParams]);

  return (
    <>
      {tab === "login" && <Login setTab={setTab}></Login>}
      {tab === "signup" && <Signup setTab={setTab}></Signup>}
      {tab === "reset-email" && <ResetEmail setTab={setTab} />}
      {tab === "reset" && <ResetPassword setTab={setTab} />}
    </>
  );
};

export default Portal;
