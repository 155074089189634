import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "src/store/uiSlice";

const useUI = () => {
  const UI = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  return {
    applicationModal: UI.applicationModal,
    loading: UI.loading,
    setApplicationModal: (params) => dispatch(uiActions.setApplicationModal(params)),
    setLoading: (params) => dispatch(uiActions.setLoading(params)),
  };
};

export default useUI;
