import { useCallback, useState } from "react";
import styles from "./OrganisationTile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faCircleArrowRight, faTrash } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { Switch } from "@mui/material";
import { customFetch } from "src/utilities/http";
import useAuth from "src/hooks/useAuth";
import useOrganisation from "src/hooks/useOrganisation";
import useAlerts from "src/hooks/useAlerts";

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

const OrganisationTile = ({ setOrganisation = () => {}, applications = [], organisation = {} }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const {
    auth: { user },
  } = useAuth();
  const { homeOrganisation } = useOrganisation();

  const [org, setOrg] = useState(organisation);
  const { confirm } = useAlerts();

  const handleSubMenuClick = useCallback((e) => {
    e.stopPropagation();
    setShowSubMenu(true);
  }, []);

  const handleMarkSensitive = useCallback(
    async (e, value) => {
      await customFetch({
        entity: "User.Organisation",
        method: "UPDATE",
        criteria: { _id: organisation._id },
        data: { isSensitive: value },
      });
      setOrg({ ...organisation, isSensitive: value });
    },

    [organisation]
  );

  const handleDisconnect = useCallback(async () => {
    try {
      await confirm({
        label: "Disconnect Organisation",
        text: "Are you sure you want to disconnect this organisation? Any pending applications will be cancelled.",
        inverse: true,
        confirmText: "Disconnect",
      });

      const res = await customFetch({
        entity: "User.Organisation",
        method: "DISCONNECT_FROM_XERO",
        data: { organisationID: org.organisationID },
      });

      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {}
  }, [org, confirm]);

  return (
    <div className={styles["container"]} onClick={() => !showSubMenu && setOrganisation(org)}>
      <div className={styles["name"]}>
        <p>{truncate(organisation?.name, 20)}</p>
      </div>
      <div className={styles["count"]}>
        <p>
          <span>{applications?.length}</span> applications
        </p>
      </div>
      <div className={styles["success"]}>
        <div className={styles["icon"]}></div>
        <p> {applications.filter((application) => application.status === "success").length} successful</p>
      </div>
      <div className={styles["pending"]}>
        <div className={styles["icon"]}></div>
        <p> {applications.filter((application) => application.status === "pending").length} pending</p>
      </div>
      <div className={styles["failed"]}>
        <div className={styles["icon"]}></div>
        <p> {applications.filter((application) => application.status === "failed").length} failed</p>
      </div>
      <div className={styles["more-menu"]}>
        <FontAwesomeIcon icon={faEllipsis} onClick={handleSubMenuClick} />
        <div
          className={clsx(styles["overlay"], showSubMenu && styles["overlay-visible"])}
          onClick={(e) => {
            e.stopPropagation();
            setShowSubMenu(false);
          }}
        ></div>
        <div className={clsx(styles["submenu"], showSubMenu && styles["visible"])}>
          {user?.contact?.email === homeOrganisation?.admin_email && (
            <div className={styles["sensitive"]}>
              <FontAwesomeIcon icon={faEyeSlash} />
              <p>Mark Organisation as Sensitive</p>
              <Switch onChange={handleMarkSensitive} defaultChecked={organisation?.isSensitive} />
            </div>
          )}
          <div className={styles["disconnect"]} onClick={handleDisconnect}>
            <FontAwesomeIcon icon={faTrash} />
            <p>Disconnect Organisation</p>
          </div>
        </div>
      </div>
      <div className={styles["select-arrow"]}>
        <FontAwesomeIcon icon={faCircleArrowRight} />
      </div>
    </div>
  );
};

export default OrganisationTile;
