import { forwardRef, useEffect, useState } from "react";
import styles from "./SearchSelect.module.css";
import useValidation from "src/hooks/useValidation";
import useInlineStyles from "src/hooks/useInlineStyles";
import useToast from "src/hooks/useToast";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import P from "../text/P";

const SearchSelect = forwardRef(
  (
    {
      options = [],
      name,
      label,
      placeholder,
      background,
      mandatory = false,
      onChange: onChangeProp = () => {},
      validation = () => true,
      message,
      disabled,
      link = {},
      value: overideValue,
      setValue: setOverideValue = () => {},
      inActive = false,
      className,
      boxShadow,
      noHover,
      loading = false,
      ...props
    },
    ref
  ) => {
    const { onChange, error, setTouched, value } = useValidation({ name, validation, mandatory, onChangeProp });
    const style = useInlineStyles({ ...props });
    const toast = useToast();
    const [open, setOpen] = useState(false);
    const [term, setTerm] = useState();
    const [filteredOptions, setFilteredOptions] = useState(options);

    const [selectedOption, setSelectedOption] = useState(
      options.find((op) => {
        return (
          (op.value && op.value === overideValue) ||
          op === overideValue ||
          (!overideValue && op.value === value) ||
          (!overideValue && op === value)
        );
      })
    );

    useEffect(() => {
      setSelectedOption(
        options.find((op) => {
          return (
            (op.value && op.value === overideValue) ||
            op === overideValue ||
            (!overideValue && op.value === value) ||
            (!overideValue && op === value)
          );
        })
      );
    }, [options, overideValue, value]);

    useEffect(() => {
      if (term) {
        setFilteredOptions(options.filter((op) => op.label.toLowerCase().includes(term.toLowerCase())));
      } else {
        setFilteredOptions(options);
      }
    }, [term, options]);

    return (
      <div
        className={clsx(
          styles["container"],
          disabled && styles["disabled"],
          boxShadow && styles["box-shadow-grey"],
          !noHover && styles["hover"],
          className
        )}
        style={style}
        onBlur={() => !disabled && setTouched(true)}
        onClick={() => disabled && toast.error("This field has been locked with read-only rights.")}
      >
        <div
          className={clsx(styles["wrapper"], error && styles["error"])}
          onClick={() => !disabled && !inActive && setOpen((prev) => !prev)}
        >
          <label className={styles["label"]}>{label}</label>
          <input
            type="text"
            className={styles["search-input"]}
            placeholder={loading ? "Loading..." : "Search"}
            value={selectedOption?.label || selectedOption || term || ""}
            onChange={(e) => {
              setTerm(e.target.value);
              setSelectedOption();
            }}
          ></input>
          {!disabled && <FontAwesomeIcon icon={faCaretDown} className={clsx(styles["icon"])} />}
        </div>
        {error && <P className={styles["message"]}>{message}</P>}
        {open && <div className={styles["overlay"]} onClick={() => setOpen(false)}></div>}
        <div className={clsx(styles["content"], open && styles["open"])}>
          {filteredOptions.map((option, index) => {
            return (
              <div
                key={index}
                className={styles["option"]}
                onClick={() => {
                  onChange(option.value || option);
                  setOpen(false);
                  setOverideValue(option.value || option);
                  setTerm();
                }}
              >
                {option?.label || (typeof option === "string" ? option : option.value)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default SearchSelect;
