import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import useWindowSize from "./useWindowSize";

const useToast = () => {
  const { screen } = useWindowSize();
  const error = (message) => {
    Toastify({
      text: message,
      duration: 5000,
      close: true,
      gravity: screen !== "desktop" ? "bottom" : "top",
      position: screen !== "desktop" ? "center" : "right",
      style: {
        background: "var(--error-light)",
        color: "var(--error-dark)",
        borderRadius: "15px",
        fontSize: "20px",
        padding: "18px 40px 20px 40px",
        boxShadow: "var(--box-shadow)",
        textAlign: "center",
        width: "100vw",
      },
      offset: {
        y: screen !== "desktop" ? 20 : 0,
      },
    }).showToast();
  };

  const success = (message) => {
    Toastify({
      text: message,
      duration: 5000,
      close: true,
      gravity: screen !== "desktop" ? "bottom" : "top",
      position: screen !== "desktop" ? "center" : "right",
      style: {
        background: "var(--success-light)",
        color: "var(--success-dark)",
        borderRadius: "15px",
        fontSize: "20px",
        padding: "18px 40px 20px 40px",
        boxShadow: "var(--box-shadow)",
        textAlign: "center",
      },
      offset: {
        y: screen !== "desktop" ? 20 : 0,
      },
    }).showToast();
  };

  const info = (message) => {
    Toastify({
      text: message,
      duration: 5000,
      close: true,
      gravity: screen !== "desktop" ? "bottom" : "top",
      position: screen !== "desktop" ? "center" : "right",
      style: {
        background: "var(--blue)",
        color: "white",
        borderRadius: "15px",
        fontSize: "20px",
        padding: "18px 90px 20px 40px",
        textAlign: "center",
      },
      offset: {
        y: screen !== "desktop" ? 20 : 0,
      },
    }).showToast();
  };
  return { error, success, info };
};

export default useToast;
