import clsx from "clsx";
import styles from "./Navigation.module.css";
import { useMemo, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronRight, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Overlay from "src/components/Interface/layout/Overlay";
import Button from "src/components/Interface/buttons/Button";
import useOrganisation from "src/hooks/useOrganisation";
import useAlerts from "src/hooks/useAlerts";
import useToast from "src/hooks/useToast";
import useNav from "src/hooks/useNav";
import { collaborationInvitation } from "src/utilities/Email/index";
import { buildHomeOrgCollaboratorLink } from "src/utilities/generate";

const navItems = [
  { label: "Dashboard", path: "/", activeKey: "" },
  { label: "All Applications", path: "/all-applications", activeKey: "all-applications" },
];

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

const MainNavigation = () => {
  const pathname = window.location.pathname.split("/")[1];
  const [active, setActive] = useState(pathname);
  const navigate = useNav();
  const { auth, logout } = useAuth();
  const { availableOrganisations, setOrganisation, organisation, homeOrganisation } = useOrganisation();
  const [switchOpen, setSwitchOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const { prompt } = useAlerts();
  const toast = useToast();

  const profileItems = useMemo(() => {
    return [
      { label: "Billing & Invoices", path: "/billing", activeKey: "billing" },
      { label: "Account Settings", path: "/settings", activeKey: "settings" },
      {
        label: "Logout",
        func: () => {
          logout();
        },
        activeKey: "settings",
      },
    ];
  }, [logout]);

  const actionItems = useMemo(() => {
    return [
      {
        label: "Send invite to join " + homeOrganisation?.name,
        func: async () => {
          handleOverlayClick();
          try {
            const email = await prompt({
              label: "Collaboration Invitation",
              text: "Enter the email address of the person you would like to invite to your E-line:",
              placeholder: "Email address",
            });

            const link = buildHomeOrgCollaboratorLink({
              homeOrganisation: homeOrganisation._id,
            });

            collaborationInvitation({
              homeOrgName: homeOrganisation.name,
              link,
              data: {
                to: email,
                subject: "Application Invitation",
              },
            });

            toast.success("Application invitation sent!");
          } catch (error) {}
        },
        icon: faChevronRight,
      },
    ];
    //eslint-disable-next-line
  }, [prompt, organisation, auth, homeOrganisation]);

  const handleNavItemClick = (item) => {
    setActive(item.activeKey);
    navigate(item.path);
  };

  const handleSwitchSelectorClick = (org, nav) => {
    setOrganisation(org);
    setSwitchOpen(false);
    setActionsOpen(false);
    nav && navigate(nav);
  };

  const handleOverlayClick = () => {
    setSwitchOpen(false);
    setProfileOpen(false);
    setActionsOpen(false);
  };

  return (
    <>
      {/* Navbar */}
      <div className={styles["container"]}>
        {organisation && (
          <>
            <div className={styles["navbar-container"]}>
              <div className={styles["switch-container"]}>
                <div
                  className={styles["switch-button"]}
                  onClick={() => {
                    handleOverlayClick();
                    setSwitchOpen((prev) => !prev);
                  }}
                >
                  <p>{truncate(organisation?.name, 20)}</p>
                  <div className={styles["switch-button-icon"]}>
                    <FontAwesomeIcon icon={switchOpen ? faChevronUp : faChevronDown} />
                  </div>
                </div>
              </div>
              <div className={styles["nav-bar"]}>
                {navItems.map((item) => (
                  <div key={item.label} className={styles["nav-item"]} onClick={() => handleNavItemClick(item)}>
                    <p className={clsx(active === item.activeKey && styles["active"])}>{item.label}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles["console-container"]}>
              <Button
                theme={"blue"}
                icon={faPlus}
                size={"medium"}
                onClick={() =>
                  navigate(
                    "/application/new?organisation=" +
                      organisation?.name +
                      "&organisationID=" +
                      organisation?.organisationID +
                      "&homeOrganisation=" +
                      auth.user?.homeOrganisation
                  )
                }
              >
                Create New Application
              </Button>
              <div
                className={styles["grid-container"]}
                onClick={() => {
                  handleOverlayClick();
                  setActionsOpen((prev) => !prev);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                  <circle cx="24.5" cy="2.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="24.5" cy="13.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="24.5" cy="24.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="13.5" cy="2.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="13.5" cy="13.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="13.5" cy="24.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="2.5" cy="2.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="2.5" cy="13.5" r="2.5" fill="#A9A9A9" />
                  <circle cx="2.5" cy="24.5" r="2.5" fill="#A9A9A9" />
                </svg>
              </div>
              <div
                className={styles["user-profile-container"]}
                onClick={() => {
                  handleOverlayClick();
                  setProfileOpen((prev) => !prev);
                }}
              >
                <div className={styles["user-icon"]}>
                  <div className={styles["user-icon-initials"]}>
                    {auth.user?.personal?.firstName?.[0]?.toUpperCase()}{" "}
                    {auth.user?.personal?.lastName?.[0]?.toUpperCase()}
                  </div>
                </div>
                <FontAwesomeIcon icon={faChevronDown} className={styles["user-icon-chevron"]} />
              </div>
            </div>
          </>
        )}
      </div>
      {/* Switch Container */}
      <Overlay open={switchOpen || profileOpen || actionsOpen} onClick={() => handleOverlayClick()} />
      <div className={clsx(styles["switch-selector-container"], switchOpen && styles["open"])}>
        {availableOrganisations.slice(0, 5).map((org) => {
          return (
            <div
              key={org.organisationID}
              className={styles["switch-selector"]}
              onClick={() => handleSwitchSelectorClick(org, "/")}
            >
              <div className={styles["switch-icon"]}>{org.name?.[0]}</div>
              <p>{truncate(org.name, 20)}</p>
            </div>
          );
        })}
        <div className={styles["switch-new"]} onClick={() => handleSwitchSelectorClick(null, "/hq")}>
          <div className={styles["switch-new-icon"]}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <p>Add a new organisation</p>
        </div>
        <div className={styles["switch-all"]} onClick={() => handleSwitchSelectorClick(null, "/hq")}>
          <p>
            {availableOrganisations.length > 1
              ? `View all ${availableOrganisations.length} organisations`
              : `Head to Eline HQ`}
          </p>
          <div className={styles["switch-all-icon"]}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
      {/* Profile container */}
      <div className={clsx(styles["profile-container"], profileOpen && styles["open"])}>
        {profileItems.map((item) => {
          return (
            <div
              key={item.label}
              className={styles["profile-item"]}
              onClick={() => (item.func ? item.func() : navigate(item.path))}
            >
              <p>{item.label}</p>
              <div className={styles["profile-icon"]}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
          );
        })}
      </div>
      {/* Actions Container */}
      <div className={clsx(styles["actions-container"], actionsOpen && styles["open"])}>
        {actionItems.map((item) => {
          return (
            <div
              key={item.label}
              className={styles["action-item"]}
              onClick={() => (item.func ? item.func() : navigate(item.path))}
            >
              <p>{item.label}</p>
              <div className={styles["action-icon"]}>
                <FontAwesomeIcon icon={item.icon} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default MainNavigation;
