import clsx from "clsx";
import styles from "./Modal.module.css";
import Overlay from "./Overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ label, children, showModal, setShowModal, className }) => {
  return (
    <>
      <Overlay open={showModal} onClick={() => setShowModal(false)} />
      <div className={clsx(styles["modal"], className, showModal && styles["visible"])}>
        <div className={styles["modal-header"]}>
          <p className={styles["label"]}>{label}</p>
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowModal(false)} />
        </div>

        <div className={styles["modal-divider"]}></div>
        <div className={styles["modal-content"]}>{children}</div>
      </div>
    </>
  );
};

export default Modal;
