import Main from "src/components/layout/Main";
import styles from "./CompletedApplication.module.css";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { customFetch } from "src/utilities/http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Input from "src/components/Interface/inputs/Input";
import Select from "src/components/Interface/inputs/Select";
import Date from "src/components/Interface/inputs/Date";
import { titleCase } from "src/utilities/format";
import CheckboxDisplay from "src/components/Interface/inputs/CheckboxDisplay";
import useOrganisation from "src/hooks/useOrganisation";
import useNav from "src/hooks/useNav";
import globalStyles from "src/Global.module.css";
const CompletedApplication = () => {
  const [application, setApplication] = useState({});
  const [availableFunds, setAvailableFunds] = useState([]);
  const { key } = useParams();
  const navigate = useNav();
  const { organisation = {} } = useOrganisation();

  const getSuperFunds = useCallback(async () => {
    const { organisationID } = organisation;
    const res = await customFetch({
      node: "xero-node",
      method: "GET_SUPERFUNDS",
      data: { organisationID },
    });

    setAvailableFunds(res.data);
  }, [organisation]);

  useEffect(() => {
    getSuperFunds();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const {
        data: [application],
      } = await customFetch({
        entity: "Application.Application",
        method: "GET",
        criteria: { key },
      });

      setApplication(application);
    })();
  }, [key]);

  return (
    <Main>
      <div className={styles["container"]}>
        <div className={styles["heading"]}>
          <div className={styles["heading-details"]}>
            <h1>
              {application?.firstName} {application?.lastName}
            </h1>
            <div className={styles["heading-status"]}>
              <div className={styles["icon"]}></div>
              <p>successful</p>
            </div>
          </div>
          <div className={styles["heading-icon"]} onClick={() => navigate("/")}>
            <FontAwesomeIcon icon={faTimes} className={styles["fa-icon"]} />
          </div>
        </div>
        <hr className={styles["heading-divider"]} />
        <div className={styles["content"]}>
          <div className={styles["content-top"]}>
            <div className={styles["content-left"]}>
              {/* Personal Details */}
              <div className={styles["block"]}>
                <p className={styles["block-heading"]}>Personal Details</p>
                <div className={styles["block-content"]}>
                  <div className={globalStyles["form-flex-container"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Select
                        options={["Mr", "Ms", "Mrs", "Dr"]}
                        label={"Title"}
                        inActive
                        value={application?.title}
                        placeholder={"Title"}
                        boxShadow
                        noHover
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"First Name"}
                        inActive
                        value={application?.firstName}
                        placeholder={"First Name"}
                        boxShadow
                      />
                    </div>
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <Input
                      type="text"
                      label={"Last Name"}
                      inActive
                      value={application?.lastName}
                      placeholder={"Last Name"}
                      boxShadow
                    />
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <Input
                      type="text"
                      label={"Email"}
                      inActive
                      value={application?.email}
                      placeholder={"Email"}
                      boxShadow
                    />
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <Input
                      type="text"
                      label={"Phone"}
                      inActive
                      value={application?.phone}
                      placeholder={"Phone"}
                      boxShadow
                    />
                  </div>
                  <div className={globalStyles["form-flex-container"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Date
                        label={"Date of Birth"}
                        format={"YYYY-MM-DD"}
                        value={application?.dateOfBirth}
                        inActive
                        placeholder={"Date of Birth"}
                        boxShadow
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Select
                        options={[
                          { label: "Male", value: "M" },
                          { label: "Female", value: "F" },
                          { label: "Non-Binary", value: "I" },
                          { label: "Rather Not Specify", value: "N" },
                        ]}
                        label={"Gender"}
                        placeholder={"Gender"}
                        inActive
                        value={application?.gender}
                        boxShadow
                        noHover
                      />
                    </div>
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <Input
                      type="text"
                      label={"Address"}
                      inActive
                      value={application?.homeAddress?.addressLine1}
                      placeholder={"Address Line 1"}
                      boxShadow
                    />
                    <Input
                      type="text"
                      label={""}
                      inActive
                      value={application?.homeAddress?.addressLine2}
                      mt={"5px"}
                      placeholder={"Address Line 2"}
                      boxShadow
                    />
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <Input
                      type="text"
                      label={"Suburb"}
                      inActive
                      value={application?.homeAddress?.city}
                      placeholder={"Suburb"}
                      boxShadow
                    />
                  </div>
                  <div className={globalStyles["form-flex-container"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Postcode"}
                        inActive
                        value={application?.homeAddress?.postalCode}
                        placeholder={"State"}
                        boxShadow
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Select
                        options={["ACT", "NSW", "QLD", "SA", "TAS", "VIC", "WA"]}
                        label={"State"}
                        placeholder={"State"}
                        inActive
                        value={application?.homeAddress?.region}
                        boxShadow
                        name={"region"}
                        noHover
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------------------------------------------------- */}
            </div>
            <div className={styles["content-right"]}>
              {/* Employment Details */}
              <div className={styles["block"]}>
                <p className={styles["block-heading"]}>Employment Details</p>
                <div className={styles["block-content"]}>
                  <div className={globalStyles["form-input-container"]}>
                    <Input
                      type="text"
                      label={"Employment Type"}
                      inActive
                      value={titleCase(application?.employmentType)}
                      placeholder={"Job Title"}
                      boxShadow
                    />
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <Select
                      options={[
                        { label: "Salary & Wages", value: "SALARYANDWAGES" },
                        { label: "Closely Held Employees", value: "CLOSELYHELDPAYEES" },
                        { label: "Working holiday maker", value: "WORKINGHOLIDAYMAKER" },
                        { label: "Labour Hire", value: "LABOURHIRE" },
                        { label: "Non-employee", value: "NONEMPLOYEE" },
                      ]}
                      label={"Income Type"}
                      placeholder={"Income Type"}
                      inActive
                      value={application?.incomeType}
                      boxShadow
                      noHover
                    />
                  </div>

                  <div className={globalStyles["form-flex-container"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Job Title"}
                        inActive
                        value={application?.jobTitle}
                        placeholder={"Job Title"}
                        boxShadow
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Date
                        label={"Start Date"}
                        value={application?.startDate}
                        inActive
                        format={"YYYY-MM-DD"}
                        placeholder={"Start Date"}
                        boxShadow
                      />
                    </div>
                  </div>

                  <div className={globalStyles["form-flex-container"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Award Classification"}
                        inActive
                        value={application?.classification}
                        placeholder={"Award Classification"}
                        boxShadow
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Select
                        options={[
                          { label: "Full Time", value: "FULLTIME" },
                          { label: "Part Time", value: "PARTTIME" },
                          { label: "Casual", value: "CASUAL" },
                        ]}
                        label={"Employment Basis"}
                        placeholder={"Employment Basis"}
                        inActive
                        value={application?.employmentBasis}
                        boxShadow
                        noHover
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------------------------------------------------- */}
              {/* Tax Details */}
              <div className={styles["block"]}>
                <p className={styles["block-heading"]}>Tax Details</p>
                <div className={styles["block-content"]}>
                  <div className={globalStyles["form-flex-container"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Tax File Number (TFN)"}
                        inActive
                        value={application?.taxDeclaration?.taxFileNumber}
                        placeholder={"Tax File Number"}
                        boxShadow
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Select
                        options={[
                          { label: "No TFN", value: "NOTQUOTED" },
                          { label: "Awaiting TFN", value: "PENDING" },
                          { label: "Pensioner", value: "PENSIONER" },
                          { label: "Under 18", value: "UNDER18" },
                        ]}
                        label={"TFN Exemption"}
                        placeholder={"TFN Exemption"}
                        inActive
                        value={application?.taxDeclaration?.tFNExemptionType}
                        boxShadow
                        noHover
                      />
                    </div>
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <Select
                      options={[
                        { label: "Australian Resident", value: "AUSTRALIANRESIDENT" },
                        { label: "Foreign Resident", value: "FOREIGNRESIDENT" },
                      ]}
                      label={"Residency Status"}
                      placeholder={"Residency Status"}
                      inActive
                      value={application?.taxDeclaration?.residencyStatus}
                      boxShadow
                      noHover
                    />
                  </div>
                  <div className={globalStyles["form-flex-container"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Other tax offest claimed"}
                        inActive
                        value={application?.taxDeclaration?.taxOffsetEstimatedAmount}
                        placeholder={"0.00"}
                        boxShadow
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Increased amount of tax witheld"}
                        inActive
                        value={application?.taxDeclaration?.upwardVariationTaxWithholdingAmount}
                        placeholder={"0.00"}
                        boxShadow
                      />
                    </div>
                  </div>
                  <div className={globalStyles["form-input-container"]}>
                    <CheckboxDisplay
                      value={application?.taxDeclaration?.hasLoanOrStudentDebt}
                      text={"Do you have study or training loans?"}
                      boxShadow
                    />
                    <CheckboxDisplay
                      value={application?.taxDeclaration?.taxFreeThresholdClaimed}
                      text={"Would you like to claim the tax free threshold?"}
                      boxShadow
                    />
                    <CheckboxDisplay
                      value={application?.eligibleToReceiveLeaveLoading}
                      text={"Are you eligible for leave loading?"}
                      boxShadow
                    />
                  </div>
                </div>
              </div>
              {/* ---------------------------------------------------------- */}
            </div>
          </div>
          <div className={styles["content-bottom"]}>
            {/* Bank Details */}
            <div className={styles["block"]}>
              <p className={styles["block-heading"]}>Bank Details</p>
              <div className={styles["block-content"]}>
                {application?.bankAccounts?.length === 0 && (
                  <p className={styles["none-added"]}>No bank accounts added.</p>
                )}
                {application?.bankAccounts?.map((account) => {
                  return (
                    <div className={styles["bank-account"]} key={account.accountNumber}>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Account Name"}
                          inActive
                          value={account.accountName}
                          placeholder={"Account Name"}
                          boxShadow
                        />
                      </div>
                      <div className={globalStyles["form-flex-container"]}>
                        <div className={globalStyles["form-input-container"]}>
                          <Input type="text" label={"BSB"} inActive value={account.bSB} placeholder={"BSB"} boxShadow />
                        </div>
                        <div className={globalStyles["form-input-container"]}>
                          <Input
                            type="text"
                            label={"Account Number"}
                            inActive
                            value={account.accountNumber}
                            placeholder={"Account Number"}
                            boxShadow
                          />
                        </div>
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Statement Text"}
                          inActive
                          value={account.statementText}
                          placeholder={"Statement Text"}
                          boxShadow
                        />
                        <CheckboxDisplay value={account.remainder} text={"Is remainder?"} boxShadow />
                      </div>
                      {!account.remainder && (
                        <div className={globalStyles["form-input-container"]}>
                          <Input
                            type="text"
                            label={"Fixed Amount"}
                            inActive
                            value={account.amount}
                            placeholder={"0.00"}
                            boxShadow
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* ---------------------------------------------------------- */}
            {/* Super Details */}
            <div className={styles["block"]}>
              <p className={styles["block-heading"]}>Super Details</p>
              <div className={styles["block-content"]}>
                {application?.superMemberships?.length === 0 && (
                  <p className={styles["none-added"]}>No super memberships added.</p>
                )}
                {application?.superMemberships?.map((membership) => {
                  return membership.superFundID ? (
                    <div className={styles["super-membership"]} key={membership.superFundID}>
                      <div className={globalStyles["form-input-container"]}>
                        <Select
                          options={availableFunds.map((fund) => {
                            return { label: fund.name, value: fund.superFundID };
                          })}
                          label={"Super Fund"}
                          placeholder={"Super Fund"}
                          value={membership.superFundID}
                          boxShadow
                          noHover
                          inActive
                        />
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Employee Number"}
                          inActive
                          value={membership?.employeeNumber}
                          placeholder={"Employee Number"}
                          boxShadow
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={styles["super-membership"]}>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"ABN"}
                          inActive
                          value={membership?.aBN}
                          placeholder={"ABN"}
                          boxShadow
                        />
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Account Name"}
                          inActive
                          value={membership?.accountName}
                          placeholder={"Account Name"}
                          boxShadow
                        />
                      </div>
                      <div className={globalStyles["form-flex-container"]}>
                        <div className={globalStyles["form-input-container"]}>
                          <Input
                            type="text"
                            label={"BSB"}
                            inActive
                            value={membership?.bSB}
                            placeholder={"BSB"}
                            boxShadow
                          />
                        </div>
                        <div className={globalStyles["form-input-container"]}>
                          <Input
                            type="text"
                            label={"Account Number"}
                            inActive
                            value={membership?.accountNumber}
                            placeholder={"Account Number"}
                            boxShadow
                          />
                        </div>
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Electronic Service Address"}
                          inActive
                          value={membership?.electronicServiceAddress}
                          placeholder={"Elecrtronic Service Address"}
                          boxShadow
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* ---------------------------------------------------------- */}
          </div>
        </div>
      </div>
    </Main>
  );
};
export default CompletedApplication;
