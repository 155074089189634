import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    applicationModal: {
      open: false,
      mode: "create",
      status: "draft",
    },
    loading: true,
  },
  reducers: {
    setApplicationModal(state, action) {
      state.applicationModal = { ...state.applicationModal, ...action.payload };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
