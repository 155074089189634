import useAuth from "src/hooks/useAuth";
import styles from "./Main.module.css";
import Navigation from "./navigation/Navigation";
import { useLayoutEffect } from "react";
import useOrganisation from "src/hooks/useOrganisation";
import useUI from "src/hooks/useUI";
import useNav from "src/hooks/useNav";
import { useLocation, useSearchParams } from "react-router-dom";

const excludedPaths = ["/application/new", "/billing", "/new-payment-method", "/plans", "/history"];

const Main = ({ children }) => {
  const { auth, initAuth } = useAuth();
  const { organisation } = useOrganisation();
  const navigate = useNav();
  const { loading } = useUI();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    (async () => {
      const token = searchParams.get("token");

      if (token) {
        localStorage.setItem("token", token);
        await initAuth();
        return navigate("/hq");
      }

      if (!auth.isAuthenticated && !loading) {
        navigate("/portal");
      }

      if (excludedPaths.includes(pathname)) return;

      if (!organisation && auth.isAuthenticated && !loading) {
        const searchParams = new URLSearchParams(search);
        navigate("/hq?" + searchParams);
      }
    })();
  }, [organisation, auth.isAuthenticated, navigate, loading, pathname, auth.user, search, searchParams, initAuth]);

  return (
    <>
      <div className={styles["wrapper"]}>
        <Navigation />
        <div className={styles["content"]}>{children}</div>
      </div>
    </>
  );
};

export default Main;
