import clsx from "clsx";
import styles from "../Application.module.css";

const tabs = [
  { label: "Confirmation" },
  { label: "Personal Details" },
  { label: "Tax Details" },
  { label: "Bank Details" },
  { label: "Super Details" },
];

const ApplicationNav = ({ tab: activetab, setTab }) => {
  return (
    <div className={styles["nav-container"]}>
      {tabs.map((tab, index) => {
        return (
          <div
            key={index}
            className={clsx(styles["nav-item"], activetab >= index && styles["active"])}
            // onClick={() => {
            //   setTab(index);
            //   window.scrollTo(0, 400);
            // }}
          >
            <p className={styles["nav-item-label"]}>{tab.label}</p>
          </div>
        );
      })}
      <div className={styles["progress-bar-container"]}>
        <div className={styles["progress-wrapper"]}>
          <div className={styles["progress-bar"]} style={{ width: 20 * (activetab + 1) + "vw" }}></div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationNav;
