import Main from "src/components/layout/Main";
import styles from "./Dashboard.module.css";
import Analytics from "./components/Analytics";
import Progress from "./components/Progress";
import { useCallback, useEffect } from "react";
import { customFetch } from "src/utilities/http";
import useOrganisation from "src/hooks/useOrganisation";
import useNav from "src/hooks/useNav";

const Dashboard = () => {
  const { organisation } = useOrganisation();
  const navigate = useNav();

  const validateOrg = useCallback(async () => {
    if (!organisation) return;

    const res = await customFetch({
      entity: "User.Organisation",
      method: "VALIDATE_XERO_CONNECTION",
      data: { organisationID: organisation?.organisationID },
    });

    if (res.status === 400) {
      navigate("/hq");
    }
  }, [organisation, navigate]);

  useEffect(() => {
    validateOrg();
  }, [validateOrg]);

  return (
    <Main>
      <div className={styles["container"]}>
        <Analytics />
        <Progress />
      </div>
    </Main>
  );
};

export default Dashboard;
