import Button from "src/components/Interface/buttons/Button";
import styles from "./PricingPlan.module.css";
import { useCallback, useState, useEffect } from "react";
import { customFetch } from "src/utilities/http";
import useNav from "src/hooks/useNav";
import { checkUserHasPaymentSetup } from "src/utilities/payment";
import useAlerts from "src/hooks/useAlerts";
import useOrganisation from "src/hooks/useOrganisation";

const organisationLimits = {
  1: 1,
  2: 5,
  3: 10,
  4: 20,
};

const PricingPlan = ({
  name,
  price,
  numberOfOrganisations,
  description,
  pricePerApplication,
  activePlan,
  index,
  setActivePlan,
}) => {
  const [loading, setLoading] = useState(false);
  const [userHasPaymentMethod, setUserHasPaymentMethod] = useState(true);
  const { availableOrganisations } = useOrganisation();
  const navigate = useNav();
  const { confirm, alert } = useAlerts();

  useEffect(() => {
    (async () => {
      const { hasPaymentMethod } = await checkUserHasPaymentSetup();
      setUserHasPaymentMethod(hasPaymentMethod);
    })();
  }, []);

  const handleButtonClick = useCallback(async () => {
    if (activePlan === index) {
      return navigate("/history");
    }

    if (!userHasPaymentMethod) return navigate("/new-payment-method");

    try {
      if (activePlan > index) {
        const sum = availableOrganisations?.length - organisationLimits[index];
        if (availableOrganisations?.length > organisationLimits[index]) {
          return alert({
            label: "Too many Xero connections.",
            text: `You have ${
              availableOrganisations?.length
            } connected organisations. You will need to disconnect ${sum} organisation${
              sum > 1 ? "s" : ""
            } before you can downgrade.`,
            confirmText: "Okay",
          });
        }

        await confirm({
          label: "Are you sure?",
          text: "You will be downgrading your subscription. This will take effect immediately.",
          confirmText: "Downgrade",
          cancelText: "Cancel",
          inverse: true,
        });
      }
      setLoading(true);
      const { status } = await customFetch({
        entity: "User.HomeOrganisation",
        method: "UPDATE_SUBSCRIPTION",
        data: { level: index },
      });
      setLoading(false);

      if (status === 200) {
        setActivePlan(index);
        window.location.reload();
      }
    } catch (error) {}
  }, [index, setActivePlan, activePlan, navigate, userHasPaymentMethod, availableOrganisations]);

  return (
    <div className={styles["container"]} style={{ borderRadius: activePlan === index ? "0 0 25px 25px" : "25px" }}>
      {activePlan === index && (
        <div className={styles["banner"]}>
          <p>You&apos;re on this plan</p>
        </div>
      )}
      <div className={styles["header"]}>
        <h3>{name}</h3>
        <h1>{price}</h1>
        <h4>
          {numberOfOrganisations} {numberOfOrganisations > 1 ? "organisations" : "organisation"}
        </h4>
        <p className={styles["description"]}>{description}</p>
      </div>
      <div className={styles["application-container"]}>
        <p>${pricePerApplication} per application</p>
      </div>

      <div className={styles["button"]}>
        <Button className={styles["button-item"]} onClick={handleButtonClick} theme={"blue"}>
          {loading
            ? "Loading..."
            : activePlan === index
            ? "View Invoices"
            : activePlan < index
            ? "Upgrade"
            : " Downgrade"}
        </Button>
      </div>
    </div>
  );
};

export default PricingPlan;
