import styles from "../Portal.module.css";
import logo from "src/assets/global/eline-logo.svg";
import illustration from "src/assets/svg/login-illustration.svg";
import Input from "src/components/Interface/inputs/Input";
import { validateEmail } from "src/utilities/validation";
import Form from "src/components/Interface/layout/Form";
import { useState } from "react";
import Checkbox from "src/components/Interface/inputs/Checkbox";
import useAuth from "src/hooks/useAuth";
import useToast from "src/hooks/useToast";
import Button from "src/components/Interface/buttons/Button";
import { faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import H1 from "src/components/Interface/headings/H1";
import Providers from "./Providers";
import useWindowSize from "src/hooks/useWindowSize";
import P from "src/components/Interface/text/P";
import useNav from "src/hooks/useNav";

const Login = ({ setTab }) => {
  const [data, setData] = useState({});
  const { login } = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { screen } = useWindowSize();
  const navigate = useNav();

  const onSubmitHandler = async (e) => {
    setLoading(true);

    if (data.isValid) {
      const user = await login({ email: data?.data?.email, password: data?.data?.password });

      if (user) {
        toast.success(`Welcome back ${user?.personal?.firstName}!`);
        navigate("/hq");
      } else {
        toast.error("Incorrect email or password.");
      }
    } else {
      toast.error("Please complete the login form.");
    }
    setLoading(false);
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["login-left"]}>
        <img src={logo} alt="logo graphic" className={styles["login-logo"]} />
        <H1 mt={screen === "mobile" ? "30px" : " 40px"} mb={"30px"}>
          Welcome back!
        </H1>
        <Providers />
        <Form onSubmit={onSubmitHandler} setFormData={setData} className={styles["login-form"]}>
          <Input
            type="text"
            name={"email"}
            label={"Email"}
            validation={validateEmail}
            placeholder={"johndoe@example.com"}
            message={"Please enter a valid email address."}
          />
          <Input type="password" name={"password"} label={"Password"} placeholder={"********"} mt={"60px"} />
          <div className={styles["login-form-actions"]}>
            <Checkbox name={"signedin"} label={"Keep me signed in"}></Checkbox>
            <div className={styles["forgot-password-link"]} onClick={() => setTab("reset-email")}>
              <P>Forgot Password?</P>
            </div>
            <Button
              icon={!loading ? faChevronRight : faSpinner}
              theme={"blue"}
              size={"medium"}
              className={styles["sign-in-button"]}
              onClick={onSubmitHandler}
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
          </div>
          <div className={styles["login-buttons"]}>
            <div className={styles["login-switch"]}>
              <P>
                Don't have an account?
                <span
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setTab("signup");
                  }}
                >
                  Sign me up!
                </span>
              </P>
            </div>
          </div>
        </Form>
      </div>
      <div className={styles["login-right"]}>
        <img src={illustration} alt="login graphic" className={styles["login-illustration"]} />
      </div>
    </div>
  );
};

export default Login;
