import Main from "src/components/layout/Main";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentMethodForm from "./PaymentMethodForm";
import useAuth from "src/hooks/useAuth";
import { useEffect, useState } from "react";
import { customFetch } from "src/utilities/http";
import useToast from "src/hooks/useToast";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const NewPaymentMethod = () => {
  const [secretKey, setSecretKey] = useState("");
  const toast = useToast();

  const {
    auth: { user },
  } = useAuth();

  useEffect(() => {
    if (!user) return;
    const getSecretKey = async () => {
      const { status, error, data } = await customFetch({
        entity: "Payment.PaymentMethod",
        method: "CREATE_SETUP_INTENT",
        data: { userID: user?.userID },
      });
      if (status !== 200) {
        toast.error(error);
        return;
      }
      setSecretKey(data?.client_secret);
    };
    getSecretKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Main>
      {secretKey && (
        <Elements stripe={stripePromise} options={{ clientSecret: secretKey }}>
          <PaymentMethodForm secretKey={secretKey} />
        </Elements>
      )}
    </Main>
  );
};
export default NewPaymentMethod;
