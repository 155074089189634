import clsx from "clsx";
import styles from "./ApplicationCard.module.css";
import Pencil from "src/assets/svg/pencil.svg";
import Bell from "src/assets/svg/bell.svg";
import Tick from "src/assets/svg/tick.svg";
import FailedPencil from "src/assets/svg/failed-pencil.svg";
import { useCallback } from "react";
import useOrganisation from "src/hooks/useOrganisation";
import useAuth from "src/hooks/useAuth";
import { countDays } from "src/utilities/date";
import useAlerts from "src/hooks/useAlerts";
import useNav from "src/hooks/useNav";
import { reminderToCompleteApplication } from "src/utilities/Email/index";
import { buildEmployeeOnboardingLink } from "src/utilities/generate";
import { customFetch } from "src/utilities/http";

const ApplicationCard = ({ application, refresh = () => {} }) => {
  const { status } = application;
  const navigate = useNav();
  const { organisation } = useOrganisation();
  const { auth } = useAuth();
  let icon = Pencil;
  const { confirm } = useAlerts();
  const { user } = auth;
  switch (status) {
    case "pending":
      icon = Bell;
      break;
    case "failed":
      icon = FailedPencil;
      break;
    case "draft":
      icon = Pencil;
      break;
    default:
      icon = Tick;
  }

  const daysAgo = countDays(new Date(), application?.createdAt);
  const remindedDays = countDays(new Date(), application?.reminderSent);

  const navigateToApplication = useCallback(() => {
    navigate(
      application.status === "success"
        ? `/completed/${application.key}`
        : `/application/${application.key}?organisation=${organisation?.name}&organisationID=${organisation?.organisationID}&homeOrganisation=${application?.homeOrganisation}`
    );
  }, [navigate, application, organisation]);

  const openApplication = useCallback(
    async (e) => {
      if (e && e.stopPropagation) e.stopPropagation(); // prevent bubbling up to parent
      if (status === "pending") {
        try {
          await confirm({
            label: "Send Reminder",
            text: "Would you like to send a reminder to this applicant?",
            confirmText: "Send Reminder",
            cancelText: "Cancel",
          });

          const link = buildEmployeeOnboardingLink({
            organisation: organisation.name,
            organisationID: organisation.organisationID,
            homeOrganisation: user?.homeOrganisation,
            key: application.key,
          });

          reminderToCompleteApplication({
            firstName: application.firstName,
            link,
            organisation: organisation?.name,
            data: {
              to: application.email,
              subject: "Reminder to Complete your Onboarding",
            },
          });

          await customFetch({
            entity: "Application.Application",
            method: "UPDATE",
            criteria: {
              key: application.key,
            },
            data: { reminderSent: new Date() },
          });

          refresh();
        } catch (error) {}
      } else {
        navigateToApplication();
      }
    },
    [status, organisation, application, confirm, user, navigateToApplication, refresh]
  );

  return (
    <>
      <div className={styles["container"]} onClick={() => navigateToApplication()}>
        <h3>
          {application.firstName} {application.lastName}
        </h3>
        {status !== "draft" ? (
          <p>{application.jobTitle}</p>
        ) : (
          <p className={styles["incomplete-subline"]}>Incomplete</p>
        )}
        <div className={styles["details"]}>
          <p>
            <span>{status === "draft" ? "saved" : "sent"}: </span>{" "}
            {daysAgo === 0 ? "Today" : `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`}
          </p>
          {application.reminderSent && (
            <p>
              <span>reminded: </span>{" "}
              {remindedDays === 0 ? "Today" : `${remindedDays} ${remindedDays === 1 ? "day" : "days"} ago`}
            </p>
          )}
          <p>
            <span>status: </span> {status} <span className={clsx(styles["status-icon"], styles[status])}></span>
          </p>
        </div>
        <div className={clsx(styles["icon"], styles[status])} onClick={openApplication}>
          <img src={icon} alt="status indicator" />
        </div>
        <div className={clsx(styles["status"], styles[status])}></div>
      </div>
    </>
  );
};

export default ApplicationCard;
