import clsx from "clsx";
import styles from "./StatusIcon.module.css";

const StatusIcon = ({ status }) => {
  return (
    <div className={styles["status-container"]}>
      <div className={clsx(styles["status-icon"], styles[status])}></div>
      <p>{status === "failed" ? "requires action" : status}</p>
    </div>
  );
};
export default StatusIcon;
