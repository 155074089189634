import clsx from "clsx";
import styles from "./P.module.css";
const P = ({ className, children, mx, my, px, py, mt, mb, ml, mr, pt, pb, pl, pr, textAlign, weight, size, ...props }) => {
  return (
    <p
      style={{
        marginTop: mt ? mt : my,
        marginBottom: mb ? mb : my,
        marginLeft: ml ? ml : mx,
        marginRight: mr ? mr : mx,
        paddingTop: pt ? pt : py,
        paddingBottom: pb ? pb : py,
        paddingLeft: pl ? pl : px,
        paddingRight: pr ? pr : px,
        textAlign: textAlign,
      }}
      className={clsx(styles["tag"], styles[weight], styles[size], className)}
      {...props}
    >
      {children}
    </p>
  );
};
export default P;
