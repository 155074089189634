import styles from "../Application.module.css";
import Input from "src/components/Interface/inputs/Input";
import { isNotBlank, validateDOB, validateEmail, validateMobile } from "src/utilities/validation";
import Select from "src/components/Interface/inputs/Select";
import ControlButtons from "../subcomponents/ControlButtons";
import Date from "src/components/Interface/inputs/Date";
import { useCallback } from "react";
import { useForm } from "src/contexts/Form";
import globalStyles from 'src/Global.module.css';

const PersonalDetails = ({ setTab, tab }) => {
  const { setData } = useForm();

  const updateHomeAddress = useCallback(
    (val, name) => {
      setData((prev) => ({ ...prev, homeAddress: { ...prev.homeAddress, [name]: val } }));
    },
    [setData]
  );

  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["form-container"]}>
        <p className={styles["content-heading"]}>Personal Details</p>
        <div className={globalStyles["form-flex-container"]}>
          <div className={globalStyles["form-input-container"]} >
            <Select
              options={["Mr", "Ms", "Mrs", "Dr"]}
              label={"Title"}
              placeholder={"select"}
              name={"title"}
              validation={isNotBlank}
              message={"Select your title."}
              mandatory
            />
          </div>
          <div className={globalStyles["form-input-container"]}>
            <Input
              type="text"
              name={"firstName"}
              label={"First Name"}
              validation={isNotBlank}
              placeholder={"John"}
              message={"Enter your first name."}
              maxLength={35}
              mandatory
            />
          </div>
        </div>
        <div className={globalStyles["form-input-container"]}>
          <Input
            type="text"
            name={"lastName"}
            label={"Last Name"}
            validation={isNotBlank}
            placeholder={"Doe"}
            message={"Enter your last name."}
            maxLength={35}
            mandatory
          />
        </div>
        <div className={globalStyles["form-input-container"]}>
          <Input
            type="text"
            name={"email"}
            label={"Email"}
            validation={validateEmail}
            placeholder={"johndoe@example.com"}
            mandatory
          />
        </div>
        <div className={globalStyles["form-input-container"]}>
          <Input
            type="text"
            name={"phone"}
            label={"Mobile Number"}
            validation={validateMobile}
            placeholder={"0400000000"}
            maxLength={50}
            mandatory
          />
        </div>
        <div className={globalStyles["form-flex-container"]}>
          <div className={globalStyles["form-input-container"]}>
            <Date
              label={"Date of Birth"}
              mandatory
              name={"dateOfBirth"}
              validation={validateDOB}
              format={"YYYY-MM-DD"}
            />
          </div>
          <div className={globalStyles["form-input-container"]}>
            <Select
              options={[
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
                { label: "Non-Binary", value: "I" },
                { label: "Rather Not Specify", value: "N" },
              ]}
              mandatory
              label={"Gender"}
              placeholder={"select"}
              name={"gender"}
              validation={isNotBlank}
            />
          </div>
        </div>

        <div className={styles["header-divider"]}></div>

        <div className={globalStyles["form-input-container"]}>
          <Input
            type="text"
            name={"addressLine1"}
            label={"Address"}
            validation={isNotBlank}
            placeholder={"4 Privet Drive"}
            message={"Enter your street address."}
            maxLength={50}
            onChange={(val) => {
              updateHomeAddress(val, "addressLine1");
            }}
            mandatory
          />
          <Input
            mt={"5px"}
            type="text"
            name={"addressLine2"}
            placeholder={"Little Whinging"}
            maxLength={50}
            onChange={(val) => {
              updateHomeAddress(val, "addressLine2");
            }}
          />
        </div>
        <div className={globalStyles["form-input-container"]}>
          <Input
            type="text"
            name={"city"}
            label={"Suburb"}
            validation={isNotBlank}
            placeholder={"Surrey"}
            message={"Enter your suburb."}
            mandatory
            onChange={(val) => {
              updateHomeAddress(val, "city");
            }}
          />
        </div>

        <div className={globalStyles["form-flex-container"]}>
          <div className={globalStyles["form-input-container"]}>
            <Input
              type="number"
              name={"postalCode"}
              label={"Postcode"}
              validation={isNotBlank}
              placeholder={"2316"}
              message={"Enter your postcode."}
              mandatory
              onChange={(val) => {
                updateHomeAddress(val, "postalCode");
              }}
            />
          </div>
          <div className={globalStyles["form-input-container"]}>
            <Select
              options={["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]}
              label={"State"}
              placeholder={"select"}
              name={"region"}
              mandatory
              onChange={(val) => {
                updateHomeAddress(val, "region");
              }}
            />
          </div>
        </div>
      </div>
      <ControlButtons setTab={setTab} tab={tab} />
    </div>
  );
};

export default PersonalDetails;
