import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./PaginationBar.module.css";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";

const PaginationBar = ({ applications = [], setVisibleApplications, divisor }) => {
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(applications.length / divisor);

  const setApplications = useCallback(
    (page) => {
      const start = (page - 1) * divisor;
      const end = start + divisor;
      setVisibleApplications(applications.slice(start, end));
    },

    //eslint-disable-next-line
    [applications, setVisibleApplications]
  );
  useEffect(() => {
    setApplications(page);
  }, [page, setApplications]);

  return (
    <>
      <div className={styles["progress-pagination-container"]}>
        <div className={styles["page-counter"]}>
          <h3>
            Page {page} of {totalPages}
          </h3>
        </div>
        <div className={styles["page-controls"]}>
          <div className={styles["page-control"]} onClick={() => page > 1 && setPage((prev) => prev - 1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className={styles["page-control"]} onClick={() => page < totalPages && setPage((prev) => prev + 1)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginationBar;
