import clsx from "clsx";
import styles from "./AnalyticsCard.module.css";
import useNav from "src/hooks/useNav";

const AnalyticsCard = ({ status, text, metric }) => {
  const navigate = useNav();
  return (
    <>
      <div className={clsx(styles["analytics"], styles[status])} onClick={() => navigate("/all-applications")}>
        <div className={styles["triangle"]}></div>
        <div className={clsx(styles["container"], styles[status])}>
          <p>{metric}</p>
        </div>
        <p className={styles["text"]}>{text}</p>
      </div>
    </>
  );
};

export default AnalyticsCard;
