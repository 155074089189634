import { useEffect, useState } from "react";
import styles from "./ToggleButton.module.css";
import clsx from "clsx";
import useValidation from "src/hooks/useValidation";
import useInlineStyles from "src/hooks/useInlineStyles";
import P from "../text/P";
import useToast from "src/hooks/useToast";

const ToggleButton = ({
  buttons = [],
  name,
  label,
  placeholder,
  background,
  validation = () => true,
  mandatory = false,
  message,
  onChange: onChangeProp = () => {},
  disabled,
  inActive = false,
  noHover,
  ...props
}) => {
  const { error, setError, setTouched, data, setData } = useValidation({ name, validation, mandatory, onChangeProp });
  const [active, setActive] = useState(data[name] || "");
  const style = useInlineStyles({ ...props });
  const toast = useToast();

  useEffect(() => {
    setActive(data[name]);
  }, [data, name, setActive]);

  return (
    <div
      className={clsx(styles["toggle-container"], disabled && styles["disabled"])}
      style={style}
      onClick={() => disabled && toast.error("This field has been locked with read-only rights.")}
    >
      <label className={styles["label"]}>{label}</label>
      <div className={styles["input-container"]}>
        {buttons.map((button, index) => {
          return (
            <div
              key={index}
              className={clsx(
                styles["button-container"],
                active === button.value && styles["active"],
                !noHover && styles["hover"]
              )}
              style={{
                borderRadius: index === 0 ? "5px 0 0 5px" : index === buttons.length - 1 ? "0 5px 5px 0" : "0",
              }}
              onClick={() => {
                if (disabled || inActive) return;
                setActive(button.value);
                setTouched(true);
                setError(false);
                setData((prev) => ({ ...prev, [name]: button.value }));
                onChangeProp(button.value);
              }}
            >
              {button.label}
            </div>
          );
        })}
      </div>
      {error && <P className={styles["message"]}>{message}</P>}
    </div>
  );
};

export default ToggleButton;
