const GoogleOAuthUrl = (homeOrg) => {
  const rootURL = "https://accounts.google.com/o/oauth2/v2/auth";

  const options = {
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    state: homeOrg,
  };
  const qs = new URLSearchParams(options);

  return `${rootURL}?${qs.toString()}`;
};

const XeroConsentUrl = async (homeOrg) => {
  return (
    `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_XERO_REDIRECT_URI}&scope=openid profile email&state=` +
    JSON.stringify({ format: "xeroIdentity", homeOrganisation: homeOrg })
  );
};

export { GoogleOAuthUrl, XeroConsentUrl };
