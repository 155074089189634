import clsx from "clsx";
import styles from "../Portal.module.css";
import googleLogo from "src/assets/global/google.png";
import xeroLogo from "src/assets/global/xero.png";
import { GoogleOAuthUrl, XeroConsentUrl } from "src/utilities/providers";
const Providers = () => {
  const googleAuthHandler = async () => {
    const homeOrganisationID = localStorage.getItem("homeOrganisation");
    window.location.href = GoogleOAuthUrl(homeOrganisationID);
    localStorage.removeItem("homeOrganisation");
  };

  const xeroAuthHandler = async () => {
    const homeOrganisationID = localStorage.getItem("homeOrganisation");
    window.location.href = await XeroConsentUrl(homeOrganisationID);
    localStorage.removeItem("homeOrganisation");
  };
  return (
    <>
      <div className={styles["login-provider-buttons"]}>
        <div onClick={googleAuthHandler} className={clsx(styles["login-provider"], styles["login-provider-google"])}>
          <img src={googleLogo} alt="google" />
          <span>Continue with Google</span>
        </div>
        <div onClick={xeroAuthHandler} className={clsx(styles["login-provider"], styles["login-provider-xero"])}>
          <img src={xeroLogo} alt="google" />
          <span>Continue with Xero</span>
        </div>
      </div>
      <div className={styles["login-divider"]}></div>
    </>
  );
};

export default Providers;
