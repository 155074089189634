import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import uiSlice from "./uiSlice";
import organisationSlice from "./organisationSlice";
const store = configureStore({
  reducer: {
    auth: authSlice,
    ui: uiSlice,
    organisation: organisationSlice,
  },
});

export default store;
