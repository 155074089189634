import { createSlice } from "@reduxjs/toolkit";

const organisationSlice = createSlice({
  name: "organisation",
  initialState: { organisation: null, provider: null, homeOrganisation: null },
  reducers: {
    setOrganisation(state, action) {
      state.organisation = action.payload;
    },
    setProvider(state, action) {
      state.provider = action.payload;
    },
    setHomeOrganisation(state, action) {
      state.homeOrganisation = action.payload;
    },
  },
});

export const organisationActions = organisationSlice.actions;

export default organisationSlice.reducer;
