import Modal from "src/components/Interface/layout/Modal";
import { useEffect, useState } from "react";
import useOrganisation from "src/hooks/useOrganisation";
import { customFetch } from "src/utilities/http";
import ManageUser from "./ManageUser";
import Button from "src/components/Interface/buttons/Button";
import styles from "./InviteTeam.module.css";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
const ManageTeamAccess = ({ show, setShow, setInvite }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const { homeOrganisation } = useOrganisation();


  useEffect(() => {
    (async () => {
      const { data } = await customFetch({
        entity: "Public.User",
        method: "GET_USERS_IN_HOME_ORGANISATION",
        data: { homeOrganisation: homeOrganisation?._id },
      });

      setTeamMembers(data);
    })();
  }, [homeOrganisation, show]);

  return (
    <Modal showModal={show} setShowModal={setShow} label={"Manage Team Access"} className={styles['modal']}>
      {teamMembers.map((member) => (
        <ManageUser
          name={member.firstName + " " + member.lastName}
          email={member.email}
          key={member.userID}
          setShowModal={setShow}
          isAdmin={homeOrganisation?.admin_email === member?.email}
        />
      ))}
      <Button
        icon={faUserPlus}
        onClick={() => {
          setInvite(true);
          setShow(false);
        }}
        className={styles["manageaccess"]}
      >
        Invite New Team Member
      </Button>
    </Modal>
  );
};

export default ManageTeamAccess;
