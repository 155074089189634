import useNav from "src/hooks/useNav";
import styles from "./Navigation.module.css";
import Button from "src/components/Interface/buttons/Button";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import useOrganisation from "src/hooks/useOrganisation";
import useAuth from "src/hooks/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "src/assets/global/eline-logo.svg";
import { useState } from "react";
import Overlay from "src/components/Interface/layout/Overlay";
import clsx from "clsx";

const navItems = [
  { label: "Dashboard", path: "/", activeKey: "" },
  { label: "All Applications", path: "/all-applications", activeKey: "all-applications" },
  { label: "Billing & Invoices", path: "/billing", activeKey: "billing" },
  { label: "Account Settings", path: "/settings", activeKey: "settings" },
];

const MobileNav = () => {
  const navigate = useNav();
  const { organisation } = useOrganisation();
  const { auth, logout } = useAuth();
  const { screen } = useWindowSize();
  const [showNav, setShowNav] = useState(false);
  const pathname = window.location.pathname.split("/")[1];
  const [active, setActive] = useState(pathname);

  const link =
    "/application/new?organisation=" +
    organisation?.name +
    "&organisationID=" +
    organisation?.organisationID +
    "&homeOrganisation=" +
    auth.user?.homeOrganisation;

  const navActions = [
    { label: "Create New Application", func: () => navigate(link), activeKey: "newapplication" },
    {
      label: "Switch Organisations",
      func: () => navigate("/hq"),
      activeKey: "hq",
    },
    {
      label: "Logout",
      func: () => {
        logout();
      },
      activeKey: "logout",
    },
  ];

  const handleNavItemClick = (item) => {
    setActive(item.activeKey);
    navigate(item.path);
  };

  return (
    <>
      <div className={styles["container"]}>
        {organisation && (
          <>
            <div className={styles["mobile-grid-icon"]} onClick={() => setShowNav((prev) => !prev)}>
              <FontAwesomeIcon icon={faBars} />
            </div>
            {screen === "mobile" && (
              <div className={styles["mobile-eline-logo"]} onClick={() => navigate("/")}>
                <img src={logo} alt="eline-logo" />
              </div>
            )}
            <div className={styles["mobile-create-app-button"]}>
              {screen === "tablet" ? (
                <Button theme={"blue"} icon={faPlus} size={"medium"} onClick={() => navigate(link)}>
                  Create New Application
                </Button>
              ) : (
                <div className={styles["mobile-plus-icon"]} onClick={() => navigate(link)}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Overlay open={showNav} onClick={() => setShowNav(false)} />
      <div className={clsx(styles["mobile-nav-container"], showNav && styles["showNav"])}>
        <div className={styles["mobile-nav-content"]}>
          <div className={styles["mobile-nav-heading"]}>
            <p>
              Hello <span>{auth?.user?.personal?.firstName}!</span>
            </p>
          </div>
          <div className={styles["mobile-nav-items"]}>
            {navItems.map((item) => (
              <div
                key={item.label}
                className={clsx(styles["mobile-nav-item"], active === item.activeKey && styles["mob-active"])}
                onClick={() => handleNavItemClick(item)}
              >
                <p>{item.label}</p>
              </div>
            ))}
          </div>
          <div className={styles["nav-actions"]}>
            {navActions.map((item) => (
              <div key={item.label} className={styles["mobile-nav-action"]} onClick={item.func}>
                <p>{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileNav;
