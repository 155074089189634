import styles from "./PaymentMethod.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import Form from "src/components/Interface/layout/Form";
import globalStyles from "src/Global.module.css";
import clsx from "clsx";
import Input from "src/components/Interface/inputs/Input";
import ToggleButton from "src/components/Interface/inputs/ToggleButton";
import Select from "src/components/Interface/inputs/Select";
import Button from "src/components/Interface/buttons/Button";
import { isNotBlank } from "src/utilities/validation";
import useNav from "src/hooks/useNav";
import { useCallback, useEffect, useState } from "react";
import useOrganisation from "src/hooks/useOrganisation";
import useToast from "src/hooks/useToast";
import { customFetch } from "src/utilities/http";
import {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import StripeWrapper from "src/components/Interface/inputs/StripeWrapper";

const stripeStyle = {
  style: {
    base: {
      color: "black",
      fontSize: "18px",
      iconColor: "#bebebe",
      "::placeholder": {
        color: "#bebebe",
        fontWeight: "300",
      },
    },
  },
};

const redirect = window.location.href.split("redirect=")?.[1];

const PaymentMethodForm = ({ secretKey = "" }) => {
  const navigate = useNav();
  const [cardBank, setCardBank] = useState("bank");
  const [initial] = useState({ methodType: "bank" });
  const { organisation, homeOrganisation } = useOrganisation();
  const [formData, setFormData] = useState({});

  const stripe = useStripe();
  const elements = useElements();

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const applicationKey = redirect?.split("?")[0];

  useEffect(() => {
    setFormData((prev) => ({ ...prev, data: { ...prev.data, organisationID: organisation?.organisationID } }));
  }, [organisation]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const { isValid, data } = formData;

    if (!isValid) {
      toast.error("Please fill in all the required fields");
      setLoading(false);
      return;
    }

    if (cardBank === "card") {
      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        toast.error("Stripe Card Element not found");
        setLoading(false);
        return;
      }

      const {
        setupIntent: { payment_method },
      } = await stripe.confirmCardSetup(secretKey, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: data.cardHolderName,
            email: homeOrganisation.admin_email,
            address: {
              line1: data.addressLine1,
              line2: data.addressLine2,
              city: data.suburb,
              state: data.state,
              postal_code: data.postCode,
            },
          },
        },
      });

      data.paymentMethod = payment_method;
    } else {
      const bankElement = elements.getElement(AuBankAccountElement);

      if (!bankElement) {
        toast.error("Stripe Bank Element not found");
        setLoading(false);
        return;
      }

      const {
        setupIntent: { payment_method },
      } = await stripe.confirmAuBecsDebitSetup(secretKey, {
        payment_method: {
          au_becs_debit: bankElement,
          billing_details: {
            name: data.accountName,
            email: homeOrganisation.admin_email,
            address: {
              line1: data.addressLine1,
              line2: data.addressLine2,
              city: data.suburb,
              state: data.state,
              postal_code: data.postCode,
            },
          },
        },
      });

      data.paymentMethod = payment_method;
    }

    if (!data.paymentMethod) {
      toast.error("Error creating payment method. Please try again later.");
      setLoading(false);
      return;
    }

    const { status, error } = await customFetch({
      entity: "Payment.PaymentMethod",
      method: "CREATE_STRIPE_PAYMENT_METHOD",
      data: { ...data, organisationID: organisation?.organisationID },
    });

    if (status !== 200) {
      toast.error(error);
      setLoading(false);
      return;
    }
    toast.success("Payment method created successfully");

    if (applicationKey) {
      await customFetch({
        entity: "Application.Application",
        method: "UPDATE",
        criteria: { key: applicationKey },
        data: { notes: "" },
      });
    }

    setLoading(false);
    redirect ? navigate("/application/" + redirect) : navigate("/billing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, stripe, elements, secretKey, navigate, organisation, applicationKey, homeOrganisation, cardBank]);

  return (
    <>
      <div className={styles["return-container"]} onClick={() => navigate("/")}>
        <div className={styles["return-icon"]}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        {<p>Back to dashboard</p>}
      </div>
      <div className={styles["new-content"]}>
        <div className={styles["new-header"]}>
          <h1>Add Payment Method: </h1>
        </div>
        <div className={styles["new-form"]}>
          <Form onSubmit={() => {}} setFormData={setFormData} initial={initial}>
            <div className={clsx(globalStyles["form-container"], styles["new-form-container"])}>
              <div className={styles["new-left"]}>
                <div className={globalStyles["block"]}>
                  <p className={globalStyles["block-heading"]}>
                    {formData?.methodType === "card" ? "Card Details" : "Bank Account Details"}
                  </p>
                </div>
                <ToggleButton
                  name={"methodType"}
                  buttons={[
                    { value: "bank", label: "Bank Account" },
                    { value: "card", label: "Credit/Debit Card" },
                  ]}
                  mt={"40px"}
                  mb={"70px"}
                  onChange={(v) => {
                    setCardBank(v);
                  }}
                  value={cardBank}
                />
                {cardBank === "card" ? (
                  // Card Details
                  <div className={styles["new-card-details"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        name={"cardHolderName"}
                        label={"Card Holder Name"}
                        placeholder={"John Doe"}
                        validation={isNotBlank}
                        message={"Please enter the cardholder's name"}
                        mandatory
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <StripeWrapper label={"Card Number"}>
                        <CardNumberElement options={stripeStyle} />
                      </StripeWrapper>
                    </div>
                    <div className={styles["exp-cvv-container"]}>
                      <div className={styles["expiry-container"]}>
                        <StripeWrapper label={"Expiry Date"}>
                          <div style={{ minWidth: "200px" }}>
                            <CardExpiryElement options={stripeStyle} />
                          </div>
                        </StripeWrapper>
                      </div>

                      <StripeWrapper label={"CVC"}>
                        <CardCvcElement options={stripeStyle} />
                      </StripeWrapper>
                    </div>
                  </div>
                ) : (
                  // Bank Account Details
                  <div className={styles["new-bank-details"]}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        name={"accountName"}
                        label={"Account Holder Name"}
                        placeholder={"John Doe"}
                        validation={isNotBlank}
                        message={"Please enter the account holder's name"}
                        mandatory
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <StripeWrapper label={"Bank Details"}>
                        <AuBankAccountElement options={stripeStyle} />
                      </StripeWrapper>
                    </div>
                  </div>
                )}
                <div className={styles["new-save-details"]}>
                  <div className={styles["new-info-container"]}>
                    <p>i</p>
                  </div>
                  <p>Bank Account / Card Details will be stored for future transactions</p>
                </div>
              </div>
              <div className={styles["new-right"]}>
                <div className={globalStyles["block"]}>
                  <p className={globalStyles["block-heading"]}>Billing Address</p>
                  <div className={globalStyles["block-content"]}>
                    <div className={globalStyles["form-flex-container"]}>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          name={"firstName"}
                          label={"First Name"}
                          placeholder={"John"}
                          validation={isNotBlank}
                          mandatory
                          message={"Please enter your first name."}
                        />
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          name={"lastName"}
                          label={"Last Name"}
                          placeholder={"Doe"}
                          validation={isNotBlank}
                          mandatory
                          message={"Please enter your last name."}
                        />
                      </div>
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        name={"addressLine1"}
                        label={"Address"}
                        placeholder={"Address Line 1"}
                        validation={isNotBlank}
                        mandatory
                        message={"Please enter your street address."}
                      />
                      <Input name={"addressLine2"} placeholder={"Address Line 2"} mt={"5px"} />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        name={"suburb"}
                        label={"Suburb"}
                        placeholder={"Suburb"}
                        validation={isNotBlank}
                        mandatory
                        message={"Please enter your suburb."}
                      />
                    </div>
                    <div className={globalStyles["form-flex-container"]}>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          name={"postCode"}
                          label={"Post Code"}
                          placeholder={"Post Code"}
                          validation={isNotBlank}
                          maxLength={4}
                          mandatory
                          message={"Please enter your post code."}
                        />
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Select
                          options={["ACT", "NSW", "QLD", "SA", "TAS", "VIC", "WA"]}
                          label={"State"}
                          placeholder={"select"}
                          name={"state"}
                          validation={isNotBlank}
                          mandatory
                          message={"Please select your state."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["form-buttons"]}>
              <Button onClick={() => navigate("/hq")} icon={faTimes} theme={"blue-light"}>
                Return to Dashboard
              </Button>
              <Button onClick={onSubmit} icon={faPaperPlane} disabled={!stripe || !elements}>
                {loading ? "Loading..." : "Save Payment Method"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodForm;
