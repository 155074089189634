import { useState, useEffect } from "react";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let screen;
  switch (true) {
    case windowSize.width < 768:
      screen = "mobile";
      break;
    case windowSize.width < 1366:
      screen = "tablet";
      break;
    default:
      screen = "desktop";
      break;
  }

  return { windowSize, screen };
};

export default useWindowSize;
