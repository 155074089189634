import { useEffect, useState } from "react";

const useDelayUnmount = (isMounted) => {
  const [showDiv, setShowDiv] = useState(isMounted);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), 500); //delay our unmount
    }
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, showDiv]);
  return showDiv;
};

export default useDelayUnmount;
