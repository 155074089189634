import clsx from "clsx";
import styles from "./Button.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useNav from "src/hooks/useNav";
const Button = ({
  className,
  children,
  mx,
  my,
  px,
  py,
  mt,
  mb,
  ml,
  mr,
  pt,
  pb,
  pl,
  pr,
  theme,
  size,
  onClick,
  icon,
  link,
  type = "button",
  style: customStyle,
  ...props
}) => {
  const navigate = useNav();
  return (
    <button
      type={type}
      onClick={link ? () => navigate(link) : onClick}
      style={{
        marginTop: mt ? mt : my,
        marginBottom: mb ? mb : my,
        marginLeft: ml ? ml : mx,
        marginRight: mr ? mr : mx,
        paddingTop: pt ? pt : py,
        paddingBottom: pb ? pb : py,
        paddingLeft: pl ? pl : px,
        paddingRight: pr ? pr : px,
        ...customStyle,
      }}
      className={clsx(styles["button"], styles[theme], styles[size], className)}
      {...props}
    >
      <div className={styles["content"]}>
        <p> {children}</p>
        {icon && <FontAwesomeIcon icon={icon} className={clsx(styles["icon"])} />}
      </div>
    </button>
  );
};

export default Button;
