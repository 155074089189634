import Main from "src/components/layout/Main";
import styles from "./AccountDashboard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns, faChevronLeft, faCreditCard, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import useNav from "src/hooks/useNav";
import { useCallback, useEffect, useState } from "react";
import Button from "src/components/Interface/buttons/Button";
import { customFetch } from "src/utilities/http";
import { titleCase } from "src/utilities/format";
import useToast from "src/hooks/useToast";
import useDelayUnmount from "src/hooks/useDelayUnmount";
import LoadingSpinner from "src/components/layout/LoadingSpinner";
import useAlerts from "src/hooks/useAlerts";
import useOrganisation from "src/hooks/useOrganisation";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const planNames = {
  1: {
    name: "Essential",
    price: "0",
  },
  2: {
    name: "Starter",
    price: "15",
  },
  3: {
    name: "Premium",
    price: "25",
  },
  4: {
    name: "Enterprise",
    price: "50",
  },
};

const BillingAndPayment = () => {
  const navigate = useNav();
  const [loading, setLoading] = useState(true);
  const [nextInvoice, setNextInvoice] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState();
  const toast = useToast();
  const showLoader = useDelayUnmount(loading);
  const { confirm } = useAlerts();
  const { homeOrganisation } = useOrganisation();

  const getPaymentMethods = useCallback(async () => {
    setLoading(true);
    const { data: paymentMethods } = await customFetch({
      entity: "Payment.PaymentMethod",
      method: "GET_AVAILABLE_PAYMENT_METHODS",
    });

    const defaultMethod = paymentMethods?.find((method) => method.isDefault);
    setDefaultPaymentMethod(defaultMethod);
    setPaymentMethods(paymentMethods?.filter((method) => !method.isDefault));
    setLoading(false);
  }, []);

  const getLatestInvoice = useCallback(async () => {
    const {
      data: { upcoming: invoice } = {},
    } = await customFetch({ entity: "Payment.Transaction", method: "GET_CURRENT_INVOICE" });

    const endDate = new Date(invoice?.period_end * 1000);

    setNextInvoice({
      date: months[endDate.getMonth()] + ", " + endDate.getFullYear(),
      transactionCount: invoice?.lines?.data
        ?.filter((line) => line.plan?.product === process.env.REACT_APP_STRIPE_METERED_PRODUCT_ID)
        .reduce((acc, item) => (acc += item.quantity), 0),
      amount: "$" + (invoice?.total / 100).toFixed(2),
    });
  }, []);

  useEffect(() => {
    getPaymentMethods();
    getLatestInvoice();
  }, [getPaymentMethods, getLatestInvoice]);

  const removePaymentMethod = useCallback(
    async (method) => {
      try {
        await confirm({
          label: "Remove Payment Method",
          text: "Are you sure you would like to remove this payment method?",
          inverse: true,
          confirmText: "Remove Payment Method",
          cancelText: "Cancel",
        });

        await customFetch({
          entity: "Payment.PaymentMethod",
          method: "DELETE",
          criteria: { _id: method._id },
        });

        getPaymentMethods();
        toast.success("Payment method removed successfully");
      } catch (error) {}
    },
    //eslint-disable-next-line
    [getPaymentMethods]
  );
  const makeMethodDefault = useCallback(
    async (method) => {
      const { status } = await customFetch({
        entity: "Payment.PaymentMethod",
        method: "SET_DEFAULT_PAYMENT_METHOD",
        data: method,
      });

      if (status === 200) {
        getPaymentMethods();
        toast.success("Payment method updated successfully");
      }
    },
    //eslint-disable-next-line
    [paymentMethods]
  );

  return (
    <Main>
      <div className={styles["container"]}>
        <div className={styles["dash-header"]}>
          <h1>Billing & Payment</h1>
          <div className={styles["return"]} onClick={() => navigate("/hq")}>
            <div className={styles["return-icon"]}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <p>Back to HQ</p>
          </div>
        </div>
        <div className={styles["billing-main-container"]}>
          {showLoader ? (
            <div className={styles["loading-container"]}>
              <LoadingSpinner colour={"blue"} loader={loading} />
            </div>
          ) : (
            <div className={styles["payment-methods"]}>
              {defaultPaymentMethod ? (
                <div className={styles["methods-container"]}>
                  <p className={styles["method-label"]}>Default Payment Method</p>
                  <div className={styles["card-container"]}>
                    <div className={styles["card-details"]}>
                      <FontAwesomeIcon
                        icon={defaultPaymentMethod?.type === "au_becs_debit" ? faBuildingColumns : faCreditCard}
                        className={styles["card-icon"]}
                        alt={"card-icon"}
                      ></FontAwesomeIcon>
                      <div className={styles["card-info"]}>
                        {defaultPaymentMethod?.type === "card" ? (
                          <>
                            <p className={styles["card-number"]}>
                              {titleCase(defaultPaymentMethod?.card?.brand)} ending {defaultPaymentMethod.card.last4}
                            </p>
                            <p className={styles["card-expiry"]}>
                              {months[defaultPaymentMethod.card.exp_month]} {defaultPaymentMethod.exp_year}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className={styles["card-number"]}>
                              Account ending in {defaultPaymentMethod?.au_becs_debit?.last4}
                            </p>
                            <p className={styles["card-expiry"]}>
                              BSB: {defaultPaymentMethod?.au_becs_debit?.bsb_number}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <p>Please add a payment method to continue.</p>
                </>
              )}

              {paymentMethods.length > 0 && (
                <div className={styles["methods-container"]}>
                  <p className={styles["method-label"]}>Other Payment Methods</p>
                  {paymentMethods.map((method, index) => {
                    return (
                      <div className={styles["card-container"]} key={index}>
                        <div className={styles["card-details"]}>
                          <FontAwesomeIcon
                            icon={method.type === "au_becs_debit" ? faBuildingColumns : faCreditCard}
                            className={styles["card-icon"]}
                            alt={"card-icon"}
                          ></FontAwesomeIcon>
                          <div className={styles["card-info"]}>
                            {method?.type === "card" ? (
                              <>
                                <p className={styles["card-number"]}>
                                  {titleCase(method?.card?.brand)} ending {method.card.last4}
                                </p>
                                <p className={styles["card-expiry"]}>
                                  {months[method.card.exp_month]} {method.exp_year}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className={styles["card-number"]}>
                                  Account ending in {method?.au_becs_debit?.last4}
                                </p>
                                <p className={styles["card-expiry"]}>BSB: {method?.au_becs_debit?.bsb_number}</p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={styles["make-default"]}>
                          <p onClick={() => makeMethodDefault(method)}>Make default</p>
                        </div>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={styles["remove-payment-method"]}
                          onClick={() => removePaymentMethod(method)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div className={styles["billing-actions-wrapper"]}>
            <div className={styles["billing-actions"]}>
              <div className={styles["dash-nav-item"]} onClick={() => navigate("/new-payment-method")}>
                <p>Add new payment method</p>
                <div className={styles["dash-icon-wrapper"]}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
              <div className={styles["bill-plan-item"]} onClick={() => navigate("/plans")}>
                <p className={styles["bill-plan-label"]}>Current Plan</p>
                <div className={styles["bill-plan-container"]}>
                  <p className={styles["plan"]}>{planNames[homeOrganisation?.subscriptionTier]?.name}</p>
                  <p>${planNames[homeOrganisation?.subscriptionTier]?.price} / month</p>
                  <p className={styles["view-plans"]}>View Plans</p>
                </div>
              </div>
              <div className={styles["bill-plan-item"]}>
                <p className={styles["bill-plan-label"]}>Upcoming Payments</p>
                <div className={styles["bill-plan-container"]}>
                  <p>{nextInvoice.date}</p>
                  <p>{nextInvoice.transactionCount} Applications</p>
                  <p>{nextInvoice.amount}</p>
                </div>
                <div className={styles["billing-button"]}>
                  <Button onClick={() => navigate("/history")}>View All Invoices</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};
export default BillingAndPayment;
