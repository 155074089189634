export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password = "") => {
  return true;
};

export function isNotBlank(str = "") {
  return str.trim().length !== 0;
}

export const validateUSI = (usi = "") => {
  const regex = /^[A-Za-z]{3}\d{4}[A-Za-z]{2}$/;
  return regex.test(usi);
};

export const validateMobile = (number = "") => {
  number = number.replace(/\s/g, "");
  const regex = /^\d{10}$/;
  return regex.test(number);
};

export const validateDOB = (dob = "") => {
  const dt = new Date(dob);
  const date = new Date();
  date.setFullYear(date.getFullYear() - 120);
  if (dt < date) return false;
  if (dt === "Invalid Date") return false;
  const today = new Date();
  if (dt > today) return false;
  if (typeof dt === "string") return false;
  return true;
};

export const validateTFN = (tfn = "") => {
  if (tfn === null) return true;
  const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

  tfn = tfn.replace(/\D/g, "");

  // Check length is 9 digits
  if (tfn.length === 9) {
    let sum = 0;
    for (let position = 0; position < weights.length; position++) {
      const digit = parseInt(tfn[position]);
      sum += weights[position] * digit;
    }
    return sum % 11 === 0;
  }

  return false;
};

export function isValidCreditCardNumber(cardNumber = "") {
  // Remove spaces and non-digit characters from the card number
  cardNumber = cardNumber.replace(/\D/g, "");

  // Check if the card number is empty or not a 16-digit number
  if (!/^\d{16}$/.test(cardNumber)) {
    return false;
  }

  // Reverse the card number and convert it to an array of digits
  const digits = cardNumber.split("").reverse().map(Number);

  // Apply the Luhn algorithm
  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    let digit = digits[i];

    // Double every second digit
    if (i % 2 === 1) {
      digit *= 2;

      // If the result is greater than 9, subtract 9
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
  }

  // The card number is valid if the sum is a multiple of 10
  return sum % 10 === 0;
}
