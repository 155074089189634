import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useNav = () => {
  const reactNav = useNavigate();

  const navigate = useCallback((path) => {
    reactNav(path);
    window.scrollTo(0, 0);
  }, [reactNav]);

  return navigate;
};

export default useNav;
