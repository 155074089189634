import { FormContextProvider } from "src/contexts/Form";
import React from "react";
const Form = ({ children, onSubmit, setFormData = () => {}, formData, initial, className, ...props }) => {
  const submitHandler = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <FormContextProvider setForm={setFormData} initial={initial}>
      <form {...props} onSubmit={submitHandler} className={className}>
        {children}
      </form>
    </FormContextProvider>
  );
};

export default Form;
