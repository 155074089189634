import styles from "./Input.module.css";
import clsx from "clsx";
import P from "../text/P";
import useValidation from "src/hooks/useValidation";
import useInlineStyles from "src/hooks/useInlineStyles";
import useToast from "src/hooks/useToast";
import { forwardRef } from "react";

const Input = forwardRef(
  (
    {
      type,
      name,
      label,
      placeholder,
      background,
      mandatory = false,
      onChange: onChangeProp = () => {},
      validation = () => true,
      message,
      disabled,
      link = {},
      value: overideValue,
      setValue: setOverideValue = () => {},
      inActive = false,
      className,
      boxShadow,
      ...props
    },
    ref
  ) => {
    const { onChange, error, setTouched, value } = useValidation({ name, validation, mandatory, onChangeProp });
    const style = useInlineStyles({ ...props });
    const toast = useToast();

    return (
      <div
        className={clsx(
          styles["input-container"],
          error && styles["error"],
          disabled && styles["disabled"],
          boxShadow && styles["box-shadow-grey"],
          background && styles["background"],
          className
        )}
        style={style}
        onClick={() => disabled && toast.error("This field has been locked with read-only rights.")}
      >
        <label className={styles["label"]}>{label}</label>
        <a href={link.destination} target="_blank" rel="noreferrer" className={styles["link"]}>
          {link.label}
        </a>
        <input
          {...props}
          onBlur={() => setTouched(true)}
          onChange={(e) => {
            onChange(e.target.value);
            setOverideValue(e.target.value);
          }}
          disabled={disabled || inActive}
          value={overideValue || value || ""}
          type={type}
          placeholder={placeholder}
          className={clsx(styles["input"])}
          ref={ref}
        ></input>
        {error && <P className={styles["message"]}>{message}</P>}
      </div>
    );
  }
);

export default Input;
