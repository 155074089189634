import Button from "src/components/Interface/buttons/Button";
import styles from "../Application.module.css";
import globalStyles from "src/Global.module.css";
import ControlButtons from "../subcomponents/ControlButtons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Input from "src/components/Interface/inputs/Input";
import { isNotBlank } from "src/utilities/validation";
import { newGuid } from "src/utilities/generate";
import { useForm } from "src/contexts/Form";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const BankDetails = ({ tab, setTab }) => {
  const { setData, data } = useForm();

  const [bankAccounts, setBankAccounts] = useState(
    data.bankAccounts?.map((acc, idx) => {
      if (idx === data?.bankAccounts.length - 1) acc.remainder = true;
      return { ...acc };
    }) || []
  );

useEffect(() => {
  if(!bankAccounts.length){
    setBankAccounts([{
      id: newGuid(),
      accountName: "",
      bsb: "",
      accountNumber: "",
      statementText: "",
      remainder: true,
    }])
  }
},[bankAccounts]); 

  useEffect(() => {
    setData((prev) => ({ ...prev, bankAccounts: bankAccounts }));
  }, [bankAccounts, setData]);

  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["form-container"]}>
        <p className={styles["content-heading"]}>Bank Details</p>
       
        {bankAccounts.map((_, idx) => {
          return (
            <div key={idx} className={styles["bank-account-cont"]}>
              <div
                className={styles["bank-bin"]}
                onClick={() => {
                  setBankAccounts((prev) => {
                    const newFunds = [...prev];
                    newFunds.splice(idx, 1);
                    return newFunds;
                  });
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} className={styles["bank-trash"]} />
              </div>
              <div className={styles["header-divider"]}></div>
              <div className={globalStyles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    name={"statement" + idx}
                    label={"Reference Text"}
                    validation={isNotBlank}
                    placeholder={"e.g. Pay Cheque"}
                    mandatory
                    maxLength={18}
                    disabled={data["status"] === "success"}
                    onChange={(val) => {
                      setBankAccounts((prev) => {
                        const newFunds = [...prev];
                        newFunds[idx] = { ...newFunds[idx], statementText: val };
                        return newFunds;
                      });
                    }}
                  />
                </div>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    name={"AccountName" + idx}
                    label={"Account Name"}
                    validation={isNotBlank}
                    placeholder={"John Doe"}
                    mandatory
                    maxLength={32}
                    disabled={data["status"] === "success"}
                    onChange={(val) => {
                      setBankAccounts((prev) => {
                        const newFunds = [...prev];
                        newFunds[idx] = { ...newFunds[idx], accountName: val };
                        return newFunds;
                      });
                    }}
                  />
                </div>
              </div>
              <div className={globalStyles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    name={"bsb" + idx}
                    label={"BSB"}
                    validation={isNotBlank}
                    mandatory
                    placeholder={"XXX XXX"}
                    maxLength={6}
                    disabled={data["status"] === "success"}
                    onChange={(val) => {
                      setBankAccounts((prev) => {
                        const newFunds = [...prev];
                        newFunds[idx] = { ...newFunds[idx], bSB: val };
                        return newFunds;
                      });
                    }}
                  />
                </div>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    name={"accountNumber" + idx}
                    label={"Account Number"}
                    validation={isNotBlank}
                    placeholder={"XXXX XXXX"}
                    mandatory
                    maxLength={9}
                    disabled={data["status"] === "success"}
                    onChange={(val) => {
                      setBankAccounts((prev) => {
                        const newFunds = [...prev];
                        newFunds[idx] = { ...newFunds[idx], accountNumber: val };
                        return newFunds;
                      });
                    }}
                  />
                </div>
              </div>
              {idx < bankAccounts.length - 1 && (
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="number"
                    name={"amount" + idx}
                    label={"Fixed Amount"}
                    validation={isNotBlank}
                    mandatory
                    placeholder={"0.00"}
                    disabled={data["status"] === "success"}
                    onChange={(val) => {
                      setBankAccounts((prev) => {
                        const newFunds = [...prev];
                        newFunds[idx] = { ...newFunds[idx], amount: val };
                        return newFunds;
                      });
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
         {bankAccounts.length < 3 && (
          <div className={styles["add-bank-btn"]}>
            <Button
              theme={"blue-dark"}
              size={"large"}
              icon={faPlus}
              onClick={() => {
                if (bankAccounts.length < 3)
                  setBankAccounts((prev) => {
                    const newArr = [...prev];
                    newArr.forEach((acc) => (acc.remainder = false));
                    newArr.push({
                      id: newGuid(),
                      accountName: "",
                      bsb: "",
                      accountNumber: "",
                      statementText: "",
                      remainder: true,
                    });

                    return newArr;
                  });
              }}
            >
              Add Another Bank Account
            </Button>
          </div>
        )}
      </div>
      <ControlButtons setTab={setTab} tab={tab} />
    </div>
  );
};

export default BankDetails;
