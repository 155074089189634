import Main from "src/components/layout/Main";
import styles from "./HQ.module.css";
import useAuth from "src/hooks/useAuth";
import TeamItem from "./components/TeamItem";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Xero from "src/assets/xero/connect-blue.svg";
import { customFetch } from "src/utilities/http";
import { faUserGroup, faUserPlus } from "@fortawesome/sharp-light-svg-icons";
import { faDownload, faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import useOrganisation from "src/hooks/useOrganisation";
import useDelayUnmount from "src/hooks/useDelayUnmount";
import LoadingSpinner from "src/components/layout/LoadingSpinner";
import InviteTeam from "./components/InviteTeam";
import ManageTeamAccess from "./components/ManageTeamAccess";
import OrganisationTile from "./components/OrganisationTile";
import useNav from "src/hooks/useNav";
import useToast from "src/hooks/useToast";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const month = months[new Date().getMonth() + 1];

const dateIsInThisMonth = (date, monthString) => {
  const [monthName, year] = monthString.split(" ");
  const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
  //eslint-disable-next-line eqeqeq
  return date.getFullYear() == year && date.getMonth() === monthIndex;
};

const HQ = () => {
  const {
    auth: { user },
  } = useAuth();

  const [applications, setApplications] = useState([]);
  const [totalSpend, setTotalSpend] = useState(0);

  const { availableOrganisations, setOrganisation, loading, homeOrganisation, allOrganisations } = useOrganisation();

  const showLoader = useDelayUnmount(loading);
  const [inviteTeamMember, setInviteTeamMember] = useState(false);
  const [manageTeamAccess, setManageTeamAccess] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [lastInvoiceLineItems, setLastInvoiceLineItems] = useState([]);
  const [lastInvoice, setLastInvoice] = useState({});
  const [totalThisMonth, setTotalThisMonth] = useState(0);

  const navigate = useNav();

  const toast = useToast();

  const handleSelectOrganisation = useCallback(
    (organisation) => {
      if (organisation.isSensitive && user?.contact?.email !== homeOrganisation?.admin_email) {
        return toast.error("You do not have access to this organisation");
      }
      setOrganisation(organisation);
      navigate("/");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOrganisation, navigate, user, homeOrganisation]
  );

  useEffect(() => {
    (async () => {
      if (!user) return;
      const { data } = await customFetch({ entity: "Application.Application", method: "GET" });
      const { data: { upcoming, last } = {} } = await customFetch({
        entity: "Payment.Transaction",
        method: "GET_CURRENT_INVOICE",
      });
      setTotalSpend(upcoming?.total / 100);
      setApplications(data);
      setLineItems(upcoming?.lines?.data || []);
      setLastInvoiceLineItems(last?.lines?.data || []);
      setLastInvoice(last);

      const meteredLineItems = upcoming?.lines?.data
        ?.filter((line) => line.plan?.product === process.env.REACT_APP_STRIPE_METERED_PRODUCT_ID)
        .reduce((acc, item) => (acc += item.quantity), 0);
      setTotalThisMonth(meteredLineItems);
    })();
  }, [user]);

  const handleDownload = useCallback(() => {
    const orgMap = Object.groupBy(allOrganisations, (x) => x.organisationID);

    //Header
    const csvData = [["Eline Spending Summary for " + month], []];

    //Upcoming Invoice Details
    csvData.push(["Upcoming Invoice Details"], ["Description", "Quantity", "Amount"]);
    lineItems?.forEach((item) => {
      csvData.push([
        item.description.includes("Eline Fixed Monthly") || item.description.includes("Eline Metered Monthly")
          ? item.description.slice(4)
          : item.description,
        item.quantity,
        "$" + (item.amount / 100).toFixed(2),
      ]);
    });
    csvData.push(["Total Spend: $" + totalSpend.toFixed(2)], []);

    //Last Invoice Details
    csvData.push(["Last Invoice Details"], ["Description", "Quantity", "Amount"]);
    lastInvoiceLineItems?.forEach((item) => {
      csvData.push([
        item.description.includes("Eline Fixed Monthly") || item.description.includes("Eline Metered Monthly")
          ? item.description.slice(4)
          : item.description,
        item.quantity,
        "$" + (item.amount / 100).toFixed(2),
      ]);
    });
    csvData.push(["Total Spend: $" + ((lastInvoice?.total || 0) / 100).toFixed(2)], []);

    //Org Summary
    const monthsArray = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      const monthYear = date.toLocaleString("default", { month: "short", year: "numeric" });
      monthsArray.unshift(monthYear);
    }

    csvData.push(["Organisation Summary (Count)", ...monthsArray]);
    allOrganisations.forEach((org) => {
      const orgApps = applications.filter((app) => app.organisationID === org.organisationID);
      const orgMonths = monthsArray.map((month) => {
        const monthApps = orgApps.filter((app) => dateIsInThisMonth(new Date(app.updatedAt), month));
        return monthApps.length;
      });
      csvData.push([org.name, ...orgMonths]);
    });
    csvData.push([]);

    //Applications Sections
    csvData.push(["Applications"], ["Date", "Organisation", "Job Title", "Employee Name", "Employee Email"]);
    applications
    ?.filter((app) => app.status === "success")
      .sort((b, a) => new Date(a.updatedAt) - new Date(b.updatedAt)) // sort by updatedAt date
      .forEach((app) => {
        csvData.push([
          new Date(app.updatedAt).toLocaleDateString(),
          orgMap[app.organisationID]?.[0]?.name,
          app.jobTitle,
          app.firstName + " " + app.lastName,
          app.email,
        ]);
      });

    const csvContent = "data:text/csv;charset=utf-8," + csvData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Eline Spending Summary - " + month + " " + new Date().getFullYear() + ".csv");
    document.body.appendChild(link);
    link.click();
  }, [lineItems, totalSpend, applications, availableOrganisations, lastInvoiceLineItems, lastInvoice]);

  const xeroAuthHandler = async () => {
    if (availableOrganisations.length >= homeOrganisation?.subscriptionLimit) return navigate("/plans");

    const { data } = await customFetch({ node: "xero-node", method: "BUILD_CONSENT_URL" });
    window.location.href = data;
  };

  return (
    <Main>
      <InviteTeam show={inviteTeamMember} setShow={setInviteTeamMember} />
      <ManageTeamAccess show={manageTeamAccess} setShow={setManageTeamAccess} setInvite={setInviteTeamMember} />
      <div className={styles["container"]}>
        <div className={styles["team-container"]}>
          <h1>
            Welcome to your paperless
            <br />
            playground, <span>{user?.personal?.firstName}.</span>
          </h1>
          <div className={styles["team-inner-container"]}>
            <div className={styles["team-heading"]}>
              <h3>Manage your team</h3>
              <div className={styles["team-total-application"]}>
                <h4>Total applications this billing cycle: </h4>
                <h4>{totalThisMonth}</h4>
              </div>
            </div>
            <div className={styles["team-content"]}>
              <TeamItem
                icon={faUserPlus}
                onClick={() => {
                  setInviteTeamMember(true);
                }}
                text={"Invite Team Member"}
              />
              <TeamItem icon={faUserGroup} onClick={() => setManageTeamAccess(true)} text={"Manage Team Access"} />
              <TeamItem icon={faCreditCard} onClick={() => navigate("/billing")} text={"Manage Payment Methods"} />
              <TeamItem icon={faFileInvoice} onClick={() => navigate("/plans")} text={"Manage Subscription"} />
            </div>
            <div className={styles["team-footer"]}>
              <h4>
                Next Invoice in {month}: ${totalSpend.toFixed(2)}
              </h4>
              <div className={styles["download-button"]} onClick={handleDownload}>
                <FontAwesomeIcon icon={faDownload} />
                <p>Download Full Spend Summary</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["select-container"]}>
          <div className={styles["select-header"]}>
            <div className={styles["select-header-title"]}>
              <h1>Select, Supervise and Soar</h1>
              <h3>
                <span>Xero Organisations</span> {availableOrganisations.length}
                <span>/{homeOrganisation?.subscriptionLimit}</span>
              </h3>
            </div>
            <div className={styles["select-header-connect"]} onClick={xeroAuthHandler}>
              <img src={Xero} alt="connect to xero"></img>
            </div>
          </div>
          {showLoader ? (
            <div className={styles["loading-container"]}>
              <LoadingSpinner colour={"blue"} loader={loading} />
            </div>
          ) : (
            <div className={styles["org-list-container"]}>
              {availableOrganisations.length === 0 ? (
                <div className={styles["no-orgs-connected"]}>
                  <h3>No active organisations. Connect to Xero get started!</h3>
                </div>
              ) : (
                availableOrganisations.map((organisation) => (
                  <OrganisationTile
                    key={organisation?._id}
                    setOrganisation={handleSelectOrganisation}
                    applications={applications.filter((app) => app.organisationID === organisation.organisationID)}
                    organisation={organisation}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </Main>
  );
};

export default HQ;
