import useAuth from "src/hooks/useAuth";
import styles from "./AccountDashboard.module.css";
import Main from "src/components/layout/Main";
import globalStyles from "src/Global.module.css";
import Input from "src/components/Interface/inputs/Input";
import { faCopy, faIdBadge, faShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useNav from "src/hooks/useNav";
import useAlerts from "src/hooks/useAlerts";
import { useCallback } from "react";
import { customFetch } from "src/utilities/http";

const AccountDashboard = () => {
  const { auth = {}, logout } = useAuth();
  const { user } = auth;
  const navigate = useNav();
  const { confirm } = useAlerts();

  const deactivateAccount = useCallback(
    async (response) => {
      if (!response) {
        return;
      }

      const { status } = await customFetch({ entity: "User.Account", method: "DEACTIVATE_ACCOUNT" });
      if (status === 200) {
        logout();
      }
    },
    [logout]
  );

  const navItems = [
    {
      label: "Sign-in & Security",
      func: async () => {
        try {
          await confirm({
            label: "Sign-in & Security",
            text: "Need to change your password? Send the reset link to your email.",
            confirmText: "Send Reset Password Link",
          });

          //TODO send reset password link
        } catch (error) {}
      },
      icon: faShield,
    },
    { label: "Billing & Payment", func: () => navigate("/billing"), icon: faIdBadge },
    { label: "Invoice History", func: () => navigate("/account/history"), icon: faCopy },
  ];

  return (
    <Main>
      <div className={styles["container"]}>
        <div className={styles["dash-header"]}>
          <h1>Welcome to your Account, {user?.personal?.firstName}</h1>
          <p>Review your application history. Billed Monthly.</p>
        </div>
        <div className={styles["dash-main-container"]}>
          <div className={styles["dash-personal-details"]}>
            <div className={globalStyles["form-input-container"]}>
              <Input
                inActive
                boxShadow
                value={user?.personal?.firstName + " " + user?.personal?.lastName}
                label={"Profile Name"}
              />
            </div>
            <div className={globalStyles["form-input-container"]}>
              <Input inActive boxShadow value={user?.contact?.email} label={"Email Address"} />
            </div>
            <div className={globalStyles["form-input-container"]}>
              <Input
                inActive
                boxShadow
                value={user?.contact?.phone}
                label={"Phone Number"}
                placeholder={"Phone Number"}
              />
            </div>
          </div>
          <div className={styles["dash-nav-wrapper"]}>
            <div className={styles["dash-nav"]}>
              <div className={styles["dash-nav-items"]}>
                {navItems.map((item, index) => (
                  <div className={styles["dash-nav-item"]} key={index + item.label} onClick={() => item.func()}>
                    <p>{item.label}</p>
                    <div className={styles["dash-icon-wrapper"]}>
                      <FontAwesomeIcon icon={item.icon} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles["deactivate-account"]}>
                <p
                  onClick={async () => {
                    try {
                      deactivateAccount(
                        await confirm({
                          label: "Deactivate Account",
                          text: "Deactivating your account is permanent and all your data will be removed. Are you sure you want to continue?",
                          inverse: true,
                          confirmText: "Yes, deactivate my account",
                          cancelText: "No, cancel!",
                        })
                      );
                    } catch (error) {}
                  }}
                >
                  Deactivate Account
                </p>
              </div>
            </div>

            <p className={styles["dash-advice"]}>
              Is there anything we can do better? We&apos;d love your advice. Contact{" "}
              <a href="mailto:team@eline.app">team@eline.app</a> to get in touch with a team member.
            </p>
          </div>
        </div>
      </div>
    </Main>
  );
};
export default AccountDashboard;
