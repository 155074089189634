import clsx from "clsx";
import styles from "./CheckboxDisplay.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CheckboxDisplay = ({ value, text, disabled }) => {
  return (
    <div className={styles["container"]}>
      <p className={clsx(disabled && styles["disabled"])}>{text}</p>
      <div className={styles["display-values"]}>
        <div
          className={clsx(
            styles["value-box"],
            ((typeof value === "boolean" && !value) || disabled) && styles["disabled"]
          )}
        >
          <div className={styles["value-outline"]}>
            {typeof value === "boolean" && value && <FontAwesomeIcon icon={faCheck} className={styles["check"]} />}
          </div>
          <p>Yes</p>
        </div>
        <div
          className={clsx(
            styles["value-box"],
            ((typeof value === "boolean" && value) || disabled) && styles["disabled"]
          )}
        >
          <div className={styles["value-outline"]}>
            {typeof value === "boolean" && !value && <FontAwesomeIcon icon={faCheck} className={styles["check"]} />}
          </div>
          <p>No</p>
        </div>
      </div>
    </div>
  );
};
export default CheckboxDisplay;
