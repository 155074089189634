import "./App.css";
import { Route, Routes } from "react-router-dom";
import Portal from "./components/pages/portal/Portal";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import Dashboard from "./components/pages/dashboard/Dashboard";
import AccountDashboard from "./components/pages/account/AccountDashboard";
import Application from "./components/pages/application/Application";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AlertsContextProvider } from "./contexts/Alerts";
import ThankYou from "./components/pages/thankyou";
import AllApplications from "./components/pages/application/AllApplications";
import CompletedApplication from "./components/pages/completedApplication/CompletedApplication";
import EmployerApplication from "./components/pages/application/EmployerApplication";
import LoadingOverlay from "./components/layout/LoadingOverlay";
import useUI from "./hooks/useUI";
import NewPaymentMethod from "./components/pages/account/NewPaymentMethod";
import BillingAndPayment from "./components/pages/account/BillingAndPayment";
import InvoiceHistory from "./components/pages/account/History";
import { XeroConsentUrl } from "./utilities/providers";
import HQ from "./components/pages/hq/HQ";
import Plans from "./components/pages/account/Plans";

function App() {
  const { initAuth } = useAuth();
  const { loading } = useUI();

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AlertsContextProvider>
        <LoadingOverlay loading={loading} />
        <Routes>
          {/* Auth */}
          <Route path="/xero-signup" exact element={<Redirects />} />
          <Route path="/portal" exact element={<Portal />} />
          <Route path="/hq" exact element={<HQ />} />

          {/* Dashboard */}
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/all-applications" exact element={<AllApplications />} />

          {/* Application */}
          <Route path="/thankyou" exact element={<ThankYou />} />
          <Route path="/onboarding/:key" exact element={<Application />} />
          <Route path="/application/:key" exact element={<EmployerApplication />} />
          <Route path="/completed/:key" exact element={<CompletedApplication />} />

          {/* Account */}
          <Route path="/settings" exact element={<AccountDashboard />} />
          <Route path="/billing" exact element={<BillingAndPayment />} />
          <Route path="/plans" exact element={<Plans />} />
          <Route path="/history" exact element={<InvoiceHistory />} />
          <Route path="/new-payment-method" exact element={<NewPaymentMethod />} />
        </Routes>
      </AlertsContextProvider>
    </LocalizationProvider>
  );
}

const Redirects = () => {
  useEffect(() => {
    (async () => {
      window.location.href = await XeroConsentUrl();
    })();
  }, []);
};

export default App;
