import { DateField } from "@mui/x-date-pickers/DateField";
import styles from "./Date.module.css";
import useValidation from "src/hooks/useValidation";
import useInlineStyles from "src/hooks/useInlineStyles";
import P from "../text/P";
import clsx from "clsx";
import { formatDateDD_MM_YYYY, formatDateYYYY_MM_DD } from "src/utilities/format";
import dayjs from "dayjs";
import useToast from "src/hooks/useToast";

const Date = ({
  name,
  label,
  background,
  validation = () => true,
  message,
  mandatory = false,
  format = "DD-MM-YYYY",
  disabled,
  value: overideValue,
  setValue: setOverideValue = () => {},
  inActive = false,
  boxShadow,
  className,
  ...props
}) => {
  const { error, setTouched, value, onChange } = useValidation({ name, validation, mandatory });
  const style = useInlineStyles({ ...props });
  const toast = useToast();
  return (
    <div
      className={clsx(
        styles["input-container"],
        error && styles["error"],
        disabled && styles["disabled"],
        background && styles["background"],
        boxShadow && styles["box-shadow-grey"],
        className
      )}
      style={style}
      onClick={() => disabled && toast.error("This field has been locked with read-only rights.")}
    >
      <label className={styles["label"]}>{label}</label>
      <DateField
        format="DD-MM-YYYY"
        onBlur={() => setTouched(true)}
        style={{ font: "var(--text) !important" }}
        value={(overideValue && dayjs(overideValue)) || (value && dayjs(value)) || ""}
        disabled={disabled || inActive}
        onChange={(e) => {
          switch (format) {
            case "DD-MM-YYYY":
              onChange(formatDateDD_MM_YYYY(e?.$d));
              setOverideValue(formatDateDD_MM_YYYY(e?.$d));
              break;
            case "YYYY-MM-DD":
              onChange(formatDateYYYY_MM_DD(e?.$d));
              setOverideValue(formatDateYYYY_MM_DD(e?.$d));
              break;
            default:
              onChange(formatDateDD_MM_YYYY(e?.$d));
              setOverideValue(formatDateDD_MM_YYYY(e?.$d));
              break;
          }
        }}
      />
      {error && <P className={styles["message"]}>{message}</P>}
    </div>
  );
};

export default Date;
