import Main from "src/components/layout/Main";
import styles from "./AccountDashboard.module.css";
import { useCallback, useEffect, useState } from "react";
import { customFetch } from "src/utilities/http";
import { titleCase } from "src/utilities/format";
import Button from "src/components/Interface/buttons/Button";
import clsx from "clsx";
import useWindowSize from "src/hooks/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import useNav from "src/hooks/useNav";

const InvoiceHistory = () => {
  const [invoices, setInvoices] = useState([]);
  const { screen } = useWindowSize();

  const navigate = useNav();

  const getInvoices = useCallback(async () => {
    const { data } = await customFetch({ entity: "Payment.Transaction", method: "GET_INVOICES" });
    setInvoices(data);
  }, []);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <Main>
      <div className={styles["container"]}>
        <div className={styles["dash-header"]}>
          <h1>Invoice History</h1>
          <div className={styles["return"]} onClick={() => navigate("/hq")}>
            <div className={styles["return-icon"]}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <p>Back to HQ</p>
          </div>
        </div>
        {screen === "desktop" ? (
          <div className={styles["invoice-table-container"]}>
            <table className={styles["invoice-table"]}>
              <tr className={styles["invoice-head"]}>
                <th className={styles["invoice-head-cell"]}>Date</th>
                <th className={styles["invoice-head-cell"]}>Items</th>
                <th className={styles["invoice-head-cell"]}>Cost</th>
                <th className={styles["invoice-head-cell"]}>Invoice Number</th>
                <th className={styles["invoice-head-cell"]}>Status</th>
                <th className={clsx(styles["invoice-head-cell"], styles["download"])}>Download</th>
              </tr>
              <tbody>
                {invoices.map((invoice) => (
                  <tr className={styles["invoice-row"]} map={invoice.number}>
                    <td className={styles["invoice-cell"]}>
                      {new Date(invoice.period_end * 1000).toLocaleDateString("en-AU")}
                    </td>
                    <td className={styles["invoice-cell"]}>{invoice.lines?.data?.[0]?.quantity}</td>
                    <td className={styles["invoice-cell"]}>${(invoice.total / 100).toFixed(2)}</td>
                    <td className={styles["invoice-cell"]}>{invoice.number}</td>
                    <td className={styles["invoice-cell"]}>{titleCase(invoice.status)}</td>
                    <td className={clsx(styles["invoice-cell"], styles["download"])}>
                      <Button
                        size={"small"}
                        onClick={() => window.open(invoice.hosted_invoice_url, "_blank", "rel=noopener noreferrer")}
                      >
                        Download
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles["only-desktop"]}>
            <p>This view is only available on the desktop version of this app.</p>
          </div>
        )}
      </div>
    </Main>
  );
};
export default InvoiceHistory;
