import useAuth from "src/hooks/useAuth";
import styles from "../Dashboard.module.css";
import H1 from "src/components/Interface/headings/H1";
import P from "src/components/Interface/text/P";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { customFetch } from "src/utilities/http";
import AnalyticsCard from "./subcomponents/AnalyticsCard";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import useOrganisation from "src/hooks/useOrganisation";

const Analytics = () => {
  const {
    auth: { user },
  } = useAuth();

  const [lastAccess, setLastAccess] = useState();

  const [applications, setApplications] = useState([]);

  const { organisation } = useOrganisation();

  useEffect(() => {
    const getApplications = async () => {
      const { data = [] } = await customFetch({
        entity: "Application.Application",
        method: "GET",
        criteria: { organisationID: organisation?.organisationID },
      });
      setApplications(data);
    };
    getApplications();
  }, [organisation]);

  useEffect(() => {
    setLastAccess(new Date(localStorage.getItem("lastAccess")));
  }, []);

  const chartData = useMemo(() => {
    // Create an array of the last 12 months
    const last12Months = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const month = currentDate.toLocaleString("default", { month: "short" });
      last12Months.push({ name: month, value: 0 });
      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    // Filter and sort the data
    return applications
      .filter((item) => {
        const updatedAtDate = new Date(item.updatedAt);
        const twelveMonthsAgo = new Date();
        twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
        return updatedAtDate >= twelveMonthsAgo;
      })
      .map((item) => {
        const updatedAtDate = new Date(item.updatedAt);
        const month = updatedAtDate.toLocaleString("default", { month: "short" });
        return { month, updatedAt: updatedAtDate };
      })
      .reduce((acc, item) => {
        const monthIndex = last12Months.findIndex((m) => m.name === item.month);
        if (monthIndex !== -1) {
          acc[monthIndex].value += 1;
        }
        return acc;
      }, last12Months)
      .reverse();
  }, [applications]);

  return (
    <div className={styles["analytics-container"]}>
      <div className={styles["analytics-heading-container"]}>
        <H1>
          Welcome back <span>{user?.personal?.firstName}</span>
        </H1>
        <P>Elevate Onboarding Efficiency: Real-time Visualisation of Application Progress and Insights</P>
      </div>
      <div className={clsx(styles["analytics-dashboard"])}>
        <div className={clsx(styles["analytics-timeline"], styles["analytics-card"])}>
          <div className={styles["timeline-heading"]}>
            <h3>Employee Applications</h3>
            <p>12 months</p>
          </div>
          <div className={styles["timeline-container"]}>
            <ResponsiveContainer width={"96%"} height={"100%"}>
              <LineChart data={chartData}>
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: "12px", fontWeight: "700" }}
                  allowDecimals={false}
                  tickCount={12}
                  axisLine={false}
                  tickLine={false}
                  tickMargin={15}
                />
                <YAxis
                  tick={{ fontSize: "12px", fontWeight: "700" }}
                  allowDecimals={false}
                  axisLine={false}
                  tickLine={false}
                  tickMargin={15}
                  padding={{ top: 15 }}
                />
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: "white", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "var(--blue)", stopOpacity: 1 }} />
                  </linearGradient>
                </defs>

                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="url(#gradient)"
                  dot={false}
                  strokeWidth={5}
                  connectNulls
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <AnalyticsCard
          status={"success"}
          text={"new successful applications"}
          metric={
            applications.filter((app) => app.status === "success" && new Date(app.updatedAt) >= lastAccess).length
          }
        />
        <AnalyticsCard
          status={"pending"}
          text={"pending applications"}
          metric={applications.filter((app) => app.status === "pending").length}
        />
        <AnalyticsCard
          status={"failed"}
          text={"applications requiring action"}
          metric={applications.filter((app) => app.status === "failed").length}
        />
      </div>
    </div>
  );
};

export default Analytics;
