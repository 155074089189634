import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "./Application.module.css";
import ApplicationNav from "./subcomponents/ApplicationNav";
import ApplicationHeader from "./subcomponents/ApplicationHeader";
import PersonalDetails from "./tabs/PersonalDetails";
import TaxDetails from "./tabs/TaxDetails";
import BankDetails from "./tabs/BankDetails";
import Form from "src/components/Interface/layout/Form";
import { customFetch } from "src/utilities/http";
import EmploymentContract from "./tabs/EmploymentConfirmation";
import SuperDetails from "./tabs/SuperDetails";
import useNav from "src/hooks/useNav";
import useWindowSize from "src/hooks/useWindowSize";
import useDelayUnmount from "src/hooks/useDelayUnmount";
import LoadingSpinner from "src/components/layout/LoadingSpinner";

const Application = () => {
  const { key } = useParams();
  const [tab, setTab] = useState(0);
  const navigate = useNav();
  const { screen } = useWindowSize();
  const [initial, setInitial] = useState({ Country: "AUSTRALIA", status: "draft" });
  const [loading, setLoading] = useState(true);

  const showLoader = useDelayUnmount(loading);

  const urlParams = new URLSearchParams(window.location.search);
  const organisationName = urlParams.get("organisation");
  let homeOrganisation = urlParams.get("homeOrganisation");
  const userID = urlParams.get("user");

  useEffect(() => {
    const fetchApplication = async () => {
      setLoading(true);
      if (!homeOrganisation) {
        const { data = {} } = await customFetch({
          entity: "Public.User",
          method: "GET_HOME_ORGANISATION",
          data: { userID },
        });

        if (!data) {
          navigate("/thankyou");
          return;
        }

        //eslint-disable-next-line
        homeOrganisation = data;
      }
      const {
        data: { application: data = [], organisation },
      } = await customFetch({
        entity: "Public.Application",
        method: "GET",
        data: { key, homeOrganisation },
      });

      if (data?.status === "success" || !data?.status || !organisation) {
        navigate("/thankyou");
        return;
      }

      setInitial({
        ...data,
        ...data.homeAddress,
        ...data.taxDeclaration,
        superMemberships: data?.superMemberships,
        ...data?.bankAccounts?.reduce((acc, fund, idx) => {
          acc["statement" + idx] = fund.statementText;
          acc["AccountName" + idx] = fund.accountName;
          acc["bsb" + idx] = fund.bSB;
          acc["accountNumber" + idx] = fund.accountNumber;
          return acc;
        }, {}),
      });

      setLoading(false);
    };

    fetchApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, navigate]);

  return (
    <>
      {screen !== "mobile" && <ApplicationNav tab={tab} setTab={setTab} />}
      <div className={styles["wrapper"]}>
        {showLoader ? (
          <div className={styles["loading-container"]}>
            <LoadingSpinner loader={loading} colour={"blue"} />
          </div>
        ) : (
          <div className={styles["container"]}>
            <ApplicationHeader
              heading={`Hello ${initial?.firstName} ${initial?.lastName}, Welcome to ${organisationName}.`}
              subline={
                tab === 0
                  ? "Please confirm the details below are correct:"
                  : "Please fill in the requried form fields below."
              }
            />
            <div className={styles["content"]}>
              <Form onSubmit={() => {}} setFormData={() => {}} initial={initial}>
                {tab === 0 && <EmploymentContract setTab={setTab} tab={tab} />}
                {tab === 1 && <PersonalDetails setTab={setTab} tab={tab} />}
                {tab === 2 && <TaxDetails setTab={setTab} tab={tab} />}
                {tab === 3 && <BankDetails setTab={setTab} tab={tab} />}
                {tab === 4 && <SuperDetails setTab={setTab} tab={tab} />}
              </Form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Application;
