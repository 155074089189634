import { useCallback, useState } from "react";
import styles from "../Portal.module.css";
import illustration from "src/assets/svg/login-illustration.svg";
import logo from "src/assets/global/eline-logo.svg";
import Input from "src/components/Interface/inputs/Input";
import { validateEmail } from "src/utilities/validation";
import useToast from "src/hooks/useToast";
import { customFetch } from "src/utilities/http";
import Button from "src/components/Interface/buttons/Button";

const ResetEmail = ({ setTab }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const { status } = await customFetch({
      entity: "Public.Authentication",
      data: { email },
      method: "RESET_PASSWORD",
    });

    setLoading(false);

    if (status === 200) {
      setTab("login");
      toast.success("Successfully sent reset email. Please check your inbox.");
    }

    //eslint-disable-next-line
  }, [email]);
  return (
    <div className={styles["container"]}>
      <div className={styles["login-left"]}>
        <img src={logo} alt="logo graphic" className={styles["login-logo"]} />
        <div className={styles["reset-details"]}>
          <p className={styles["reset-email-text"]}>
            Enter the email address associated with your account and we'll send instruction to your email on how to
            reset your password.
          </p>
          <Input
            value={email}
            onChange={setEmail}
            background
            label={"Email"}
            mt={"30px"}
            placeholder={"johndoe@example.com"}
            validation={validateEmail}
            message={"Please enter a valid email address."}
          />
          <Button onClick={onSubmit} size={"medium"} mt={"40px"}>
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
      <div className={styles["login-right"]}>
        <img src={illustration} alt="login graphic" className={styles["login-illustration"]} />
      </div>
    </div>
  );
};
export default ResetEmail;
